import * as React from 'react';
import { Space } from 'antd';
import { ActivityLog, VatRunData } from 'store/vat/models';
import { ReactComponent as IconNote } from 'assets/images/icons/iconNote.svg';
import { ActivityType, DataLogStatus } from 'store/app/enums';
import moment from 'moment';
import { Input } from 'antd';
import styled from 'styled-components';
import { ExportButton, ScopeButton } from 'components/Custom/buttons';
import { ReactComponent as IconExport } from 'assets/images/icons/iconExport.svg';
import { StatusGetter } from 'utils/status-getter';
import { RenderUserDetail } from 'utils/render-user-details';
import { useDispatch } from 'react-redux';
import { turnDrawerOff } from 'store/app/actions';
import { CSVLink } from 'react-csv';
import routes from 'routes';
import { useParams } from 'react-router';

const ActivityLogsWrapper = styled.div`
  .activity-card {
    border-radius: 10px;
    opacity: 0px;
    background: #ffffff;
    border: 1px solid #e9f6f7;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .title-date {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #042a2a;
    border-bottom: 1px solid #dbeae3;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .user-box {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  .user-details {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-weight: 400;
  }
  .user-name {
    font-size: 14px;
    color: #042a2a;
  }
  .user-email {
    font-size: 12px;
    color: #879494;
  }
  .note-log {
    border-radius: 10px;
    opacity: 0px;
    border: 1px solid #e9f6f7;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    padding: 15px;
    margin-top: 10px;
  }
`;

const { TextArea } = Input;

interface ActivityLogsProps {
  data: VatRunData;
  activityLogs?: ActivityLog[];
  updateNote: (noteText: string, dataLogId: string) => void;
  connectionId: string;
  mappingId: string;
}

export const ActivityLogs: React.FC<ActivityLogsProps> = ({
  data,
  activityLogs,
  updateNote,
  connectionId,
  mappingId,
}) => {
  const [editNote, setEditNote] = React.useState(false);
  const [noteText, setNoteText] = React.useState<string>('');
  const dispatch = useDispatch();

  const onNoteChange = (e) => {
    setNoteText(e.target.value);
  };

  const onNoteSave = () => {
    updateNote(noteText, data.dataLogId);
    noteText !== '' && setEditNote(false);
    setNoteText('');
    dispatch(turnDrawerOff());
  };
  // const columns = [
  //   {
  //     title: 'Changes',
  //     dataIndex: 'type',
  //     render: (content) => ActivityType[content],
  //   },
  //   {
  //     title: 'Date',
  //     dataIndex: 'createdAt',
  //     render: (content) => moment(content).format('DD/MM/YYYY - hh:mm:ss'),
  //   },
  //   {
  //     title: 'User',
  //     dataIndex: 'fullName',
  //   },
  //   {
  //     title: 'Email',
  //     dataIndex: 'email',
  //   },
  //   {
  //     title: 'Details',
  //     dataIndex: 'details',
  //     render: (content: string) => {
  //       if (content.includes('<') && content.includes('>'))
  //         return <div dangerouslySetInnerHTML={{ __html: content }} />;
  //       return content;
  //     },
  //   },
  // ];

  const getDetails = (logFile: ActivityLog) => {
    const content = logFile.details;
    const name = logFile.fullName;

    if (content.includes('<') && content.includes('>'))
      if (
        content.includes('<submissionlink>') &&
        content.includes('</submissionlink>')
      ) {
        const mainText = content.split('<submissionlink>')[0];
        const linkText = content
          .split('<submissionlink>')[1]
          .split('</submissionlink>')[0];
        const hrefString = routes.vat.viewReportLink
          .replace(':connectionId', connectionId)
          .replace(':mappingId', mappingId)
          .replace(
            ':dataLogId',
            logFile?.linkedPeriodId ? logFile?.linkedPeriodId : ''
          );

        const htmlString = `<span>${mainText}</span><a href="${hrefString}">${linkText}</a>`;
        return (
          <div
            //className="note-log"
            dangerouslySetInnerHTML={{ __html: htmlString }}
          />
        );
      } else {
        return (
          <>
            <span>{name} sent a note to client:</span>
            <div
              className="note-log"
              dangerouslySetInnerHTML={{
                __html: content
                  .replace('Subject:', '<strong>Subject:</strong>')
                  .replace('Message:', '<strong>Message:</strong>')
                  .replace('Preferences', '<strong>Preferences</strong>'),
              }}
            />
          </>
        );
      }
    return content;
  };

  const onExportActivities = () => {
    activityLogs;
  };

  const getHeaderForExport = (dataToExport) => {
    const obj = dataToExport?.length > 0 ? dataToExport[0] : null;
    let headerArr: any[] = [];
    if (obj) {
      const headers = Object.keys(obj);
      headerArr = headers.map((key) => ({
        label: key.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
        key: key,
      }));
    }

    return headerArr;
  };

  const getDataForExport = (activityLogs: ActivityLog[]) => {
    const dataToExport = [...activityLogs];
    let dataArr: any[] = []; //@ts-ignore
    dataArr =
      dataToExport?.length &&
      dataToExport?.map((log) => ({
        type: ActivityType[log.type],
        createdAt: moment(log.createdAt).format('DD/MM/YYYY - hh:mm:ss'),
        details: log.details,
        email: log.email,
        fullName: log.fullName,
      }));

    return dataArr;
  };

  return (
    <ActivityLogsWrapper>
      {data?.status &&
        DataLogStatus[data?.status] !== DataLogStatus.SUBMITTED && (
          <Space size={'large'}>
            <ExportButton
              icon={<IconNote />}
              style={{ marginBottom: 20 }}
              onClick={() => setEditNote(!editNote)}
            >
              {!editNote ? 'Add Note' : 'Close Note'}
            </ExportButton>
            <ExportButton
              icon={<IconExport />}
              style={{ marginBottom: 20 }}
              onClick={onExportActivities}
            >
              <CSVLink
                filename={'activity logs.csv'}
                data={activityLogs && getDataForExport(activityLogs)}
                headers={getHeaderForExport(activityLogs)}
              >
                <span>Export Activity</span>
              </CSVLink>
            </ExportButton>
          </Space>
        )}

      {editNote && (
        <>
          <TextArea rows={4} onChange={onNoteChange} value={noteText} />
          <div className="divider-20" />
          <ScopeButton
            type={'primary'}
            onClick={onNoteSave} //@ts-ignore
            width="100%"
          >
            Save Note
          </ScopeButton>
          <div className="divider-20" />
        </>
      )}
      <div
        className={`scroller-activity ${editNote && 'scroller-activity-note'}`}
      >
        {activityLogs?.map((al) => (
          <div className="activity-card">
            <div style={{ alignSelf: 'end', marginBottom: 10 }}>
              <StatusGetter status={al.type} smaller />
            </div>
            <span className="title-date">
              {moment(al.createdAt).format('DD/MM/YYYY - hh:mm:ss')}
            </span>

            <div className="user-box">
              <RenderUserDetail record={al.userDto} showOption />
            </div>
            <div>{getDetails(al)}</div>
          </div>
        ))}
      </div>
    </ActivityLogsWrapper>
  );
};
