import { call, put } from 'redux-saga/effects';
import { setUserRightsFailure, setUserRightsSuccess } from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { setUserRights } from '../api';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { SetUserRightsRequestAction } from '../types';
import { updateEntityMembers } from 'store/organisation/actions';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* setUserRightsSaga(action: SetUserRightsRequestAction) {
  try {
    let err: ApiError | null;
    const { userRights, tablePermission, user } = action.payload;
    const response: ApiResponse = yield call(
      setUserRights,
      userRights.uuid,
      tablePermission
    );
    err = checkResponse(response?.responseMessage);
    if (err) throw err;
    if (user) {
      yield put(
        updateEntityMembers(userRights.organisationId, user, tablePermission)
      );
    }
    yield put(setUserRightsSuccess(userRights.uuid, tablePermission));
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(setUserRightsFailure());
  }
}
