import { call, put } from 'redux-saga/effects';
import { addPasswordFailure, addPasswordSuccess } from '../actions';
import { addPassword } from '../api';
import { AddPasswordRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn, turnModalOff } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* addPasswordSaga(action: AddPasswordRequestAction) {
  try {
    let err: ApiError | null;

    const { newPassword } = action.payload;
    const response: ApiResponse = yield call(addPassword, newPassword);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Updated !',
      description: 'Password changed successfully.',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(addPasswordSuccess());
    yield put(turnModalOff());
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(addPasswordFailure());
  }
}
