import { Col, Form, Space } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { FormPasswordInput } from 'components/Custom/inputs';
import { LabelWrapper } from 'components/Custom/labels';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface FormValues {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

interface ChangePasswordFormProps {
  hasPassword?: boolean;
  savePassword: (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string
  ) => void;
  form: any;
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  savePassword,
  hasPassword,
  form,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (values: FormValues) => {
    savePassword(
      values.oldPassword,
      values.newPassword,
      values.newPasswordConfirm
    );
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      form={form}
      autoComplete="off"
      onFinish={handleSubmit}
    >
      {hasPassword && (
        <>
          <LabelWrapper>
            <span className="label-text">{t('signup.old-password')}</span>
          </LabelWrapper>
          <Form.Item
            name="oldPassword"
            rules={[{ required: true, message: t('signup.required-field') }]}
          >
            <FormPasswordInput border_color="#DBEAE3" />
          </Form.Item>
        </>
      )}
      <Space style={{ columnGap: 15, marginTop: '10px' }}>
        <Space direction="vertical">
          <LabelWrapper>
            <span className="label-text">{t('signup.new-password')}</span>
          </LabelWrapper>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: t('signup.pleaseEnterPassword'),
              },
              {
                min: 8,
                message: '',
              },
              /*               {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^_-])[A-Za-z\d@.#$!%*?^&_-]{8,20}$/,
                message: t('signup.not-match-pattern'),
              }, */
            ]}
          >
            <FormPasswordInput border_color="#DBEAE3" />
          </Form.Item>
        </Space>

        <Space direction="vertical">
          <LabelWrapper>
            <span className="label-text">{t('signup.repeatPassword')}</span>
          </LabelWrapper>
          <Form.Item
            name="newPasswordConfirm"
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: t('signup.pleaseConfirmPassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('signup.passwordDoNotMatch'))
                  );
                },
              }),
            ]}
          >
            <FormPasswordInput border_color="#DBEAE3" />
          </Form.Item>
        </Space>
      </Space>
      <Col span={24}>
        <Form.Item>
          <ScopeButton
            width="172px"
            height="40px"
            type="primary"
            htmlType="submit"
          >
            {hasPassword ? t('users.change-password') : t('users.add-password')}
          </ScopeButton>
        </Form.Item>
      </Col>
    </Form>
  );
};
