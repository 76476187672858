import React, { useState } from 'react';
//import moment from 'moment';
import {
  Checkbox,
  Col,
  InputNumber,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { TaxRate } from 'store/returns/models/returnData';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { ReverseCharge, SelectedRate, StepOneData } from 'pages/returns';
import styled from 'styled-components';
import { RoundAddButton, ScopeButton } from 'components/Custom/buttons';

import { TaxRateType } from 'store/app/enums';
import { StyledPopconfirm } from 'components/Custom/Popconfirm';
import { useTranslation } from 'react-i18next';

const SidePanelWrapper = styled.div`
  padding: 0 10px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .control-box,
  .control-box-small {
    margin-top: 14px;
    background: #ffffff;
    width: 400px;
    border-top: 1px solid #dbeae3;
    border-left: 1px solid #dbeae3;
    border-right: 1px solid #dbeae3;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 22px 0 22px;
    box-shadow: 0px 5px 10px 0px #0000000d;
    //box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    z-index: 10;
  }

  .control-box-small {
    border-radius: 10px;
  }

  .control-box-no-shadow {
    box-shadow: none;
  }
  .control-line-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: #058181;
    margin-bottom: 15px;
  }

  .mapping-list-box {
    background: #ffffff;
    width: 400px;
    border-left: 1px solid #dbeae3;
    border-right: 1px solid #dbeae3;
    padding: 0px 22px;
    z-index: 0;
    overflow: hidden;
  }

  .bottom-box {
    background: #ffffff;
    height: 22px;
    width: 400px;
    border-radius: 0px 0px 10px 10px;
    border-bottom: 1px solid #dbeae3;
    border-left: 1px solid #dbeae3;
    border-right: 1px solid #dbeae3;
    //padding: 0px 22px;
    z-index: 10;
    box-shadow: 0px -5px 10px 0px #0000000d;
  }
  .bottom-box-no-shadow {
    box-shadow: none;
  }

  .control-line {
    border-top: 1px solid #dbeae3;
    width: 100%;
    margin-bottom: 15px;
  }

  .mapping-details {
    font-size: 12px;
    line-height: 20px;
    text-align: left;
  }

  .tag-cash,
  .tag-reverse,
  .tags-all,
  .tag-OOS {
    align-self: center;
    //width:100px
    height: 21px;
    border-radius: 2px;
    border: 1px solid #dbeae3;
    opacity: 0px;
    background: #fff5e2;
    font-size: 12px;
    font-weight: 600;
    color: #042a2a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 3px;
    margin: 1px 2px;
    z-index: 2;
    white-space: nowrap;
  }

  .tag-OOS {
    background: #ffe9e2;
  }

  .tag-cash {
    background: #dbeae3;
  }

  .tag-reverse {
    background: #dedbea;
  }

  .cash-accounting-box {
    background: #ffffff;
    //height: 22px;
    width: 400px;
    //border-radius: 0px 0px 10px 10px;
    border-bottom: 1px solid #dbeae3;
    border-left: 1px solid #dbeae3;
    border-right: 1px solid #dbeae3;
    padding: 0px 20px;
    z-index: 10;
    //box-shadow: 0px -5px 10px 0px #0000000d;
  }

  .bottom-box-ca {
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 12px 20px;
  }
  .checkbox-rc-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
  }

  .checkbox-rc {
    display: flex;
    gap: 10px;
    align-content: center;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input-wrap {
    font-size: 12px;
  }
  .ant-input-number-group-addon {
    padding: 0 5px;
  }
`;

interface SidePanelProps {
  selectedRates: SelectedRate[];
  selectedRatesCopy: SelectedRate[];
  reverseCharges: ReverseCharge[];
  taxRates?: TaxRate[];
  outOfScopeCode: string;
  handleCashAccounting: (rateId?: string) => void;
  handleReverseCharge: (rateId: string, value: string | null) => void;
  hasCashAccounting: boolean;
  saveCashAccounting: () => void;
  saveReverseCharges: () => boolean;
  fillCashAccounting: (value: boolean) => void;
  resetReverseCharge: () => void;
  loadCashAccounting: () => void;
  useCashAccounting: boolean;
  toggleCA: (value: boolean) => void;
  useReverseCharge: boolean;
  toggleRC: (value: boolean) => void;
  loadReverseCharge: () => void;
  updateTaxRateType: (type: TaxRateType, rateId: string) => void;
  resetForm: () => void;
  useCashReverse: boolean;
  onSubmit: () => void;
}
const { Text } = Typography;

export const SidePanel: React.FC<SidePanelProps> = ({
  selectedRates,
  selectedRatesCopy,
  reverseCharges,
  taxRates,
  outOfScopeCode,
  handleCashAccounting,
  handleReverseCharge,
  hasCashAccounting,
  saveCashAccounting,
  saveReverseCharges,
  fillCashAccounting,
  resetReverseCharge,
  loadCashAccounting,
  loadReverseCharge,
  useCashAccounting,
  toggleCA,
  useReverseCharge,
  toggleRC,
  updateTaxRateType,
  resetForm,
  useCashReverse,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [bottom, setBottom] = useState<boolean>(false);
  const [top, setTop] = useState<boolean>(true);

  const onCACheckboxClick = (rateId?: string) => {
    handleCashAccounting(rateId);
  };

  const onRCCheckboxClick = (rateId?: string) => {
    rateId && handleReverseCharge(rateId, null);
  };

  const onRCChange = (rateId: string, value: string) => {
    handleReverseCharge(rateId, value);
  };

  const handleSaveCA = () => {
    saveCashAccounting();
    toggleCA(false);
  };

  const handleSaveRC = () => {
    const succesfullSave = saveReverseCharges();
    succesfullSave && toggleRC(false);
  };

  const handleClearCA = () => {
    fillCashAccounting(false);
  };

  const handleClearRC = () => {
    resetReverseCharge();
  };

  const handleSelectAllCA = () => {
    fillCashAccounting(true);
  };

  const onCAClick = () => {
    !useCashAccounting && loadCashAccounting();
    toggleCA(!useCashAccounting);
  };

  const onRCClick = () => {
    !useReverseCharge && loadReverseCharge();
    toggleRC(!useReverseCharge);
  };

  const onTaxRateTypeClick = (type: TaxRateType, rateId: string) => {
    updateTaxRateType(type, rateId);
  };

  const getSelectedRates = (rateId: string) => {
    const selRate = selectedRates?.find((sr) => sr.value === rateId);
    if (selRate) isOutOfScope = selRate?.location[0] === outOfScopeCode;

    return (
      <>
        {selRate?.location.map((l) => (
          <div
            key={l}
            className={l === outOfScopeCode ? 'tag-OOS' : 'tags-all'}
          >
            {l}
          </div>
        ))}
        {selRate?.cashAccounting && <div className="tag-cash">Cash</div>}
        {selRate?.reverseCharge && selRate?.reverseCharge > 0 && (
          <div className="tag-reverse">RC</div>
        )}
      </>
    );
  };

  const getInputOutput = (rateId: string) => {
    const selRate = selectedRates?.find((sr) => sr.value === rateId);
    if (
      selRate &&
      selRate.location[0] !== outOfScopeCode &&
      selRate.location.length > 0
    )
      return (
        <>
          <Tooltip title="Toggle between Input and Output Tax rate type">
            {selRate.taxRateType === TaxRateType.INPUT && (
              <ArrowLeftOutlined
                style={{ color: '#054581' }}
                onClick={() => onTaxRateTypeClick(TaxRateType.OUTPUT, rateId)}
              />
            )}{' '}
            {selRate.taxRateType === TaxRateType.OUTPUT && (
              <ArrowRightOutlined
                style={{ color: '#058181' }}
                onClick={() => onTaxRateTypeClick(TaxRateType.INPUT, rateId)}
              />
            )}
          </Tooltip>
        </>
      );
  };
  const getCACheckbox = (taxRate: TaxRate) => {
    const selRate = selectedRatesCopy?.find(
      (sr) => sr.value === taxRate.taxType
    );
    const enableCA =
      useCashAccounting &&
      selRate?.location &&
      selRate.location.length > 0 &&
      selRate?.location[0] !== outOfScopeCode;

    if (enableCA || true)
      return (
        <>
          <Checkbox
            checked={selRate?.cashAccounting}
            value={selRate?.cashAccounting}
            onChange={() => onCACheckboxClick(selRate?.value)}
            name={selRate?.value}
            aria-label={selRate?.value}
          >
            {selRate?.cashAccounting ? (
              <strong>{`${taxRate.name} [${taxRate.taxRate}%]`}</strong>
            ) : (
              taxRate.name + ' [' + taxRate.taxRate + '%]'
            )}
          </Checkbox>
        </>
      );
  };

  const getRCCheckbox = (taxRate: TaxRate) => {
    const selRate = reverseCharges?.find((rc) => rc.rateId === taxRate.taxType);

    return (
      <div className="checkbox-rc-wrapper">
        <div className="checkbox-rc">
          <Checkbox
            checked={selRate?.used}
            value={selRate?.used}
            onChange={() => onRCCheckboxClick(selRate?.rateId)}
          />
          <Text
            ellipsis={{ tooltip: taxRate.name }}
            style={{
              fontSize: 12,
              //   padding: '2px 0',
              color: '#879494',
              width: 200,
            }}
          >
            {selRate?.used ? <strong>{taxRate.name}</strong> : taxRate.name}
          </Text>
        </div>
        {selRate?.used && (
          <>
            <InputNumber<string>
              style={{ width: 80, fontSize: '12px' }}
              defaultValue=""
              min="0"
              max="100"
              step="0"
              onChange={(val) => val && onRCChange(selRate.rateId, val)}
              onStep={(val) => val && onRCChange(selRate.rateId, val)}
              stringMode
              size={'small'}
              addonAfter="%"
              value={selRate.amount !== null ? selRate.amount.toFixed(2) : ''}
            />
          </>
        )}
      </div>
    );
  };

  let color = false;
  let isOutOfScope = false;

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    const top = e.target.scrollTop === 0;
    if (bottom) {
      setBottom(true);
      setTop(false);
    } else if (top) {
      setBottom(false);
      setTop(true);
    } else {
      setBottom(false);
      setTop(false);
    }
  };

  return (
    <SidePanelWrapper>
      <div
        className={`control-box ${!useReverseCharge && 'control-box-small'}`}
      >
        <div className="control-line-box">
          <span>Calculate Reverse Charges</span>
          <RoundAddButton disabled={useCashAccounting} onClick={onRCClick}>
            {useReverseCharge ? '-' : '+'}
          </RoundAddButton>
        </div>
      </div>
      {useReverseCharge ? (
        <>
          <div className={'cash-accounting-box'}>
            <div className="mapping-details" style={{ width: '95%' }}>
              If you do not account for reverse charge in your accounting
              software, select the VAT codes which generate.TAX will use to
              calculate / input in the VAT return:
            </div>
            <div className="divider-10" />
            <div className="scroller-rc">
              {taxRates?.map((tr) => (
                <>
                  {tr.taxRate === 0 && (
                    <div style={{ margin: '7px 0px' }}>{getRCCheckbox(tr)}</div>
                  )}
                </>
              ))}
            </div>
          </div>

          <div className={`bottom-box bottom-box-no-shadow bottom-box-ca`}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <ScopeButton //@ts-ignore
                width="88px"
                height="25px"
                type="default"
                fontSize="12px"
                lineheight="12px"
                onClick={handleClearRC}
              >
                Clear
              </ScopeButton>

              <ScopeButton //@ts-ignore
                width="88px"
                height="25px"
                type="primary"
                fontSize="12px"
                lineheight="12px"
                onClick={handleSaveRC}
              >
                Update
              </ScopeButton>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`control-box ${
              !useCashAccounting && 'control-box-small'
            }`}
          >
            <div className="control-line-box">
              <span>Enable Cash Accounting</span>
              <RoundAddButton disabled={!hasCashAccounting} onClick={onCAClick}>
                {useCashAccounting ? '-' : '+'}
              </RoundAddButton>
            </div>
          </div>

          {useCashAccounting ? (
            <>
              <div className={'cash-accounting-box'}>
                <div className="mapping-details" style={{ width: '95%' }}>
                  Select the tax rates which will be processed as cash
                  accounting. Ignore this section if reporting is on an accrual
                  basis.
                </div>
                <div className="divider-10" />
                <div className="scroller-ca">
                  {taxRates?.map((tr) => (
                    <div style={{ margin: '7px 0px' }}>{getCACheckbox(tr)}</div>
                  ))}
                </div>
              </div>
              <div className={`bottom-box bottom-box-no-shadow bottom-box-ca`}>
                <div style={{ display: 'flex', gap: 10 }}>
                  <ScopeButton //@ts-ignore
                    width="88px"
                    height="25px"
                    type="default"
                    fontSize="12px"
                    lineheight="12px"
                    onClick={handleSelectAllCA}
                  >
                    Select All
                  </ScopeButton>
                  <ScopeButton //@ts-ignore
                    width="88px"
                    height="25px"
                    type="default"
                    fontSize="12px"
                    lineheight="12px"
                    onClick={handleClearCA}
                  >
                    Clear
                  </ScopeButton>
                </div>
                <ScopeButton //@ts-ignore
                  width="88px"
                  height="25px"
                  type="primary"
                  fontSize="12px"
                  lineheight="12px"
                  onClick={handleSaveCA}
                >
                  Update
                </ScopeButton>
              </div>
            </>
          ) : (
            <>
              <div
                className={`control-box ${top ? 'control-box-no-shadow' : ''}`}
              >
                <div className="control-line-box">
                  <span style={{ color: '#058181 !important' }}>
                    Tax Rate Mapping
                  </span>
                </div>
              </div>
              <div className="mapping-list-box">
                <div
                  id="scrollable"
                  className="scroller"
                  onScroll={handleScroll}
                >
                  {taxRates?.map((tr) => {
                    color = !color;
                    isOutOfScope = false;
                    return (
                      <div
                        style={{
                          background: color ? '#F3F7F8' : '#FFFFFF',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                        key={tr.id}
                      >
                        <div style={{ width: 180 }}>
                          <Text
                            ellipsis={{ tooltip: tr.name }}
                            style={{
                              fontSize: 12,
                              padding: '2px 0',
                              color: '#042A2A',
                            }}
                          >
                            {tr.name}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            width: 150,
                            flexWrap: 'wrap',
                          }}
                        >
                          {getSelectedRates(tr.taxType)}
                        </div>
                        <div>{getInputOutput(tr.taxType)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={`bottom-box ${bottom ? 'bottom-box-no-shadow' : ''}`}
              />
              <div className="divider-10" />
              <Space size={'small'}>
                <StyledPopconfirm // @ts-ignore
                  placement="left"
                  title="Reset form"
                  description="Are you sure you want to remove all mapping data?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={resetForm}
                >
                  <ScopeButton
                    type="default" //@ts-ignore
                    //width="100%"
                    disabled={useCashReverse}
                    width="190px"
                    bg_color="#f3f7f8"
                  >
                    {t('common.reset')}
                  </ScopeButton>
                </StyledPopconfirm>

                <ScopeButton
                  onClick={() => onSubmit()}
                  type="primary" //@ts-ignore
                  width="190px"
                  disabled={useCashReverse}
                >
                  {t('common.save')}
                </ScopeButton>
              </Space>
            </>
          )}
        </>
      )}
    </SidePanelWrapper>
  );
};
