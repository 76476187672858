import { call, put } from 'redux-saga/effects';
import { ApiError } from 'store/api/types';
import { CheckContactsRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { CheckContactsResponse, checkContacts } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { checkContactsFailure, checkContactsSuccess } from '../actions';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* checkContactsSaga(action: CheckContactsRequestAction) {
  try {
    let err: ApiError | null;
    const { connectionId } = action.payload;

    const response: CheckContactsResponse = yield call(
      checkContacts,
      connectionId
    );
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success',
      description: 'Contacts were checked.',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    const lastViesCheckDate = response.data.lastViesCheckDate;
    const contacts = response.data.contacts;

    yield put(checkContactsSuccess(lastViesCheckDate, contacts, connectionId));
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(checkContactsFailure());
  }
}
