import React, { useEffect, useState } from 'react';
import { MappingData, ReturnData } from 'store/returns/models';
import { Col, Layout, Row } from 'antd';
import { MapTaxRates } from './map-tax-rates';
import { Section } from 'store/returns/models/returnData';
import { SidePanel } from './side-panel';
import {
  ReverseCharge,
  SelectedRate,
  StepOneData,
  TaxRateOption,
} from 'pages/returns';
import { TaxRateType } from 'store/app/enums';
import {
  PreferenceSections,
  PreferenceActions,
  Preference,
} from 'utils/preferences';

interface AddMappingFormProps {
  preLoading: boolean;
  taxRatesLoading: boolean;
  loading: boolean;
  returnData?: ReturnData;
  mappingData?: MappingData;
  stepOneData: StepOneData;
  form2: any;
  editMode: boolean;
  selectedRates: SelectedRate[];
  selectedRatesCopy: SelectedRate[];
  reverseCharges: ReverseCharge[];
  outOfScopeCode: string;
  cashAccounting: boolean;
  handleCashAccounting: (rateId: string) => void;
  handleReverseCharge: (rateId: string, value: string | null) => void;
  addUnmappedToOutOfScope: () => void;
  updateSelectedRates: (mappedData: SelectedRate[]) => void;
  sections?: Section[];
  taxRateOptions?: TaxRateOption[];
  outOfScopeOptions?: TaxRateOption[];
  handleTaxRangeChange: (
    rateIds: string[],
    outOfScope: boolean,
    code: string
  ) => void;
  setOptions: (trOptions: TaxRateOption[], oosOptions: TaxRateOption[]) => void;
  prepareTaxRateOptions: () => TaxRateOption[];
  hasTemplates: boolean;
  loadTemplateMappings: () => void;
  openTemplateWindow: () => void;
  resetForm: () => void;
  editInitialData: () => void;
  saveCashAccounting: () => void;
  saveReverseCharges: () => boolean;
  resetReverseCharge: () => void;
  fillCashAccounting: (value: boolean) => void;
  loadCashAccounting: () => void;
  loadReverseCharge: () => void;
  onSubmit: () => void;
  updateTaxRateType: (type: TaxRateType, rateId: string | any[]) => void;
  handlePreferences: (
    preferenceSection: PreferenceSections,
    action: PreferenceActions,
    preference: Preference
  ) => any;
}

export const mapTaxRatesStyle: React.CSSProperties = {
  backgroundColor: 'white',
  padding: '40px 30px 0 40px',
};

export const MappingForm: React.FC<AddMappingFormProps> = ({
  preLoading,
  taxRatesLoading,
  loading,
  returnData,
  mappingData,
  stepOneData,
  form2,
  editMode,
  selectedRates,
  selectedRatesCopy,
  reverseCharges,
  outOfScopeCode,
  cashAccounting,
  handleCashAccounting,
  handleReverseCharge,
  addUnmappedToOutOfScope,
  updateSelectedRates,
  sections,
  taxRateOptions,
  outOfScopeOptions,
  handleTaxRangeChange,
  setOptions,
  prepareTaxRateOptions,
  hasTemplates,
  loadTemplateMappings,
  openTemplateWindow,
  resetForm,
  editInitialData,
  saveCashAccounting,
  saveReverseCharges,
  resetReverseCharge,
  fillCashAccounting,
  loadCashAccounting,
  loadReverseCharge,
  onSubmit,
  updateTaxRateType,
  handlePreferences,
}) => {
  const [useCashAccounting, setUseCashAccounting] = useState<boolean>(false);
  const [useReverseCharge, setUseReverseCharge] = useState<boolean>(false);
  const toggleCA = (value: boolean) => setUseCashAccounting(value);
  const toggleRC = (value: boolean) => setUseReverseCharge(value);

  useEffect(() => {
    !loading && loadTemplateMappings();
  }, [editMode, loading]);

  if (taxRatesLoading || loading) return null;
  return (
    <Layout style={{ background: '#F3F7F8' }}>
      <Row justify={'space-between'} style={{ paddingRight: 450 }}>
        <Col span={24} style={{ background: '#FFFFFF' }}>
          {/* <Row> */}
          {/* <Col span={24}>
              <Row justify={'center'}>
                <Col span={24}> */}
          {!preLoading && (
            <>
              <div style={mapTaxRatesStyle}>
                <MapTaxRates
                  sections={sections}
                  taxRateOptions={taxRateOptions}
                  outOfScopeOptions={outOfScopeOptions}
                  outOfScopeCode={outOfScopeCode}
                  handleTaxRangeChange={handleTaxRangeChange}
                  form={form2}
                  mappingData={mappingData}
                  taxRates={returnData?.taxRates}
                  updateSelectedRates={updateSelectedRates}
                  setOptions={setOptions}
                  prepareTaxRateOptions={prepareTaxRateOptions}
                  addUnmapped={addUnmappedToOutOfScope}
                  useCashReverse={useCashAccounting || useReverseCharge}
                  selectedRates={selectedRates}
                  updateTaxRateType={updateTaxRateType}
                  stepOneData={stepOneData}
                  useCashAccounting={useCashAccounting}
                  editInitialData={editInitialData}
                  hasTemplates={hasTemplates}
                  openTemplateWindow={openTemplateWindow}
                  useReverseCharge={useReverseCharge}
                  handlePreferences={handlePreferences}
                />
              </div>
            </>
          )}
          {/* </Col>
              </Row>
            </Col> */}
          {/* </Row> */}
        </Col>
      </Row>

      <div
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          right: 0,
          maxWidth: 450,
          width: 450,
        }}
      >
        <SidePanel
          selectedRates={selectedRates}
          selectedRatesCopy={selectedRatesCopy}
          reverseCharges={reverseCharges}
          taxRates={returnData?.taxRates}
          outOfScopeCode={outOfScopeCode}
          handleCashAccounting={handleCashAccounting}
          handleReverseCharge={handleReverseCharge}
          hasCashAccounting={cashAccounting}
          saveCashAccounting={saveCashAccounting}
          saveReverseCharges={saveReverseCharges}
          fillCashAccounting={fillCashAccounting}
          resetReverseCharge={resetReverseCharge}
          loadCashAccounting={loadCashAccounting}
          loadReverseCharge={loadReverseCharge}
          useCashAccounting={useCashAccounting}
          toggleCA={toggleCA}
          useReverseCharge={useReverseCharge}
          toggleRC={toggleRC}
          updateTaxRateType={updateTaxRateType}
          onSubmit={onSubmit}
          resetForm={resetForm}
          useCashReverse={useCashAccounting || useReverseCharge}
        />
      </div>
    </Layout>
  );
};
