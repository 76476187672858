import { call, put, select } from 'redux-saga/effects';
import { deleteMappingFailure, deleteMappingSuccess } from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { DeleteMappingRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { deleteMapping } from '../api';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import {
  getOrgMappings,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import { fetchOrganisationsSuccess } from 'store/organisation/actions';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* deleteMappingSaga(action: DeleteMappingRequestAction) {
  try {
    let err: ApiError | null;
    const { organisationId, mappingId } = action.payload;

    const deleteMappingResponse: ApiResponse = yield call(
      deleteMapping,
      organisationId,
      mappingId
    );

    err = checkResponse(deleteMappingResponse?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success!',
      description: deleteMappingResponse.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));

    const orgs = yield select(getOrgOrganisations);
    const maps = yield select(getOrgMappings);
    const idx = maps.findIndex(
      (m) => m.mappingId === mappingId && m.organisationId === organisationId
    );
    if (idx !== -1) {
      maps.splice(idx, 1);
      yield put(fetchOrganisationsSuccess(orgs, maps));
    }
    yield put(deleteMappingSuccess());
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(deleteMappingFailure());
  }
}
