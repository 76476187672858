//@ts-nocheck
import { Input, InputProps } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';
import styled from 'styled-components';

const { TextArea } = Input;

export const FormInput = styled(Input)`
  width: ${(props) => (props.width ? props.width : '90%')};
  border-radius: 0;
  border: 1px solid transparent;
  padding: ${(props) => (props.addonAfter ? '0px 0px' : '6px 11px')};
  min-width: 25%;
  height: 45px;
  border: 1px solid
    ${(props) => (props.border_color ? props.border_color : 'transparent')};
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #41aaaf;
    box-shadow: none;
  }
  // .ant-select-selection-item {
  //   //background: red !important;
  // }

  .ant-input-outlined {
    border-radius: 0 !important;
    border: 1px solid transparent !important;
    width: ${(props) => (props.width ? props.width : '90%')} !important;
    height: 43px;
    padding: 0px 10px !important;
  }

  .ant-input-group {
    width: ${(props) => (props.width ? props.width : '90%')} !important;
  }

  .ant-input-group-addon {
    border-radius: 0 !important;
    border: 1px solid transparent !important;
    background: white;
  }
`;
interface FormPasswordInputProps extends InputProps {
  border_color?: string;
  lmargin?: string;
}
export const FormPasswordInput = styled(Input.Password)<FormPasswordInputProps>`
  width: 330px;
  border-radius: 0;
  border: 1px solid
    ${(props) => (props.border_color ? props.border_color : 'transparent')};
  padding: 6px 11px;
  height: 45px;
  margin-left: ${(props) => (props.lmargin ? props.lmargin : '0')};
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #41aaaf;
    box-shadow: none;
  }
`;

export const FormPasswordInputWithValidation = styled(PasswordInput)`
  width: 330px;
  border-radius: 0;
  border: 1px solid transparent;
  padding: 6px 11px;
  height: 45px;
  margin-right: ${(props) => (props.rmargin ? props.rmargin : '0')};
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #41aaaf;
    box-shadow: none;
  }
`;

export const RoundInput = styled(Input)`
  width: ${(props) => (props.width ? props.width : '305px')};
  height: ${(props) => (props.height ? props.height : '40px')};
  border-radius: 100px;
  border: 1px solid transparent;
  font-size: 14px;
  background: ${(props) => (props.bg_color ? props.bg_color : '#f3f7f8')};
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #41aaaf;
    box-shadow: none;
  }
  .ant-input {
    background: #f3f7f8;
  }
`;

export const OTPInput = styled(Input.OTP)`
  #mfa-code > input {
    width: 55px !important;
    height: 65px !important;
    border-radius: 5px;
  }
`;

export const FormTextArea = styled(TextArea)`
  width: 317px !important;
  height: 161px !important;
  border: 1px solid #e9f6f7 !important;
`;
