import {
  Badge,
  Collapse,
  CollapseProps,
  Space,
  Switch,
  Typography,
} from 'antd';
import * as React from 'react';
import { Mapping } from 'store/organisation/models';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { StyledCard } from 'components/Custom/card';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { CSSProperties } from 'styled-components';
import { CardTitleLargeSpan } from 'components/Custom/spans';
import { ScopeButton } from 'components/Custom/buttons';
import { CardInfo } from './card-info';
import { ScopeTable } from 'components/Custom/table';
import { VatReturnLog } from 'store/vat/models';
import { AvatarSizes, renderAvatar } from 'utils/render-avatar';
import { StatusGetter } from 'utils/status-getter';
import { DataLogStatus } from 'store/app/enums';
import { AccountType, ReturnFrequency } from 'store/api/enums';
import { calculateNextPeriod } from 'utils/calculate-next-period';
import { User } from 'store/auth/models';
import { StyledTooltip } from 'components/Custom/Tooltip';
import { Preference, PreferenceActions } from 'utils/preferences';
import {
  HIDE_CANCELLED_ENTITY,
  PAGE_SIZE_ENTITY,
} from 'pages/organisations/organisation';

interface MappingDetailsProps {
  mapping: Mapping;
  countryName: string | undefined;
  connectionId: string | undefined;
  vatReturnLogs: VatReturnLog[];
  vatGenerate: (
    connectionId: string,
    mappingId: string,
    dataLogId?: string
  ) => void;
  editReturnMapping: (connectionId: string, mappingId: string) => void;
  connected: boolean;
  currentUser?: User;
  handlePreferences: (action: PreferenceActions, preference: Preference) => any;
}

const { Text } = Typography;

export const MappingDetails: React.FC<MappingDetailsProps> = ({
  mapping,
  countryName,
  connectionId,
  vatReturnLogs,
  vatGenerate,
  editReturnMapping,
  connected,
  currentUser,
  handlePreferences,
}) => {
  const { t } = useTranslation();
  const pageSizeSaved = handlePreferences(PreferenceActions.GET, {
    name: PAGE_SIZE_ENTITY + '-' + mapping.mappingId,
  });
  const hideCancelledSaved = handlePreferences(PreferenceActions.GET, {
    name: HIDE_CANCELLED_ENTITY + '-' + mapping.mappingId,
  });

  const [hideCancelled, setHideCancelled] = React.useState(hideCancelledSaved);
  const getVatLogsPerMapping = () => {
    if (
      (currentUser &&
        AccountType[currentUser.accountType] === AccountType.CLIENT) ||
      hideCancelled
    )
      return vatReturnLogs?.filter(
        (vat) =>
          vat.mappingId === mapping.mappingId &&
          DataLogStatus[vat.status] !== DataLogStatus.CANCELLED
      );
    else
      return vatReturnLogs?.filter(
        (vat) => vat.mappingId === mapping.mappingId
      );
  };

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };

  const renderSubmissionDate = (_, record: VatReturnLog) => {
    const result = '';
    if (DataLogStatus[record?.status] == DataLogStatus.CANCELLED) {
      return result;
    } else {
      return record?.submissionDate
        ? moment(record?.submissionDate).format('DD/MM/YY')
        : '';
    }
  };

  const renderPeriodDates = (_, record: VatReturnLog) => {
    //const result = '';
    //if (DataLogStatus[record?.status] == DataLogStatus.CANCELLED) {
    //  return result;
    //} else {
    return `${
      record?.fromReportingDate
        ? moment(record?.fromReportingDate).format('DD/MM/YY')
        : ''
    } - ${
      record?.toReportingDate
        ? moment(record?.toReportingDate).format('DD/MM/YY')
        : ''
    }`;
    //}
  };

  const columns = [
    {
      title: 'Period',
      dataIndex: 'fromReportingDate',
      key: 'fromReportingDate',
      width: '160px',
      render: renderPeriodDates,
      // defaultSortOrder: 'ascend',
      // sorter: (a, b) =>
      //   new Date(a.nextDueDate).valueOf() - new Date(b.nextDueDate).valueOf(),
      // sortIcon: renderSortIcon,
    },
    {
      title: t('main.preparer'),
      dataIndex: 'savedByUserFullName',
      key: 'savedByUserFullName',
      //className: 'no-padding',
      //align: 'center',
      sorter: (a, b) =>
        a.savedByUserFullName?.localeCompare(b.savedByUserFullName),
      sortIcon: renderSortIcon,
      render: (_, record: VatReturnLog) => {
        if (
          currentUser &&
          AccountType[currentUser.accountType] === AccountType.CLIENT
        )
          return;
        if (record.preparerUser) {
          const userId = record.preparerUser?.userUuid;
          return (
            <Space>
              {renderAvatar(
                record.preparerUser?.firstName,
                record.preparerUser?.lastName,
                AvatarSizes.SMALL,
                userId,
                record.preparerUser?.profilePicture
              )}
              <Text
                style={{
                  minWidth: 100,
                  fontSize: 11,
                  fontWeight: 400,
                  color: '#042a2a',
                }} //@ts-ignore
                ellipsis={{ rows: 1, tooltip: true }}
              >{`${record.preparerUser?.firstName} ${record.preparerUser?.lastName}`}</Text>
            </Space>
          );
        }
      },
    },
    {
      title: t('main.reviewer'),
      dataIndex: 'reviewerUserFullName',
      key: 'reviewerUserFullName',
      sorter: (a, b) =>
        a.reviewerUserFullName?.localeCompare(b.reviewerUserFullName),
      sortIcon: renderSortIcon,
      render: (_, record: VatReturnLog) => {
        if (
          currentUser &&
          AccountType[currentUser.accountType] === AccountType.CLIENT
        )
          return;
        if (record.reviewerUser) {
          const userId = record.reviewerUser.userUuid;
          return (
            <Space>
              {renderAvatar(
                record.reviewerUser.firstName,
                record.reviewerUser.lastName,
                AvatarSizes.SMALL,
                userId,
                record.reviewerUser.profilePicture
              )}
              <Text
                style={{
                  minWidth: 100,
                  fontSize: 11,
                  fontWeight: 400,
                  color: '#042a2a',
                }} //@ts-ignore
                ellipsis={{ rows: 1, tooltip: true }}
              >{`${record.reviewerUser?.firstName} ${record.reviewerUser?.lastName}`}</Text>
            </Space>
          );
        }
      },
      width: 100,
    },
    {
      title: t('main.approver'),
      dataIndex: 'approvedBy',
      key: 'approvedBy',
      sorter: (a, b) => a.approvedBy?.localeCompare(b.approvedBy),
      sortIcon: renderSortIcon,
      render: (_, record: VatReturnLog) => {
        if (record.clientUser) {
          const userId = record.clientUser.userUuid;
          return (
            <Space>
              {renderAvatar(
                record.clientUser.firstName,
                record.clientUser.lastName,
                AvatarSizes.SMALL,
                userId,
                record.clientUser.profilePicture
              )}
              <Text
                style={{
                  minWidth: 100,
                  fontSize: 11,
                  fontWeight: 400,
                  color: '#042a2a',
                }} //@ts-ignore
                ellipsis={{ rows: 1, tooltip: true }}
              >
                {`${record.clientUser?.firstName} ${record.clientUser?.lastName}`}
              </Text>
            </Space>
          );
        }
      },
    },
    {
      title: t('main.status'),
      dataIndex: 'status',
      key: 'status',
      render: (content) => (
        <StatusGetter status={DataLogStatus[content]} noDraft smaller />
      ),
      width: 100,
      sorter: (a, b) => a.status?.localeCompare(b.status),
      sortIcon: renderSortIcon,
    },
    {
      title: t('organisation.submission-date'),
      dataIndex: 'submissionDate',
      key: 'submissionDate',
      align: 'left',
      width: '100px',
      render: renderSubmissionDate,
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        new Date(a.nextDueDate).valueOf() - new Date(b.nextDueDate).valueOf(),
      sortIcon: renderSortIcon,
    },

    {
      title: t('main.action-required'),
      width: '100px',
      render: (_, record: VatReturnLog) => {
        const onClick = () => {
          connectionId &&
            vatGenerate(connectionId, mapping.mappingId, record.uuid);
        };
        return (
          mapping.mappingId && (
            <>
              <ScopeButton //@ts-ignore
                width="70px"
                height="25px"
                fontSize="12px"
                lineheight="12px"
                onClick={onClick}
                type="primary"
              >
                {(DataLogStatus[record.status] ==
                  DataLogStatus.AWAITING_APPROVAL &&
                  record.reviewerUser?.userUuid === currentUser?.userUuid) ||
                (DataLogStatus[record.status] ==
                  DataLogStatus.AWAITING_CLIENT_APPROVAL &&
                  record.clientUser?.userUuid === currentUser?.userUuid)
                  ? t('main.review')
                  : t('main.open')}
              </ScopeButton>
            </>
          )
        );
      },
    },
  ];

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: 'white',
    borderRadius: 50,
    border: 'none',
  };

  const renderExtraArea = () => {
    if (
      currentUser &&
      AccountType[currentUser.accountType] !== AccountType.CLIENT
    )
      return (
        <Space>
          {currentUser &&
          AccountType[currentUser.accountType] !== AccountType.CLIENT &&
          connected &&
          mappingNoSubmissions() ? (
            <ScopeButton
              type="default" //@ts-ignore
              width="185px"
              height="32px"
              style={{ fontSize: '13px', fontWeight: 700 }}
              onClick={() =>
                connectionId &&
                editReturnMapping(connectionId, mapping.mappingId)
              }
            >
              Edit Tax Return
            </ScopeButton>
          ) : (
            <StyledTooltip // @ts-ignore
              title={
                connected
                  ? 'Edit cannot happen due to a submission in progress'
                  : 'Edit disabled due to a disconnected entitty'
              }
              placement="top"
              arrow={false}
            >
              <ScopeButton
                type="default" //@ts-ignore
                width="185px"
                height="32px"
                style={{ fontSize: '13px', fontWeight: 700 }}
                onClick={() =>
                  connectionId &&
                  editReturnMapping(connectionId, mapping.mappingId)
                }
                disabled
              >
                Edit Tax Return
              </ScopeButton>
            </StyledTooltip>
          )}
          <ScopeButton
            type="primary" //@ts-ignore
            width="185px"
            height="32px"
            style={{ fontSize: '13px', fontWeight: 700 }}
            onClick={() =>
              connectionId &&
              vatGenerate(connectionId, mapping.mappingId, undefined)
            }
            disabled={!connected}
          >
            {t('organisation.create-tax-submission')}
          </ScopeButton>
        </Space>
      );
  };
  const mappingNoSubmissions = () => {
    const allSubmissions = getVatLogsPerMapping();
    const startedSubmissions = allSubmissions.filter(
      (as) =>
        DataLogStatus[as.status] !== DataLogStatus.CANCELLED &&
        DataLogStatus[as.status] !== DataLogStatus.SUBMITTED
    );
    if (startedSubmissions && startedSubmissions.length > 0) {
      return false;
    } else return true;
  };
  const renderAccordionHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          gap: 14,
          width: '100%',
          marginBottom: mapping?.dataLog ? 15 : 0,
        }}
      >
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CardTitleLargeSpan>{`${countryName} - ${mapping.mappingName}`}</CardTitleLargeSpan>
          </div>
          <div style={{ float: 'right' }}>{renderExtraArea()}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            flexWrap: 'wrap',
            gap: 20,
            columnGap: 50,
          }}
        >
          <CardInfo
            label={t('organisation.created-by')}
            data={
              <Text
                ellipsis={{ tooltip: mapping.createdBy }}
                style={{
                  //fontSize: 12,
                  //   padding: '2px 0',
                  //color: '#879494',
                  width: 180,
                }}
              >
                {mapping.createdBy}
              </Text>
            }
            haveBorder
          />

          <CardInfo
            label={t('organisation.created-on')}
            data={moment(mapping.createdDate).format('DD/MM/YYYY')}
            haveBorder
          />

          <CardInfo
            label={t('organisation.return-frequency')}
            data={
              mapping.returnFrequency
                ? ReturnFrequency[mapping.returnFrequency]
                : 'N/A'
            }
            haveBorder
          />

          {/* <CardInfo
            label={t('organisation.return-from-date')}
            data={moment(mapping.periodFromDate).format('DD/MM/YYYY')}
            haveBorder
          /> */}
          <CardInfo
            label={t('organisation.next-period')}
            //data={mapping.nextPeriod}
            data={nextPeriod(mapping.periodFromDate, mapping.returnFrequency)}
            haveBorder
          />
          <CardInfo
            label={t('organisation.next-due-date')}
            data={
              <span
                className={
                  //@ts-ignore
                  new Date(mapping.returnDueDate)?.valueOf() <
                  new Date().valueOf()
                    ? 'expired-due-date'
                    : ''
                }
              >
                {moment(mapping.returnDueDate).format('DD/MM/YYYY')}
              </span>
            }
            haveBorder
          />

          <CardInfo
            label={t('organisation.cash-accounting')}
            data={
              <Badge
                status={mapping.cashAccounting ? 'success' : 'error'}
                text={mapping.cashAccounting ? 'Yes' : 'No'}
              />
            }
            haveBorder
          />
        </div>
      </div>
    );
  };
  const nextPeriod = (selectedStartDate, selectedFrequency) => {
    return calculateNextPeriod(selectedStartDate, selectedFrequency)[0];
  };
  const savePageSize = (pageSize: number) => {
    const mappingId = mapping.mappingId;

    handlePreferences(PreferenceActions.SET, {
      name: PAGE_SIZE_ENTITY + '-' + mappingId,
      value: pageSize,
    });
  };

  const onHiddenSwitch = () => {
    setHideCancelled(!hideCancelled);
    handlePreferences(PreferenceActions.SET, {
      name: HIDE_CANCELLED_ENTITY + '-' + mapping.mappingId,
      value: !hideCancelled,
    });
  };

  const renderAccordionBody = () => {
    return (
      <>
        {getVatLogsPerMapping() && getVatLogsPerMapping().length > 0 && (
          <div
            style={{
              //maxWidth: 950,
              minWidth: 600,
              margin: 'auto',
              borderTop: mapping?.dataLog ? '1px solid #DBEAE3' : 'none',
            }}
          >
            <ScopeTable
              filters={[]}
              columns={columns}
              mainLocaleKey="users"
              originalDataSource={getVatLogsPerMapping()} //@ts-ignore
              tableDataSource={getVatLogsPerMapping()}
              pagination={true}
              rowsPerPage={pageSizeSaved ? pageSizeSaved : 6}
              enableColumnsCustomization={false}
              rowHeight={45}
              titleComponent={
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <CardTitleLargeSpan style={{ fontSize: 18 }}>
                    {t('organisation.submissions')}
                  </CardTitleLargeSpan>
                  <Space>
                    <span style={{ fontSize: 12 }}>Hide Cancelled</span>
                    <Switch
                      size={'small'}
                      checked={hideCancelled}
                      className={hideCancelled ? 'switch-checked' : ''}
                      onChange={onHiddenSwitch}
                    />
                  </Space>
                </div>
              }
              showSizeChanger
              getNewPageSize={savePageSize}
              filterSave={() => {}}
            />
          </div>
        )}
      </>
    );
  };

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
    panelStyle
  ) => [
    {
      key: '1',
      label: renderAccordionHeader(),
      children: renderAccordionBody(),
      style: panelStyle,
      //extra: renderExtraArea(),
    },
  ];

  return (
    <StyledCard //@ts-ignore
      //width={'fit-content'}
      width="100%"
      style={{
        //maxWidth: 1250,
        //width: '100%' /*minWidth: 916.6*/,
        overflow: 'auto',
      }}
    >
      <Collapse
        collapsible="icon"
        bordered={false}
        defaultActiveKey={['1']}
        items={getItems(panelStyle)}
      />
    </StyledCard>
  );
};
