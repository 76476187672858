import { call, put } from 'redux-saga/effects';
import { activateAccountFailure, activateAccountSuccess } from '../actions';
import { activate } from '../api';
import { ActivateAccountRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { fetchMeRequest, logoutRequest } from 'store/auth/actions';
import { push } from 'connected-react-router';
import routes from 'routes';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* activateAccountSaga(action: ActivateAccountRequestAction) {
  //yield put(logoutRequest());
  try {
    let err: ApiError | null;
    const { user, resetPassword } = action.payload;
    const response: ApiResponse = yield call(activate, user, resetPassword);
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    let message: MessageData = {
      title: 'Activated',
      description: response?.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));
    yield put(fetchMeRequest());
    yield put(push(routes.main));
    yield put(activateAccountSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(activateAccountFailure());
  }
}
