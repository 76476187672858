import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from 'store/auth/selectors';
import { Col, Row, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ExportButton, ScopeButton } from 'components/Custom/buttons';
import { getCurrentPage, getMappingData } from 'store/app/selectors';
import { turnDrawerOn } from 'store/app/actions';
import { DrawerData } from 'store/app/types';
import { DrawerType, MenuSize, Pages } from 'store/app/enums';
import { ConnectEntity } from 'components/ConnectEntity';
import {
  getOrgGettingTaxes,
  getOrgMappedReturns,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import routes from 'routes';
import { getReturnData } from 'store/returns/selectors';
import { ReactComponent as IconBack } from 'assets/images/icons/iconBackVector.svg';
import { ReactComponent as IconRefresh } from 'assets/images/icons/iconRefresh.svg';
import { Link } from 'react-router-dom';
import { getUserMembers } from 'store/user/selectors';
import { getVatRunData } from 'store/vat/selectors';
import { getViesContacts } from 'store/vat-checker/selectors';
import { StyledTooltip } from 'components/Custom/Tooltip';

interface PageTitle {
  main: string;
  pre: string | null;
}

interface HeaderProps {
  menuSize: MenuSize;
}

export const Header: React.FC<HeaderProps> = ({ menuSize }) => {
  const loggedUser = useSelector(getAuthUser);
  const currentPage = useSelector(getCurrentPage);
  const mappedReturns = useSelector(getOrgMappedReturns);
  const mappingData = useSelector(getMappingData);
  const returnData = useSelector(getReturnData);
  const vatRunData = useSelector(getVatRunData);
  const members = useSelector(getUserMembers);
  const gettingTaxes = useSelector(getOrgGettingTaxes);
  const orgs = useSelector(getOrgOrganisations);
  const contacts = useSelector(getViesContacts);

  //const organisations = useSelector(getOrgMappedOrganisations);
  const dispatch = useDispatch();
  const [backLink, setBackLink] = React.useState(routes.main);
  const [showLine, setShowLine] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState<PageTitle | null>({
    main: currentPage.name,
    pre: null,
  });

  const onAddEntity = () => {
    const drawerData: DrawerData = {
      title: 'Add a new entity',
      subtitle: 'Connect one of the below',
      type: DrawerType.CONNECT_ENTITY,
      component: <ConnectEntity />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  React.useEffect(() => {
    if (
      currentPage.name === Pages.ENTITIES &&
      currentPage.route.includes('organisation')
    ) {
      const orgIdIndex = routes.organisations.organisation.indexOf(':id');
      const orgId = currentPage.route.substring(orgIdIndex);
      const orgName = mappedReturns?.find(
        (mr) => mr.organisationId === orgId
      )?.organisation;

      if (orgName) {
        setPageTitle({ pre: currentPage.name + ' / ', main: orgName });
        setBackLink(routes.organisations.all);
        setShowLine(false);
      }
    } else if (currentPage.name === Pages.RETURN) {
      const orgIdIndex = routes.returns.edit.indexOf(':connectionId');
      setShowLine(true);
      if (currentPage.route.includes('editMapping')) {
        const orgIdEnd = currentPage.route.indexOf('/editMapping');
        const conId = currentPage.route.substring(orgIdIndex, orgIdEnd);
        const orgName = mappedReturns?.find(
          (mr) => mr.connectionId === conId
        )?.organisation;
        const orgId = mappedReturns?.find(
          (mr) => mr.connectionId === conId
        )?.organisationId;
        const mapId = currentPage.route.substring(orgIdEnd + 13);
        const typeId = mappedReturns?.find(
          (mr) => mr.mappingId === mapId
        )?.returnTypeId;
        const selReturnType = returnData?.returnTypesEdit?.find(
          (rt) => rt.id === typeId
        );
        const mappingName = selReturnType
          ? selReturnType.countryName + ' - ' + selReturnType.name
          : '';
        setPageTitle({
          pre: Pages.ENTITIES + ' / ' + orgName + ' / ',
          main: mappingName,
        });
        orgId &&
          setBackLink(routes.organisations.organisation.replace(':id', orgId));
      } else {
        const conId = currentPage.route.substring(orgIdIndex);
        const orgName = orgs?.find(
          (o) => o.connectionId === conId
        )?.friendlyName;
        const orgId = orgs?.find((o) => o.connectionId === conId)?.uuid;
        const selReturnType = returnData?.returnTypesEdit?.find(
          (rt) => rt.id === mappingData?.typeId
        );
        const mappingName = selReturnType
          ? selReturnType.countryName + ' - ' + selReturnType.name
          : '';
        setPageTitle({
          pre: Pages.ENTITIES + ' / ' + orgName + ' / ',
          main: mappingName,
        });
        orgId &&
          setBackLink(routes.organisations.organisation.replace(':id', orgId));
      }
    } else if (currentPage.route.includes('profile/')) {
      const accountIdIndex = routes.account.details.indexOf(':accountId?');
      const accountId = currentPage.route.substring(accountIdIndex);
      const accountObj = members?.find((mr) => mr.accountUuid === accountId);

      if (accountObj) {
        setPageTitle({
          pre: Pages.USERS + ' / ',
          main: `${accountObj.firstName} ${accountObj.lastName}`,
        });
        setBackLink(routes.users);
        setShowLine(false);
      }
    } else if (currentPage.name === Pages.SUBMISSION) {
      const selMapOrg = mappedReturns?.find(
        (mr) => mr.mappingId === vatRunData?.mappingId
      );
      const conId = selMapOrg?.connectionId;
      const orgName = mappedReturns?.find(
        (mr) => mr.connectionId === conId
      )?.organisation;
      const orgId = mappedReturns?.find(
        (mr) => mr.connectionId === conId
      )?.organisationId;
      const selReturnType = selMapOrg?.returnTypesEdit?.find(
        (rt) => rt.id === selMapOrg?.returnTypeId
      );
      const mappingName = selReturnType
        ? selReturnType.countryName + ' - ' + selReturnType.name
        : '';
      const submissionName = `Submission ${vatRunData?.generationPeriod}`;
      setPageTitle({
        pre: Pages.ENTITIES + ' / ' + orgName + ' / ' + mappingName + ' / ',
        main: submissionName,
      });
      orgId &&
        setBackLink(routes.organisations.organisation.replace(':id', orgId));
    } else if (
      currentPage.name === Pages.VIES &&
      currentPage.route.includes('/customers-and-suppliers/')
    ) {
      const idx = routes.vies.customersAndSuppliers.indexOf(':connectionId');
      const conId = currentPage.route.substring(idx);

      const orgName = orgs?.find((o) => o.connectionId === conId)?.friendlyName;

      setPageTitle({
        pre: Pages.VIES + ' / ' + orgName + ' / ',
        main: 'Customers & Suppliers',
      });
      setBackLink(routes.vies.qualifiedEntities);
    } else if (
      currentPage.name === Pages.VIES &&
      currentPage.route.includes('/vat-checker/contact-details/') &&
      contacts &&
      orgs
    ) {
      const idx = routes.vies.contact.indexOf(':connectionId');
      const endIdx = currentPage.route.indexOf('/contact/');
      const conId = currentPage.route.substring(idx, endIdx);
      const orgName = orgs?.find((o) => o.connectionId === conId)?.friendlyName;

      const contId = currentPage.route.lastIndexOf('/');
      const contactId = currentPage.route.substring(contId + 1);
      const conatctName: any = contacts?.find(
        (o) => o?.contactUuid === contactId
      )?.name;

      setPageTitle({
        pre:
          Pages.VIES +
          ' / ' +
          orgName +
          ' / ' +
          'Customers & Suppliers' +
          ' / ',
        main: conatctName,
      });
      conId &&
        setBackLink(
          routes.vies.customersAndSuppliers.replace(':connectionId', conId)
        );
    } else {
      setPageTitle({ main: currentPage.name, pre: null });
      setShowLine(false);
    }
  }, [currentPage, orgs, vatRunData, contacts]);

  const getHeaderWidthClass = () => {
    let className = '';
    if (
      currentPage.name == Pages.DASHBOARD ||
      (currentPage.name == Pages.ENTITIES &&
        !currentPage.route.includes('organisation')) ||
      (currentPage.name == Pages.VIES &&
        !currentPage.route.includes('contact-details')) ||
      currentPage.name == Pages.USERS ||
      (currentPage.name == Pages.VIES &&
        currentPage.route.includes('customers-and-suppliers'))
    ) {
      className = 'header-container-center';
    } else if (
      (currentPage.name == Pages.ENTITIES &&
        currentPage.route.includes('organisation')) ||
      currentPage.route.includes('contact-details') ||
      currentPage.name == Pages.RETURN ||
      currentPage.name == Pages.SUBMISSION ||
      currentPage.name === Pages.SUPPORT ||
      currentPage.name === Pages.WHATS_NEW
    ) {
      className = '';
    }

    return className;
  };

  let refreshMessage = '';
  if (currentPage.name === Pages.ENTITIES)
    refreshMessage = 'Check connections and Update Tax rates';
  if (currentPage.name === Pages.VIES)
    refreshMessage = 'Update VAT checker entities';
  const headerClass =
    menuSize === MenuSize.WIDE ? 'header-narrow' : 'header-wide';
  return (
    <div
      className={`header-container ${headerClass}`}
      style={showLine ? { borderBottom: '1px solid #41aaaf40' } : {}}
    >
      <div className={getHeaderWidthClass()}>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Typography.Title level={4}>
              {pageTitle?.pre && !pageTitle.pre.includes('undefined') && (
                <>
                  <Link to={backLink} style={{ marginRight: 20 }}>
                    <IconBack />
                  </Link>
                  <span
                    style={{ fontWeight: 400, color: '#879494', fontSize: 16 }}
                  >
                    {pageTitle.pre}
                  </span>
                </>
              )}
              {!pageTitle?.main.includes('undefined') && pageTitle?.main}
            </Typography.Title>
          </Col>
          <Col>
            <Space size={'large'}>
              {/* <RoundInput
              prefix={<SearchOutlined />}
              size="large"
              placeholder="Search"
            /> */}
              {gettingTaxes ? (
                <div className="loader" />
              ) : (
                <>
                  {(currentPage.name === Pages.ENTITIES ||
                    currentPage.name === Pages.VIES) && (
                    <StyledTooltip //@ts-ignore
                      title={refreshMessage}
                    >
                      <ExportButton
                        icon={<IconRefresh />}
                        //disabled={refreshDisable(vatRunData) || !showRefreshButton()}
                        onClick={() => {
                          currentPage.name === Pages.ENTITIES &&
                            localStorage.removeItem(
                              `gen-tax-tax-rates-${loggedUser?.userUuid}`
                            );
                          currentPage.name === Pages.VIES &&
                            localStorage.removeItem(
                              `vies-infos-${loggedUser?.userUuid}`
                            );

                          location.reload();
                        }}
                        //loading={loading}
                        //style={{ zIndex: 200, marginRight: 20 }}
                      >
                        Refresh Entities
                      </ExportButton>
                    </StyledTooltip>
                  )}
                </>
              )}
              {loggedUser?.canManageOrgs && (
                <ScopeButton
                  type={'primary'}
                  //onClick={() => connectOrganisation(Provider.XERO)}
                  onClick={onAddEntity} // @ts-ignore
                  width={'170px'}
                  height={'40px'}
                >
                  <Space>
                    <div className="testpic" style={{}}>
                      <PlusOutlined />
                    </div>
                    New Entity
                  </Space>
                </ScopeButton>
              )}
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
};
