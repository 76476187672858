import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import routes from 'routes';
import { useTranslation } from 'react-i18next';
import { getAuthUser } from 'store/auth/selectors';
import { getCommonCountries } from 'store/common/selectors';
import {
  getOrgLoading,
  getOrgMappedOrganisations,
} from 'store/organisation/selectors';
import { getReturnData } from 'store/returns/selectors';
import { getVatPrepLoading } from 'store/vat/selectors';
import { EntityComponent } from 'components/Entity';
import { DrawerType } from 'store/app/enums';
import { clearMappingData, turnDrawerOn } from 'store/app/actions';
import { DrawerData } from 'store/app/types';
import { ChooseReturn } from 'components/MappingForm/choose-return';
import { fetchAddReturnDataRequest } from 'store/returns/actions';
import { ConnectEntity } from 'components/ConnectEntity';
import { InviteUser } from 'components/UsersComponent/invite-user';
import { Entity } from 'store/organisation/models';
import { getUserLoading, getUserMembers } from 'store/user/selectors';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { checkPageLoaded } from 'utils/check-page-loaded';
import {
  Preference,
  PreferenceActions,
  preferences,
  PreferenceSections,
} from 'utils/preferences';

export const PAGE_SIZE_ENTITIES = 'page-size-entities';

export const OrganisationsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const mappedOrganisations = useSelector(getOrgMappedOrganisations);
  const countries = useSelector(getCommonCountries);
  const returnData = useSelector(getReturnData);
  const currentUser = useSelector(getAuthUser);
  const orgLoading = useSelector(getOrgLoading);
  const vatLoading = useSelector(getVatPrepLoading);
  const members = useSelector(getUserMembers);
  const userLoading = useSelector(getUserLoading);
  const loading = orgLoading || vatLoading;
  const [showPage, setShowPage] = useState(false);

  const handlePreferences = (
    action: PreferenceActions,
    preference: Preference
  ) => {
    if (currentUser)
      return preferences(
        action,
        PreferenceSections.ENTITIES,
        preference,
        currentUser?.userUuid
      );
    return false;
  };

  const addEntity = () => {
    const drawerData: DrawerData = {
      title: 'Add a new entity',
      subtitle: 'Connect one of the below',
      type: DrawerType.CONNECT_ENTITY,
      component: <ConnectEntity />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  React.useEffect(() => {
    setShowPage(true);
    currentUser && checkPageLoaded(location.pathname, routes.organisations.all);
  }, []);

  const addReturnMapping = (connectionId: string) => {
    if (connectionId) {
      const drawerData: DrawerData = {
        title: 'Add a Tax Return',
        type: DrawerType.ADD_TAX_RETURN,
        component: (
          <ChooseReturn editMode={false} setSectionTouched={() => {}} />
        ),
      };
      if (!returnData || returnData.connectionId !== connectionId) {
        dispatch(fetchAddReturnDataRequest(connectionId));
      }
      dispatch(clearMappingData());
      dispatch(turnDrawerOn(drawerData));
    } else alert('Missing connection Id.');
  };

  const editReturnMapping = (connectionId: string, mappingId: string) => {
    if (connectionId && mappingId) {
      dispatch(clearMappingData());
      dispatch(
        push(
          routes.returns.edit
            .replace(':connectionId', connectionId)
            .replace(':mappingId', mappingId)
        )
      );
    } else alert('Missing connection Id.');
  };

  const openMemberWindow = (entity: Entity) => {
    const modalData: DrawerData = {
      type: DrawerType.INVITE_USER,
      title: `${t('common.share')} “${entity.organisation} ”`,
      component: (
        <InviteUser
          loading={userLoading}
          members={entity.users} //@ts-ignore
          allUsers={_.differenceBy(members, entity.users, 'accountUuid')}
          organisationId={entity.organisationId}
        />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const editUsers = (entity: Entity) => {
    openMemberWindow(entity);
  };

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`Entities | ${process.env.PROJECT_NAME}`}</title>
            <meta name="description" content="This is a page for Entities" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            ></meta>
          </Helmet>
          <EntityComponent
            loading={loading}
            mappedOrganisations={mappedOrganisations}
            countries={countries}
            addReturnMapping={addReturnMapping}
            editReturnMapping={editReturnMapping}
            addEntity={addEntity}
            editUsers={editUsers}
            currentUser={currentUser}
            handlePreferences={handlePreferences}
          />
        </>
      )}
    </React.Fragment>
  );
};
