export enum ExceptionsActionType {
  UNRESOLVED = 'Unresolved',
  CURRENT_RETURN = 'Included',
  ADJUSTMENT = 'Adjusted',
  DEFER = 'Deferred',
  DEFERRED_PREVIOUSLY = 'Unresolved (Previously Deferred)',
  OUT_OF_SYSTEM_ADJUSTED = 'OUT_OF_SYSTEM_ADJUSTED',
}

export type ExceptionsWrapper = {
  exceptionUuid: string;
  exceptionActionType: string;
  adjustmentPeriodId: string | null;
  adjustDate: string | null;
};
