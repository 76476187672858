import {
  Col,
  Form,
  Row,
  Space,
  Tooltip,
  Transfer,
  TransferProps,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Section, TaxRate } from 'store/returns/models/returnData';
import { MappingData } from 'store/returns/models';
import { SelectedRate, StepOneData, TaxRateOption } from 'pages/returns';
import { ReactComponent as IconQUestionMark } from 'assets/images/icons/iconQuestionMark.svg';
import { ReactComponent as IconImport } from 'assets/images/icons/iconImport.svg';
import { ReactComponent as IconSupport } from 'assets/images/icons/iconSupport.svg';
import { FormSelect } from 'components/Custom/selects';
import styled from 'styled-components';
import { ExportButton, ScopeButton } from 'components/Custom/buttons';
import { StyledTooltip } from 'components/Custom/Tooltip';
import { TaxRateType, WindowType } from 'store/app/enums';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  PreferenceSections,
  PreferenceActions,
  Preference,
} from 'utils/preferences';
import { MappingGuide } from './mapping-guide';
import { useDispatch } from 'react-redux';
import { turnModalOn } from 'store/app/actions';
import { ModalData } from 'store/app/types';

interface RecordType {
  key: string;
  title: string;
  description: string;
}

const MapTaxRatesWrapper = styled.div`
  margin-bottom: 50px;
  .box-column {
    width: 100px;
  }
  .box-column-space {
    width: 5px;
  }

  .mapping-label {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #879494;
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
  }

  .mapping-label span:hover {
    color: #41aaaf;
  }

  .mapping-section {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;
  }

  .system-generated {
    height: 45px;
    border-radius: 2px;
    background: #e9f6f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #879494;
    font-size: 14px;
    font-weight: 400;
    padding: 0 25px;
    width: 100%;
  }

  .mapping-box,
  .mapping-box-oos {
    min-width: 80px;
    height: 45px;
    border-radius: 2px;
    background: #fff5e2;
    border: 1px solid #dbeae3;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .mapping-box-oos {
    background: #ffe9e2;
  }
  .oos-link {
    font-size: 14px;
    font-weight: 800;
    color: #058181;
  }

  .ant-transfer-list-content {
    background: #f3f7f8;
  }
  .ant-transfer-list-header {
    background: #41aaaf1a;
  }
  .ant-transfer-list {
    border: 1px solid #dbeae3;
  }
  .ant-transfer-list-body {
    background: #f3f7f8;
  }
  .ant-transfer-list-header-title {
    font-size: 18px;
    font-weight: 600;
  }
  .ant-empty-image {
    display: none;
  }

  .mapping-details-box {
    border-radius: 10px;
    border: 1px solid #dbeae3;
    background: #f3f7f8;
    padding: 20px;
    margin-bottom: 40px;
  }

  .entity-title-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .entity-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .edit-details-link {
    font-size: 18px;
    font-weight: 400;
    color: #41aaaf;
    cursor: pointer;
  }

  .mapping-details-wrapper {
    display: flex;
    gap: 40px;
    //justify-content: space-between;
    //max-width: 60%;
  }

  .mapping-details {
    display: flex;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    align-items: center;
    gap: 10px;
  }

  .details-separator {
    border: 1px solid #41aaaf;
    height: 90%;
    background: #41aaaf;
  }
`;

export const MAPPING_TUTORIAL_HIDE = 'mapping-tutorial-hide';

interface MapTaxRatesProps {
  sections?: Section[];
  taxRateOptions?: TaxRateOption[];
  outOfScopeOptions?: TaxRateOption[];
  outOfScopeCode?: string;
  handleTaxRangeChange: (
    rateIds: string[],
    outOfScope: boolean,
    code: string
  ) => void;
  form: any;
  mappingData?: MappingData;
  taxRates?: TaxRate[];
  updateSelectedRates: (mappedData: SelectedRate[]) => void;
  setOptions: (trOptions: TaxRateOption[], oosOptions: TaxRateOption[]) => void;
  prepareTaxRateOptions: () => TaxRateOption[];
  addUnmapped: () => void;
  useCashReverse: boolean;
  selectedRates?: SelectedRate[];
  updateTaxRateType: (type: TaxRateType, rateId: string | any[]) => void;
  //disabled?: boolean;
  stepOneData: StepOneData;
  useCashAccounting: boolean;
  editInitialData: () => void;
  hasTemplates: boolean;
  openTemplateWindow: () => void;
  useReverseCharge: boolean;
  handlePreferences: (
    preferenceSection: PreferenceSections,
    action: PreferenceActions,
    preference: Preference
  ) => any;
}
const { Text } = Typography;

export const SYSTEM_GENERATED = 'SYSTEM_GENERATED';
export const MapTaxRates: React.FC<MapTaxRatesProps> = ({
  sections,
  taxRateOptions,
  outOfScopeOptions,
  outOfScopeCode,
  handleTaxRangeChange,
  form,
  mappingData,
  taxRates,
  updateSelectedRates,
  setOptions,
  prepareTaxRateOptions,
  addUnmapped,
  useCashReverse,
  selectedRates,
  updateTaxRateType,
  //disabled,
  stepOneData,
  useCashAccounting,
  editInitialData,
  hasTemplates,
  openTemplateWindow,
  useReverseCharge,
  handlePreferences,
}) => {
  const dispatch = useDispatch();

  const mappingPanelHide = handlePreferences(
    PreferenceSections.MAPPING,
    PreferenceActions.GET,
    {
      name: MAPPING_TUTORIAL_HIDE,
    }
  );

  const [showCard, setShowCard] = React.useState(!mappingPanelHide);

  useEffect(() => {
    let isMounted = true;

    if (mappingData?.templateMappingId) {
      const trOptions: TaxRateOption[] = prepareTaxRateOptions();
      const oosOptions: TaxRateOption[] = prepareTaxRateOptions();

      const md: SelectedRate[] = [];
      const oosCode = sections?.find((s) => s.subsections[0].outOfScope)
        ?.subsections[0].code;
      const caCode = sections?.find(
        (s) => s.name === s.subsections[0].code
      )?.name;
      const jsonMapping = mappingData?.jsonMapping;
      const newValues = {};

      jsonMapping?.map((jm) => {
        const taxRateId = jm.taxType;
        const value = taxRateId ? taxRateId : '';
        const idx = trOptions.findIndex((opt) => opt.value === value);
        if (idx > -1) {
          const codes = jm.codes?.split(',');
          const loc = codes ? codes : [];
          let cashAccounting = false;
          const reverseCharge: number | null =
            jm.reverseCharge !== null && parseFloat(jm.reverseCharge) > 0
              ? parseFloat(jm.reverseCharge)
              : null;
          const taxRateType = jm.taxRateType
            ? jm.taxRateType
            : TaxRateType.INPUT;
          codes?.map((c) => {
            let boxCode = c;
            if (c === oosCode) {
              boxCode = 'outOfScope';
            }
            if (c === caCode) {
              cashAccounting = true;
            } else {
              if (Array.isArray(newValues[boxCode]))
                newValues[boxCode].push(taxRateId);
              else {
                newValues[boxCode] = [taxRateId];
              }
            }
          });
          if (codes) {
            if (codes[0] === oosCode) {
              !!taxRateId &&
                trOptions.splice(
                  trOptions.findIndex((t) => t.value === taxRateId),
                  1
                );
            } else {
              oosOptions.splice(
                oosOptions.findIndex((o) => o.value === taxRateId),
                1
              );
            }
          }
          const location = loc.filter((l) => l !== caCode);
          !!value &&
            md.push({
              value,
              location,
              cashAccounting,
              reverseCharge,
              taxRateType,
            });
        }
      });

      try {
        if (isMounted) {
          selectedRates?.forEach((sr) => {
            const idx = md.findIndex((m) => m.value === sr.value);
            if (idx === -1) md.push(sr);
          });
          if (jsonMapping && jsonMapping.length > 0) updateSelectedRates(md);
          setOptions(trOptions, oosOptions);
          form.resetFields();
          form.setFieldsValue(newValues);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [mappingData]);

  const NOT_USED = 'NOT_USED';
  const initialTargetKeys = [];
  const [targetKeys, setTargetKeys] =
    useState<TransferProps['targetKeys']>(initialTargetKeys);
  const [selectedKeys, setSelectedKeys] = useState<TransferProps['targetKeys']>(
    []
  );
  const [taxRateTypeData, setTaxRateTypeData] =
    useState<TransferProps['dataSource']>();

  useEffect(() => {
    if (selectedRates) {
      const data_pre = Array.from({
        length: selectedRates?.length,
      }).map<RecordType>((_, i) => {
        const notUsed =
          selectedRates[i].location.length === 0 ||
          selectedRates[i].location[0] === outOfScopeCode;
        const title = taxRates?.find(
          (tr) => tr.taxType === selectedRates[i].value
        )?.name;
        return {
          key: selectedRates[i].value,
          title: notUsed ? NOT_USED : title ? title : '',
          description: title && !notUsed ? title : '',
        };
      });
      const data = data_pre.filter((d) => d.title !== NOT_USED);
      const targetKeys: TransferProps['targetKeys'] = data
        .filter((item) => {
          const nIdx = selectedRates.findIndex((sr) => sr.value === item.key);
          if (
            nIdx !== -1 &&
            selectedRates[nIdx].taxRateType === TaxRateType.OUTPUT
          )
            return item;
        })
        .map((item) => item.key);

      setTaxRateTypeData(data);
      setTargetKeys(targetKeys);
    }
  }, [selectedRates]);

  const onTaxRateTypeChange: TransferProps['onChange'] = (nextTargetKeys) => {
    updateTaxRateType(TaxRateType.OUTPUT, nextTargetKeys);
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange: TransferProps['onSelectChange'] = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const renderTaxRateTypeSelection = () => {
    return (
      <Transfer
        dataSource={taxRateTypeData}
        titles={['Input', 'Output']}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onTaxRateTypeChange}
        onSelectChange={onSelectChange}
        render={(item) => item.title}
        listStyle={{
          width: 400,
          height: 260,
        }}
        selectAllLabels={[
          ({ selectedCount, totalCount }) => (
            <span>
              {selectedCount === 0
                ? `${totalCount} tax rate${totalCount > 1 ? 's' : ''}`
                : `${selectedCount} of ${totalCount} tax rate${
                    totalCount > 1 ? 's' : ''
                  }`}
            </span>
          ),
          ({ selectedCount, totalCount }) => (
            <span>
              {selectedCount === 0
                ? `${totalCount} tax rate${totalCount > 1 ? 's' : ''}`
                : `${selectedCount} of ${totalCount} tax rate${
                    totalCount > 1 ? 's' : ''
                  }`}
            </span>
          ),
        ]}
      />
    );
  };
  const renderTopButtons = () => {
    return (
      <Space>
        <ScopeButton
          width="fit-content"
          height="25px"
          fontSize="12px"
          lineheight="12px"
          onClick={guideVideoWindow}
          type="default"
        >
          Take Interactive Tour
        </ScopeButton>
        <a
          href="https://generate.tax/book-a-session/"
          target="_blank"
          rel="noreferrer"
        >
          <ScopeButton
            width="fit-content"
            height="25px"
            fontSize="12px"
            lineheight="12px"
            type="dark-orange"
          >
            Book an Onboarding Session
          </ScopeButton>
        </a>
      </Space>
    );
  };

  const guideVideoWindow = () => {
    const modalData: ModalData = {
      type: WindowType.VIDEO,
      title: '',
      component: (
        <div
          style={{
            position: 'relative',
            paddingBottom: 'calc(47.46527777777778% + 41px)',
            height: 0,
            width: '100%',
          }}
        >
          <iframe
            src="https://demo.arcade.software/0vvpiO2YylrGctTGC7nI?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
            title="generate.TAX"
            frameBorder="0"
            loading="lazy"
            allowFullScreen
            allow="clipboard-write"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              colorScheme: 'light',
            }}
          />
        </div>
      ),
    };

    dispatch(turnModalOn(modalData));
  };
  return (
    <>
      <MapTaxRatesWrapper>
        {showCard ? (
          <MappingGuide
            setShowCard={setShowCard}
            handlePreferences={handlePreferences}
            renderTopButtons={renderTopButtons}
          />
        ) : (
          <Row
            justify={'space-between'}
            style={{ marginTop: -20, marginBottom: 20 }}
          >
            {renderTopButtons()}
            <ExportButton
              type="text"
              onClick={() => {
                setShowCard(true);
                handlePreferences(
                  PreferenceSections.MAPPING,
                  PreferenceActions.SET,
                  {
                    name: MAPPING_TUTORIAL_HIDE,
                    value: false,
                  }
                );
              }}
              icon={<IconSupport />} //@ts-ignore
              isGuide
            >
              Open Guide
            </ExportButton>
          </Row>
        )}
        <div className="mapping-details-box">
          <div className="entity-title-row">
            <Space>
              <span className="entity-title">{stepOneData.entity}</span>
              {!useCashAccounting && (
                <div
                  className="edit-details-link"
                  onClick={() => editInitialData()}
                >
                  <EditOutlined />
                </div>
              )}
            </Space>
            <Tooltip
              title={
                !hasTemplates
                  ? 'No entities available with the same tax return.'
                  : ''
              }
            >
              <ExportButton
                onClick={openTemplateWindow}
                disabled={
                  !hasTemplates || useCashAccounting || useReverseCharge
                }
                icon={<IconImport />}
              >
                <span>Load from another Entity</span>
              </ExportButton>
            </Tooltip>
          </div>
          <div className="mapping-details-wrapper">
            <div className="mapping-details">
              <div className="details-separator" />
              <span>
                <strong>Tax Return:</strong>
                <br />
                {stepOneData.country} - {stepOneData.typeName}
              </span>
            </div>

            <div className="mapping-details">
              <div className="details-separator" />
              <span>
                <strong>Period:</strong>
                <br />
                {stepOneData.firstPeriod}
              </span>
            </div>

            <div className="mapping-details">
              <div className="details-separator" />
              <span>
                <strong>Due Date:</strong>
                <br />
                {moment(stepOneData.firstDueDate).format('DD/MM/YYYY')}
              </span>
            </div>
          </div>
        </div>
        <Form name="basic" form={form} autoComplete="off">
          {sections?.map((s, idx) => (
            <div key={`section${idx}`} style={{ marginBottom: 20 }}>
              {s.name !== s.subsections[0].code && (
                <Row key={`row${idx}`}>
                  <Col key={`Col${idx}`} span={24} className="mapping-section">
                    <span>{s.name}</span>
                    {s.subsections.findIndex((ss) => ss.outOfScope) > -1 && (
                      <ScopeButton //@ts-ignore
                        width="250px"
                        height="30px"
                        type={'default'}
                        fontSize="12px"
                        lineheight="12px"
                        onClick={() => addUnmapped()}
                        disabled={useCashReverse}
                      >
                        Mark Remaining as Out of Scope
                      </ScopeButton>
                    )}
                  </Col>
                  <Col key={`Col2${idx}`} span={24}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        {s.subsections.map((ss) => (
                          <React.Fragment key={ss.id}>
                            <tr>
                              <td className="mapping-label">
                                {ss.name}
                                {ss.altHint && (
                                  <Tooltip title={ss.altHint}>
                                    <span>
                                      <IconQUestionMark />
                                    </span>
                                  </Tooltip>
                                )}
                              </td>
                              <td className="box-column-space" />
                              <td className="box-column" />
                            </tr>
                            <tr>
                              <td>
                                {ss.type === SYSTEM_GENERATED ? (
                                  <>
                                    <div className="system-generated">
                                      <span>Totals</span>
                                      <span style={{ color: '#042A2A' }}>
                                        <i>System Generated</i>
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <Form.Item
                                    name={
                                      ss.outOfScope ? 'outOfScope' : ss.code
                                    }
                                    style={{ marginBottom: 0 }}
                                  >
                                    <FormSelect //@ts-ignore
                                      width="100%"
                                      height="45px"
                                      mode="multiple"
                                      textalign={'start'}
                                      placeholder="Select"
                                      options={
                                        ss.outOfScope
                                          ? outOfScopeOptions
                                          : taxRateOptions
                                      }
                                      onChange={(e: any) =>
                                        handleTaxRangeChange(
                                          e,
                                          ss.outOfScope,
                                          ss.code
                                        )
                                      }
                                      key={ss.id}
                                      allowClear
                                      showSearch
                                      bordercolor="#DBEAE3"
                                      bgcolor="#F3F7F8"
                                      selected_bg_color="#FFFFFF"
                                      radius="2px"
                                      maxTagCount={
                                        ss.outOfScope ? undefined : 'responsive'
                                      }
                                      disabled={useCashReverse} //@ts-ignore
                                      filterOption={(input, option) =>
                                        option?.label
                                          ?.toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      maxTagPlaceholder={(omittedValues) => (
                                        <StyledTooltip //@ts-ignore
                                          overlayStyle={{
                                            pointerEvents: 'none',
                                          }}
                                          title={
                                            <>
                                              {omittedValues.map(
                                                ({ label }) => (
                                                  <>
                                                    <span>{label}</span>
                                                    <br />
                                                  </>
                                                )
                                              )}
                                            </>
                                          }
                                        >
                                          <span>+{omittedValues.length}</span>
                                        </StyledTooltip>
                                      )}
                                    />
                                  </Form.Item>
                                )}
                              </td>
                              <td className="box-column-space" />
                              <td className="box-column">
                                <div
                                  className={
                                    ss.outOfScope
                                      ? 'mapping-box-oos'
                                      : 'mapping-box'
                                  }
                                >
                                  <Text>{ss.code}</Text>
                                </div>
                              </td>
                            </tr>
                            <tr style={{ height: 25 }} />
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )}
            </div>
          ))}
          <>
            <Row>
              <Col
                span={24}
                className="mapping-section"
                style={{ flexDirection: 'column' }}
              >
                <span>Tax Rate Type</span>
                <div className="divider-10" />
                <span className="mapping-label">
                  Select the tax rates and use the arrows to move them between
                  'Input' and 'Output'. This is important for generate.TAX to
                  compute the data in the return.
                </span>
                <div className="divider-10" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    //alignItems: 'center',
                  }}
                >
                  {renderTaxRateTypeSelection()}
                </div>
              </Col>
            </Row>
          </>
        </Form>
      </MapTaxRatesWrapper>
    </>
  );
};
