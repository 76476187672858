import * as React from 'react';
import moment from 'moment';
import { Col, Form, Row, Select, Typography } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { useDispatch } from 'react-redux';
import { turnDrawerOff } from 'store/app/actions';
import { FormSelect } from 'components/Custom/selects';
import { LabelWrapper } from 'components/Custom/labels';
import { FormDatePicker } from 'components/Custom/datePicker';
import { useTranslation } from 'react-i18next';
import { DataJournal } from 'store/vat/models/vatReportData';
import { VatReturnLog } from 'store/vat/models';
import { DataLogStatus } from 'store/app/enums';
import { ExceptionsActionType } from 'store/vat/models/exceptionWrapper';

type FieldType = {
  adjustmentPeriod?: string;
  captureDate?: Date;
};

type OtherSubmissions = {
  uuid: string;
  period: string;
};

enum FieldNames {
  ADJUSTMENT_PERIOD = 'adjustmentPeriod',
  CAPTURE_DATE = 'captureDate',
}

interface AdjustmentFormProps {
  selectedRowKeys: DataJournal[];
  addExceptionAction: (
    exceptionActionType: string,
    selectedRowKeys: DataJournal[],
    setSelectedRowKey: (data: DataJournal[]) => void,
    selectedAdjustmentPeriod: string | null,
    selectedCaptureDate: any
  ) => void;
  selectedVatReturnLogs: VatReturnLog[];
  setSelectedRowKey: (data: DataJournal[]) => void;
}

//const { Text, Title } = Typography;
export const AdjustmentForm: React.FC<AdjustmentFormProps> = ({
  selectedRowKeys,
  addExceptionAction,
  selectedVatReturnLogs,
  setSelectedRowKey,
}) => {
  const [selectedAdjustmentPeriod, setSelectedAdjustmentPeriod] =
    React.useState<string | null>(null);
  const [selectedCaptureDate, setSelectedCaptureDate] = React.useState<any>();
  const [otherSubmissions, setOtherSubmissions] =
    React.useState<OtherSubmissions[]>();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    addExceptionAction(
      'ADJUSTMENT',
      selectedRowKeys,
      () => {},
      selectedAdjustmentPeriod,
      selectedCaptureDate
    );
    dispatch(turnDrawerOff());
    setSelectedRowKey([]);
  };

  const onAdjustmentPeriodChange = (period) => {
    setSelectedAdjustmentPeriod(period);
  };

  const onCaptureDateChange = (date: any) => {
    form.validateFields([[FieldNames.CAPTURE_DATE]]);

    if (date.isAfter(selectedCaptureDate)) {
      form.resetFields(['returnDueDate']);
    }
    setSelectedCaptureDate(date);
  };

  React.useEffect(() => {
    if (selectedVatReturnLogs) {
      const submittedLogs = selectedVatReturnLogs.filter(
        (vrl) => DataLogStatus[vrl.status] === DataLogStatus.SUBMITTED
      );
      submittedLogs.sort(function (a, b) {
        return (
          new Date(a.fromReportingDate).valueOf() -
          new Date(b.fromReportingDate).valueOf()
        );
      });
      const otherLogs: OtherSubmissions[] = [
        { uuid: 'OOS', period: 'Out of system' },
      ];
      submittedLogs.forEach((sl) => {
        const fromDate = moment(sl.fromReportingDate).format('DD/MM/YYYY');
        const toDate = moment(sl.toReportingDate).format('DD/MM/YYYY');
        const period = `${fromDate} - ${toDate}`;
        const uuid = sl.uuid;
        otherLogs.push({ uuid, period });
      });
      setOtherSubmissions(otherLogs);
    }
  }, [selectedVatReturnLogs]);
  return (
    <>
      <Typography
        style={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '18px',
          textAlign: 'left',
          color: '#042A2A',
          marginBottom: '40px',
        }}
      >
        The selected exceptions will be adjusted in a prior period VAT return.
        Choose the submitted period from the provided list of submissions and
        enter the date for the adjustment submission.
        <br />
        <br /> After submitting this return, you can review the details of what
        needs to be submitted to the authorities by opening the submission
        requiring adjustment.
      </Typography>
      <Row justify={'center'}>
        <Col span={24}>
          <Form
            name="basic"
            form={form}
            autoComplete="off"
            onFinish={handleSubmit}
          >
            <LabelWrapper>
              <span className="label-text">
                {t('exceptions.adjustment-period')}
              </span>
            </LabelWrapper>
            <Form.Item<FieldType>
              name="adjustmentPeriod"
              rules={[
                {
                  message: t('common.required-field'),
                  validator: () => {
                    if (
                      !selectedAdjustmentPeriod &&
                      selectedAdjustmentPeriod !== null
                    ) {
                      return Promise.reject('Message problem');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <FormSelect
                placeholder={t('common.select')}
                onChange={onAdjustmentPeriodChange} //@ts-ignore
                width="100%"
                height="45px"
                bordercolor="#DBEAE3"
                bgcolor="#FFFFFF"
              >
                {otherSubmissions?.map((os) => (
                  <Select.Option key={os.uuid} value={os.uuid}>
                    <span
                      key={os.uuid}
                      //style={editMode ? { color: '#2E2E2E' } : {}}
                    >
                      {os.period}
                    </span>
                  </Select.Option>
                ))}
              </FormSelect>
            </Form.Item>

            <LabelWrapper>
              <span className="label-text">
                {t('exceptions.adjustment-date')}
              </span>
            </LabelWrapper>
            <Form.Item<FieldType>
              name="captureDate"
              rules={[
                {
                  message: t('common.required-field'),
                  validator: () => {
                    if (!selectedCaptureDate) {
                      return Promise.reject('Message problem');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <FormDatePicker
                style={{ width: '100%' }}
                placeholder={t('common.select')}
                onChange={onCaptureDateChange}
                format="DD/MM/YYYY" //@ts-ignore
                hasValue={!!selectedCaptureDate}
              />
            </Form.Item>

            <Form.Item>
              <ScopeButton
                type="primary"
                htmlType="submit" //@ts-ignore
                width="100%"
              >
                {t('common.save')}
              </ScopeButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
