import { Card, CardProps } from 'antd';
import styled from 'styled-components';

interface StyledCardProps extends CardProps {
  nopadding?: boolean;
  fullsize?: boolean;
  width?: string;
  checked?: boolean;
  stopshadowbox?: boolean;
  height?: string;
  centercontent?: boolean;
  hideheader?: boolean;
  spreadcontent?: boolean;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  //margin: 25px 15%;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  //height: ${(props) => (props.height ? props.height : '270px')};
  border: 1px solid #dbeae3;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.stopshadowbox ? 'none' : '0px 0px 10px 0px #0000000d'};

  .ant-switch-inner {
    background: #dbeae3 !important;
  }

  .ant-switch-handle::before {
    background: ${(props) => (props.checked ? '#41AAAF' : '#FFFFFF')}!important;
  }

  .switch-checked {
    .ant-switch-handle::before {
      background: #41aaaf !important;
    }
  }
  ${(props) =>
    props.fullsize &&
    `
      .ant-card-body {
        width:100%
      }
    `}
  ${(props) =>
    props.nopadding &&
    `
      .ant-card-body {
        padding:0
      }
    `}

    ${(props) =>
    props.centercontent &&
    `
      .ant-card-body {
        display: flex;
        justify-content: center;
      }
    `}
    
    ${(props) =>
    props.spreadcontent &&
    `
        .ant-card-body {
          display: flex;
          justify-content: space-between;
        }
      `}
    
    ${(props) =>
    props.hideheader &&
    `
        .ant-card-head {
          border-bottom: none;
          min-height: 20px !important;
        }
      `}
`;
