import { ViesStatus } from 'store/app/enums';
import { ExceptionsActionType } from './exceptionWrapper';

export interface VatReportData {
  summary: BoxSummary[];
  contacts: DataContact[];
  journals: DataJournal[];
  summarizedJournals: DataJournal[];
  exceptions: DataJournal[];
  currencySymbol: string;
  page: number;
  pageSize: number;
  totalPages: number;
  success: boolean;
  message: string;
  httpStatus: string;
  sections: TaxReportSectionDataResponse[];
  subsections: TaxReportSubsectionDataResponse[];
  adjustedSubsections: TaxReportSubsectionDataResponse[];
}

export interface BoxSummary {
  boxName: string;
  net: number;
  gross: number;
  tax: number;
  isGenerated: boolean;
}

export interface DataContact {
  name: string;
  country: string;
  taxNumber: string;
  viesStatus: ViesStatus;
  goodsSum: number;
  servicesSum: number;
  triangularTradeSum: number;
  isTriangularTrade: boolean;
}

export interface DataJournal {
  taxName: string;
  journalDate: string;
  sourceId: string;
  sourceType: string;
  contact: string;
  invoiceNumber: string;
  accountCode: string;
  accountType: string;
  accountName: string;
  reference: string;
  description: string;
  currency: string;
  netAmount: number;
  taxAmount: number;
  modifiedNetAmount: number;
  modifiedTaxAmount: number;
  grossAmount: number;
  netAmountConverted: number;
  taxAmountConverted: number;
  grossAmountConverted: number;
  journalNumber: number;
  journalCreatedDate: string;
  invoiceId: string;
  paymentStatus: string;
  selectedValue: string;
  sourceUrl: string;
  appliedAmount: number;
  uuid: string;
  taxType: string;
  taxRate: number;
  valueModifier: number;
  error: string;
  exceptionActionType?: string;
  key?: any;
  type: DataJournalType;
  isProcessed: boolean;
  deferredCount?: number;
}

export enum DataJournalType {
  EXCEPTION = 'EXCEPTION',
  TRANSACTION = 'TRANSACTION',
}

export interface TaxReportSectionDataResponse {
  name: string;
}

export interface TaxReportSubsectionDataResponse {
  name: string;
  code: string;
  net: number;
  tax: number;
  sortOrder: number;
  booleanExpression: boolean;
  cellName: string;
}

export interface ExcelFileResponse {
  file: string;
  fileName: string;
}
