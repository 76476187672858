export const trustedDomains = [
  process.env.PUBLIC_URL,
  'https://generate.tax',
  process.env.API_URL.replace('/api', ''),
  'https://authorize.xero.com/',
  'https://login.xero.com/',
  'https://api.generate.tax/',
  'https://accounts.google.com/',
  'https://login.microsoftonline.com/',
];
