import { Tooltip, Typography } from 'antd';
import { StyledCard } from 'components/Custom/card';
import React from 'react';
import { TaxRateType } from 'store/app/enums';
import { MappingTaxRate } from 'store/returns/models/mappingTaxRate';
import { MappedReturn, Subsection } from 'store/returns/models/returnData';
import styled from 'styled-components';

const MappingOverviewWrapper = styled.div`
  .text-generic {
    color: #000000;
    font-size: 13px;
    font-weight: 500;
  }
  .mapping-form-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    //gap: 20px;
  }
  .section-title {
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0%;
    color: #000000;
    width: 100%;
  }
  .subsection-manual-box {
    //max-width: 465px;
    height: 100%;
    border-radius: 5px;
    background: #f3f7f8;
    display: flex;
    flex-direction: column;
  }

  .subsection-title {
    min-height: 30px;
    border-bottom: 1px solid #dbeae3;
    padding: 10px;
    font-family: Inter;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0%;
    color: #879494;
    display: flex;
    align-items: center;
  }
  .mapping-box,
  .mapping-box-oos {
    min-width: 70px;
    height: 35px;
    border-radius: 2px;
    background: #fff5e2;
    border: 1px solid #dbeae3;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    align-self: center;
  }

  .mapping-box-oos {
    background: #ffe9e2;
  }
  .taxes-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px;
    height: 100%;
  }
  .tax-list-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .tax-rate-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-family: Inter;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #042a2a;
    margin-bottom: 3px;
  }

  .tax-rate-tags {
    padding: 0 5px 0px 3px;
    margin-right: 5px;
    min-width: 30px;
    text-align: center;
  }
  .system-generated {
    height: 45;
    background: #e9f6f7;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }
  .system-generated span {
    font-family: Inter;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0%;
    color: #879494;
  }
`;

const SYSTEM_GENERATED = 'SYSTEM_GENERATED';
interface MappingOverviewProps {
  //showSideMenu: boolean;
  mappedReturn?: MappedReturn;
  jsonMapping?: MappingTaxRate[];
}

const { Text } = Typography;
const CA = 'Cash Accounting';
const OUT_OF_SCOPE = 'OUT OF SCOPE';
export const MappingOverview: React.FC<MappingOverviewProps> = ({
  //showSideMenu,
  mappedReturn,
  jsonMapping,
}) => {
  const sections = mappedReturn?.sections?.filter((s) => {
    return (
      s.returnTypeId === mappedReturn.returnTypeId //||
      // (hasCA && s.name === s.subsections[0].code)
    );
  });

  sections?.sort((a, b) => a.sortOrder - b.sortOrder);
  // const jsonMappingString = mappedReturn?.dataLog?.jsonMapping;
  // const jsonMapping: MappingTaxRate[] = jsonMappingString
  //   ? JSON.parse(jsonMappingString).mapping
  //   : null;
  let cnt = 0;
  const setCount = (reset = false) => {
    if (reset) cnt = 0;
    else
      switch (cnt) {
        case 0:
          cnt = 1;
          break;
        case 1:
          cnt = 2;
          break;
        case 2:
          cnt = 1;
          break;
      }
  };

  const renderSubsections = (
    subsections: Subsection[],
    sectionName: string
  ) => {
    setCount(true);
    subsections?.sort((a, b) => a.sortOrder - b.sortOrder);

    const renderMargin = () => {
      if (cnt === 1) return { marginRight: 10 };
      if (cnt === 2) return { marginLeft: 10 };
    };

    const emptyCell = (boxName: string) => {
      const idx = jsonMapping?.findIndex((m) =>
        foundCode(m.codes || '', boxName)
      );
      if (idx === -1) return true;
      else return false;
    };

    const foundCode = (codeList: string, code2Find: string) => {
      const codesSplit = codeList?.split(',');
      const idx = codesSplit?.findIndex((c) => c === code2Find);
      if (idx === -1) return false;
      else return true;
    };

    return subsections?.map(
      (ss) =>
        ss.type !== SYSTEM_GENERATED && (
          <>
            {setCount()}
            <div
              style={{
                width:
                  sectionName.toUpperCase() !== OUT_OF_SCOPE ? '50%' : '75%',
                marginTop: 20,
              }}
            >
              <div className="subsection-manual-box" style={renderMargin()}>
                <span className="subsection-title">
                  {ss.name.trim() ? ss.name : ss.code}
                </span>

                <div className="taxes-wrapper">
                  <div className="tax-list-wrapper">
                    {jsonMapping?.map(
                      (m) =>
                        foundCode(m.codes || '', ss.code) && (
                          <>
                            <div className="tax-rate-line">
                              <Typography.Text
                                style={{
                                  maxWidth:
                                    sectionName.toUpperCase() !== OUT_OF_SCOPE
                                      ? 240
                                      : '100%',
                                  marginRight: 10,
                                }}
                                ellipsis={
                                  sectionName.toUpperCase() !== OUT_OF_SCOPE //@ts-ignore
                                    ? { rows: 1, tooltip: true }
                                    : false
                                }
                              >
                                {m.taxCodeName} [
                                {m.reverseCharge
                                  ? m.reverseCharge.replace(/[.,]00$/, '')
                                  : m.taxRate
                                      ?.toString()
                                      .replace(/[.,]00$/, '')}
                                %]
                              </Typography.Text>
                              <div style={{ display: 'flex' }}>
                                {m.codes?.includes(CA) && (
                                  <Tooltip title="Cash Accounting">
                                    <div
                                      className="tax-rate-tags"
                                      style={{
                                        background: '#C2F4DC',
                                      }}
                                    >
                                      Cash
                                    </div>
                                  </Tooltip>
                                )}
                                {m.reverseCharge && (
                                  <Tooltip title="Reverse Charge">
                                    <div
                                      className="tax-rate-tags"
                                      style={{
                                        background: '#E9D8FD',
                                      }}
                                    >
                                      RC
                                    </div>
                                  </Tooltip>
                                )}
                                {m.taxRateType === TaxRateType.INPUT &&
                                  sectionName?.toUpperCase() !==
                                    OUT_OF_SCOPE && (
                                    <Tooltip title="Input">
                                      <div
                                        className="tax-rate-tags"
                                        style={{
                                          background: '#B0F4F7',
                                        }}
                                      >
                                        In
                                      </div>
                                    </Tooltip>
                                  )}
                                {m.taxRateType === TaxRateType.OUTPUT && (
                                  <Tooltip title="Output">
                                    <div
                                      className="tax-rate-tags"
                                      style={{
                                        background: '#B0DBF7',
                                      }}
                                    >
                                      Out
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </>
                        )
                    )}
                    {emptyCell(ss.code) && (
                      <span
                        className="subsection-title"
                        style={{ border: 'none' }}
                      >
                        No tax rates have been mapped!
                      </span>
                    )}
                  </div>
                  <div className="mapping-box">{ss.code}</div>
                </div>
              </div>
            </div>
            {/* )} */}
            {/* </div> */}
          </>
        )
    );
  };

  return (
    <MappingOverviewWrapper>
      {' '}
      <StyledCard //@ts-ignore
        style={{
          //width: showSideMenu ? 'calc(100vw - 550px)' : '100%',
          //minWidth: 900,
          maxWidth: 1000,
          margin: 'auto',
        }}
      >
        <Text className="text-generic">
          This is the mapping used by generate.TAX to prepare this submission.
          If you need to edit the mapping, you must first cancel the submission.
          You may then edit the Tax Return page and correct any incorrect
          mappings.
        </Text>

        <div className="mapping-form-wrapper">
          {sections?.map(
            (s) =>
              s.subsections.findIndex((ss) => ss.type !== SYSTEM_GENERATED) >
                -1 && (
                <>
                  <div className="divider-20" />
                  <div className="divider-20" />
                  <div className="section-title">{s.name}</div>
                  {renderSubsections(s.subsections, s.name)}
                </>
              )
          )}
        </div>
      </StyledCard>
    </MappingOverviewWrapper>
  );
};
