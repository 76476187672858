import React from 'react';
import { Col, Divider, Row, Space } from 'antd';
import {
  PreferenceSections,
  PreferenceActions,
  Preference,
} from 'utils/preferences';
import { StyledCard } from 'components/Custom/card';
import { ReactComponent as IconClose } from 'assets/images/icons/iconClose.svg';
import { MAPPING_TUTORIAL_HIDE } from './map-tax-rates';
import { CardTitleLargeSpan, CardListSpan } from 'components/Custom/spans';
import MappingStepOne from 'assets/images/mapping-step-1.svg';
import MappingStepTwo from 'assets/images/mapping-step-2.svg';
import MappingStepThree from 'assets/images/mapping-step-3.svg';
import MappingStepFour from 'assets/images/mapping-step-4.svg';

interface MappingGuideProps {
  setShowCard: (show: boolean) => void;
  handlePreferences: (
    preferenceSection: PreferenceSections,
    action: PreferenceActions,
    preference: Preference
  ) => any;
  renderTopButtons: () => React.ReactNode;
}

const msgBoxWrapper: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minWidth: 300,
  maxWidth: 300,
  minHeight: 290,
};

export const MappingGuide: React.FC<MappingGuideProps> = ({
  setShowCard,
  handlePreferences,
  renderTopButtons,
}) => {
  return (
    <StyledCard
      width="100%"
      spreadcontent
      fullsize
      hideheader
      style={{
        marginBottom: '20px',
        width: '100%',
      }}
      title={
        <div style={{ top: 20, position: 'absolute', left: 25 }}>
          {renderTopButtons()}
        </div>
      }
      extra={
        <IconClose
          style={{
            top: '25',
            position: 'absolute',
            right: '25',
            cursor: 'pointer',
            filter:
              'invert(71%) sepia(12%) saturate(247%) hue-rotate(131deg) brightness(80%) contrast(81%)',
          }}
          onClick={() => {
            setShowCard(false);
            handlePreferences(
              PreferenceSections.MAPPING,
              PreferenceActions.SET,
              {
                name: MAPPING_TUTORIAL_HIDE,
                value: true,
              }
            );
          }}
        />
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
          flexWrap: 'wrap',
          //gap: 50,
        }}
      >
        <Divider />
        <div style={msgBoxWrapper}>
          <div>
            <CardTitleLargeSpan>Map Tax Rates </CardTitleLargeSpan>
            <CardListSpan>
              <ul style={{ paddingInlineStart: 20, maxWidth: 350 }}>
                <li>
                  Click each box in the return and select the tax rates to be
                  mapped.
                </li>
                <li>Mark unused tax rates in the Out of Scope section.</li>
                <li>Set each tax rate as Input or Output.</li>
              </ul>
            </CardListSpan>
          </div>
          <div style={{ marginBottom: 10 }}>
            <img alt="mappin-step-1" src={MappingStepOne} />
          </div>
        </div>
        <div style={msgBoxWrapper}>
          <div>
            <CardTitleLargeSpan>Reverse Charge</CardTitleLargeSpan>
            <CardListSpan>
              <ul style={{ paddingInlineStart: 20, maxWidth: 350 }}>
                <li>
                  In the Reverse Charge box, mark the 0% tax rates where reverse
                  charge applies.
                </li>
                <li>
                  Enter the tax rate used by generate.TAX and map it in the tax
                  return.
                </li>
              </ul>
            </CardListSpan>
          </div>
          <div>
            <img alt="mapping-step-2" src={MappingStepTwo} />
          </div>
        </div>

        <div style={msgBoxWrapper}>
          <div>
            <CardTitleLargeSpan>Cash Accounting</CardTitleLargeSpan>
            <CardListSpan>
              <ul style={{ paddingInlineStart: 20, maxWidth: 350 }}>
                <li>
                  In the Cash Accounting box, mark the tax rates where cash
                  applies.
                </li>
                <li>Map these in the tax return accordingly.</li>
              </ul>
            </CardListSpan>
          </div>
          <div>
            <img alt="mapping-step-3" src={MappingStepThree} />
          </div>
        </div>
        <div style={msgBoxWrapper}>
          <div>
            <CardTitleLargeSpan>Review</CardTitleLargeSpan>
            <CardListSpan>
              <ul style={{ paddingInlineStart: 20, maxWidth: 350 }}>
                <li>In the Tax Rate Mapping section, review your mappings</li>
                <li>
                  For each tax rate, check boxes, what to ignore, cash
                  accounting, reverse charge
                </li>
                <li>
                  Toggle between Input and Output Tax rate type using the arrows
                </li>
              </ul>
            </CardListSpan>
          </div>
          <div>
            <img alt="mapping-step-4" src={MappingStepFour} />
          </div>
        </div>
      </div>
    </StyledCard>
  );
};
