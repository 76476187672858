import { call, put, select } from 'redux-saga/effects';
import { fetchAddUrlFailure, fetchAddUrlSuccess } from '../actions';
import { FetchAddUrlResponse, getAddOrganisationUrl } from '../api';
import { FetchAddUrlRequestAction } from '../types';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { navigateToExternalPage } from 'utils/validation/common';
import { checkResponse } from 'store/utils';
import { getAuthUser } from 'store/auth/selectors';
import { logoutRequest } from 'store/auth/actions';
import { toTitleCase } from 'utils/to-title-case';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchAddUrlSaga(action: FetchAddUrlRequestAction) {
  try {
    let err: ApiError | null;

    const { providerName, uuid, sourceUrl } = action.payload;
    const response: FetchAddUrlResponse = yield call(
      getAddOrganisationUrl,
      providerName,
      uuid,
      sourceUrl
    );
    const orgUrl: string = response.data.url;

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: `Contacting ${toTitleCase(providerName)}`,
      description: 'Please be patient, it might take few seconds',
      type: MessageStates.INFO,
    };
    yield put(turnMessageOn(message));
    yield call(navigateToExternalPage, orgUrl);
    yield put(fetchAddUrlSuccess());
    const currentUser = yield select(getAuthUser);
    if (currentUser) {
      localStorage.removeItem(`vies-infos-${currentUser.userUuid}`);
    }
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchAddUrlFailure());
  }
}
