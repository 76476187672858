import { call, delay, put, select } from 'redux-saga/effects';
import { fetchRunDataFailure, fetchRunDataSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { FetchRunDataRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import {
  CheckEntityStatusResponse,
  GetRunDataResponse,
  checkEntityStatus,
  getRunData,
} from '../api';
import { turnMessageOn, updateMappingData } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { DataStatus, VatRunData } from '../models';
import { logoutRequest } from 'store/auth/actions';
import { push } from 'connected-react-router';
import routes from 'routes';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchRunDataSaga(action: FetchRunDataRequestAction) {
  try {
    let err: ApiError | null;
    const { vatRunRequestData } = action.payload;

    const statusCheckResponse: CheckEntityStatusResponse = yield call(
      checkEntityStatus,
      vatRunRequestData.connectionId
    );

    //if (statusCheckResponse.data.status === DataStatus.IN_PROGRESS) {
    if (statusCheckResponse.data.status !== DataStatus.IDLE) {
      const message: MessageData = {
        title: 'Server busy',
        description:
          'We are currently updating the data from the provider. Please try again later',
        type: MessageStates.WARNING,
      };
      yield put(turnMessageOn(message));
      yield put(fetchRunDataFailure());
      //} else if (statusCheckResponse.data.status === DataStatus.IDLE) {
    } else {
      const response: GetRunDataResponse = yield call(
        getRunData,
        vatRunRequestData
      );

      err = checkResponse(response?.responseMessage);
      if (err) throw err;

      const vatRunData: VatRunData = response.data;
      yield put(updateMappingData(undefined));
      yield put(fetchRunDataSuccess(vatRunData));
      yield put(push(routes.vat.report));
    }
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchRunDataFailure());
  }
}
