import * as React from 'react';
import { Col, Row } from 'antd';
import { DataLogStatus } from 'store/app/enums';
import { VatRunData } from 'store/vat/models';
import styled from 'styled-components';
import routes from 'routes';
import { ScopeButton } from 'components/Custom/buttons';

const ErrorWrapper = styled.div`
  margin-top: 10px;

  .error-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #d13131;
  }

  .report-link {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
    color: #d13131;
  }
`;

export const renderErrorMessage = (data: VatRunData) => {
  return (
    <ErrorWrapper>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          {!data.isPeriodClosed && !data.dataLogId && (
            <>
              <span className="error-text">
                <strong>Caution:</strong> You cannot save this report because
                there is another open return for this period. If you wish to
                initiate a new return, you must first cancel the open return and
                then re-generate the report.
              </span>
            </>
          )}
          {data.status !== DataLogStatus.CANCELLED &&
            data.status !== DataLogStatus.SUBMITTED &&
            data.isFirstTime === false &&
            data.validRange !== null &&
            data.validRange === false && (
              <>
                <hr />
                <span className="error-text">
                  <strong>Caution:</strong> This report cannot be saved, updated
                  or submitted because the date range in the mapping is not the
                  same as the daterange selected on this report.
                </span>
              </>
            )}
          {data.isFirstTime === true &&
            data.validRange !== null &&
            data.validRange === false &&
            (!data.status || data.status === DataLogStatus.NOT_SAVED) && (
              <>
                <hr />
                <span className="error-text">
                  <strong>Caution:</strong> The chosen reporting period differs
                  from your mapping. In certain countries, the initial submitted
                  return may require a different period. Therefore, please
                  verify that the selected period is accurate for this return.
                </span>
              </>
            )}
        </Col>
        {!data.isPeriodClosed && !data.dataLogId && !!data.openDataLogId && (
          <Col span={24}>
            <ScopeButton
              onClick={() => {
                const url = routes.vat.viewReportLink
                  .replace(':connectionId', data.tokenId)
                  .replace(':mappingId', data.mappingId)
                  .replace(':dataLogId', data.openDataLogId);
                document.location = url;
              }}
              type="default"
            >
              View the open return
            </ScopeButton>
          </Col>
        )}
      </Row>
    </ErrorWrapper>
  );
};
