import { call, put, select } from 'redux-saga/effects';
import {
  deleteUserFailure,
  deleteUserSuccess,
  fetchUsersRequest,
} from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { deleteUser } from '../api';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { DeleteUserRequestAction } from '../types';
import { MessageStatus, TablePermission } from 'store/api/enums';
import { getOrgOrganisations } from 'store/organisation/selectors';
import { updateEntityMembers } from 'store/organisation/actions';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* deleteUserSaga(action: DeleteUserRequestAction) {
  try {
    let err: ApiError | null;
    const { userToDeleteId } = action.payload;
    const response: ApiResponse = yield call(deleteUser, userToDeleteId);

    err = checkResponse(response?.responseMessage);
    if (err && response.responseMessage.status === MessageStatus.UNAUTHORIZED) {
      yield put(fetchUsersRequest());
    }
    if (err) throw err;
    yield put(deleteUserSuccess(userToDeleteId));
    const organisations = yield select(getOrgOrganisations);
    for (let i = 0; i < organisations.length; i++) {
      const userIndex = organisations[i].users.findIndex(
        (u) => u.userUuid === userToDeleteId
      );
      if (userIndex > -1) {
        yield put(
          updateEntityMembers(
            organisations[i].uuid,
            organisations[i].users[userIndex],
            TablePermission.NONE
          )
        );
      }
    }
    //yield put(fetchUsersRequest());
    const message: MessageData = {
      title: 'Success',
      description: 'User successfully deleted',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(deleteUserFailure());
  }
}
