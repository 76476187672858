import moment from 'moment';
import {
  Divider,
  Dropdown,
  MenuProps,
  Popover,
  Row,
  Space,
  TableProps,
  Tooltip,
} from 'antd';
import * as React from 'react';
import { DataJournal } from 'store/vat/models/vatReportData';
import { StyledCard } from 'components/Custom/card';
import { ScopeTable } from 'components/Custom/table';
import {
  ContextButton,
  ExportButton,
  IconContextButton,
} from 'components/Custom/buttons';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { ReactComponent as IconExport } from 'assets/images/icons/iconExport.svg';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import { ReactComponent as IconSupport } from 'assets/images/icons/iconSupport.svg';
import ExceptionStepOne from 'assets/images/exception-step-1.svg';
import ExceptionStepTwo from 'assets/images/exception-step-2.svg';
import { RoundInput } from 'components/Custom/inputs';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import {
  CardDescSpan,
  CardTitleLargeSpan,
  ColoredTag,
} from 'components/Custom/spans';
import { ReactComponent as IconClose } from 'assets/images/icons/iconClose.svg';
import { ExceptionsActionType } from 'store/vat/models/exceptionWrapper';
import styled from 'styled-components';
import {
  Preference,
  PreferenceActions,
  PreferenceSections,
} from 'utils/preferences';
import { PAGE_SIZE_EXCEPTIONS } from 'pages/vat/report';

const ExceptionsWrapper = styled.div`
  .ant-dropdown-trigger {
    color: #ffffff;
    background-color: #41aaaf;
    width: 124px;
    height: 30px;
    border-radius: 50px;
    display: block;
    line-height: 2.25;
    font-weight: 400;
  }

  .ant-dropdown-trigger:hover {
    color: #042a2a;
    background-color: #ffbc3e;
    font-weight: 400;
  }

  .ant-dropdown-trigger[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    border: 1px solid #d9d9d9;
  }
`;
interface RenderExceptionsProps {
  transData?: DataJournal[];
  dataToExport?: DataJournal[];
  filteredBox?: string | null;
  openAdjustmentPanal: (
    selectedRowKeys: DataJournal[],
    setSelectedRowKey: (data: DataJournal[]) => void
  ) => void;
  addExceptionAction: (
    exceptionActionType: string,
    selectedRowKeys: DataJournal[],
    setSelectedRowKey: (data: DataJournal[]) => void,
    selectedAdjustmentPeriod?: string | null,
    selectedCaptureDate?: any
  ) => void;
  handleExceptions: boolean;
  handlePreferences: (
    preferenceSection: PreferenceSections,
    action: PreferenceActions,
    preference: Preference
  ) => any;
  providerName: string;
  showSideMenu: boolean;
}

const items: MenuProps['items'] = [
  {
    label: 'Include in this submission',
    key: '0',
  },
  {
    label: 'Adjustment to a prior submission',
    key: '1',
  },
  {
    label: 'Defer to next submission',
    key: '2',
  },
];

const EXCEPTIONS_TUTORIAL_HIDE = 'exceptions-tutorial-hide';
export const RenderExceptions: React.FC<RenderExceptionsProps> = ({
  transData,
  dataToExport,
  filteredBox,
  openAdjustmentPanal,
  addExceptionAction,
  handleExceptions,
  handlePreferences,
  providerName,
  showSideMenu,
}) => {
  const exceptionsPanelHide = handlePreferences(
    PreferenceSections.EXCEPTIONS,
    PreferenceActions.GET,
    {
      name: EXCEPTIONS_TUTORIAL_HIDE,
    }
  );
  const pageSizeSaved = handlePreferences(
    PreferenceSections.EXCEPTIONS,
    PreferenceActions.GET,
    {
      name: PAGE_SIZE_EXCEPTIONS,
    }
  );

  const [source, setSource] = React.useState(transData);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<DataJournal[]>(
    []
  );

  const [showCard, setShowCard] = React.useState(!exceptionsPanelHide);
  const { t } = useTranslation();

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };

  React.useEffect(() => {
    setSource(transData);
  }, [transData]);

  React.useEffect(() => {
    console.log('showCard', showCard, 'handleExceptions', handleExceptions);
  }, [showCard]);

  const renderContextMenu = (record: DataJournal) => {
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <IconContextButton
            type="text"
            onClick={() =>
              addExceptionAction('CURRENT_RETURN', [record], setSelectedRowKeys)
            }
          >
            {t('exceptions.current-return')}
          </IconContextButton>
          <IconContextButton
            type="text"
            onClick={() => openAdjustmentPanal([record], setSelectedRowKeys)}
          >
            {t('exceptions.adjustment-return')}
          </IconContextButton>
          <IconContextButton
            type="text"
            onClick={() =>
              addExceptionAction('DEFER', [record], setSelectedRowKeys)
            }
          >
            {t('exceptions.defer')}
          </IconContextButton>
        </Space>
      </div>
    );
  };

  const renderActionTag = (_, record: DataJournal) => {
    switch (
      record?.exceptionActionType !== undefined
        ? ExceptionsActionType[record.exceptionActionType]
        : undefined
    ) {
      case ExceptionsActionType.UNRESOLVED:
      case ExceptionsActionType.DEFERRED_PREVIOUSLY:
        const prevDefer =
          record.exceptionActionType !== undefined &&
          ExceptionsActionType[record.exceptionActionType] ===
            ExceptionsActionType.DEFERRED_PREVIOUSLY;
        return (
          <ColoredTag
            color="#D13131" //@ts-ignore
            bgcolor="#FFFFFF"
            bordered={true}
            bordercolor="#DBEAE3"
          >
            {prevDefer ? (
              <Tooltip
                title={`Previously Deferred${
                  record?.deferredCount
                    ? ' ' +
                      record?.deferredCount +
                      (record?.deferredCount === 1 ? ' time' : ' times')
                    : ''
                }`}
              >
                {ExceptionsActionType.UNRESOLVED}{' '}
                {prevDefer
                  ? `(${record?.deferredCount ? record?.deferredCount : '*'})`
                  : ''}
              </Tooltip>
            ) : (
              <>{ExceptionsActionType.UNRESOLVED}</>
            )}
          </ColoredTag>
        );
      case ExceptionsActionType.ADJUSTMENT:
        return (
          <ColoredTag
            color="#3794E5" //@ts-ignore
            bgcolor="#FFFFFF"
            bordered={true}
            bordercolor="#DBEAE3"
          >
            {ExceptionsActionType.ADJUSTMENT}
          </ColoredTag>
        );
      case ExceptionsActionType.CURRENT_RETURN:
        return (
          <ColoredTag
            color="#41AAAF" //@ts-ignore
            bgcolor="#FFFFFF"
            bordered={true}
            bordercolor="#DBEAE3"
          >
            {ExceptionsActionType.CURRENT_RETURN}
          </ColoredTag>
        );
      case ExceptionsActionType.DEFER:
        return (
          <ColoredTag
            color="#E99144" //@ts-ignore
            bgcolor="#FFFFFF"
            bordered={true}
            bordercolor="#DBEAE3"
          >
            {ExceptionsActionType.DEFER}
          </ColoredTag>
        );

      default:
        return <>{record.exceptionActionType}</>;
    }
  };

  const columns = [
    {
      fixed: 'left',
      render: (_, record: DataJournal) => (
        <a
          href={record.sourceUrl}
          style={{ cursor: 'pointer' }}
          target={'_blank'}
          rel="noreferrer"
        >
          <img
            src={
              providerName
                ? require(`shared/assets/images/${providerName.toLowerCase()}_small.png`)
                    .default
                : ''
            }
            style={{ marginTop: 7 }}
            width={25}
          />
        </a>
      ),
    },
    {
      title: 'Journal Date',
      dataIndex: 'journalDate',
      key: 'journalDate',
      //ellipsis: true,
      fixed: 'left',
      render: (_, record: DataJournal) =>
        moment(record.journalDate).format('DD/MM/YYYY'),
      sorter: (a, b) =>
        new Date(a.journalDate).valueOf() - new Date(b.journalDate).valueOf(),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Tax Name',
      dataIndex: 'taxName',
      key: 'taxName',
      sorter: (a, b) => a.taxName?.localeCompare(b.taxName),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Source Type',
      dataIndex: 'sourceType',
      key: 'sourceType',
      sorter: (a, b) => a.sourceType?.localeCompare(b.sourceType),
      sortIcon: renderSortIcon,
    },
    {
      title: 'A/C Code',
      dataIndex: 'accountCode',
      key: 'accountCode',
      sorter: (a, b) => a.accountCode?.localeCompare(b.accountCode),
      sortIcon: renderSortIcon,
    },
    {
      title: 'A/C Type',
      dataIndex: 'accountType',
      key: 'accountType',
      sorter: (a, b) => a.accountType?.localeCompare(b.accountType),
      sortIcon: renderSortIcon,
    },
    {
      title: 'A/C Name',
      dataIndex: 'accountName',
      key: 'accountName',
      ellipsis: true,
      sorter: (a, b) => a.accountName?.localeCompare(b.accountName),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmountConverted',
      key: 'netAmountConverted',
      render: (content: number) => (
        <div
          style={{
            textAlign: 'end',
            color: content < 0 ? '#d13131' : '',
          }}
        >
          {content.toFixed(2)}
        </div>
      ),
      sorter: (a, b) => (a.netAmountConverted > b.netAmountConverted ? -1 : 1),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmountConverted',
      key: 'taxAmountConverted',

      render: (content: number) => (
        <div style={{ textAlign: 'end', color: content < 0 ? '#d13131' : '' }}>
          {content.toFixed(2)}
        </div>
      ),
      sorter: (a, b) => (a.taxAmountConverted > b.taxAmountConverted ? -1 : 1),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Action',
      dataIndex: 'exceptionActionType',
      key: 'exceptionActionType',
      fixed: 'right',
      //width: handleExceptions ? 0 : 1,
      render: renderActionTag,
      sorter: (a, b) =>
        a.exceptionActionType?.localeCompare(b.exceptionActionType),
      sortIcon: renderSortIcon,
    },
    !handleExceptions
      ? { fixed: 'right' }
      : {
          fixed: 'right',
          render: (_, record: DataJournal) => {
            return (
              <Popover
                placement="topLeft"
                arrow={false}
                trigger="click"
                content={renderContextMenu(record)}
                title={''}
                overlayStyle={{ zIndex: 500 }}
                //open={popupOpen}
              >
                <ContextButton
                //onClick={() => setPopupOpen(true)} type="text"
                >
                  <EllipseIcon
                    style={{
                      height: '10px',
                    }}
                  />
                </ContextButton>
              </Popover>
            );
          },
        },
  ];

  const getHeaderForExport = (dataToExport) => {
    const obj = dataToExport?.length > 0 ? dataToExport[0] : null;
    let headerArr: any = [];
    if (obj) {
      const headers = Object.keys(obj);
      headerArr = headers.map((key) => ({
        label: key.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
        key: key,
      }));
    }

    return headerArr;
  };

  const search = (e) => {
    const value = e.target.value;
    if (value != '') {
      const filterTable = transData?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.toLowerCase())
        )
      );

      setSource(filterTable);
    } else {
      setSource(transData);
    }
  };

  const rowSelection: TableProps<DataJournal>['rowSelection'] = {
    selectedRowKeys: selectedRowKeys.map((sr) => sr.key),
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataJournal[]) => {
      setSelectedRowKeys(selectedRows);
    },
    getCheckboxProps: (record: DataJournal) => ({
      name: record.accountName,
    }),
  };

  const renderCardTitle = () => {
    return (
      <Space
        style={{
          display: 'flex',
          marginBottom: 20,
          justifyContent: 'space-between',
        }}
      >
        <Space>
          <ExportButton icon={<IconExport />}>
            <CSVLink
              filename={'Exceptions Export.csv'}
              data={dataToExport}
              headers={getHeaderForExport(dataToExport)}
              className="csv-link-wrapper"
            >
              <span>Export {'Exceptions'}</span>
            </CSVLink>
          </ExportButton>
        </Space>

        <RoundInput
          width={'200px'}
          height={'35px'}
          prefix={<SearchOutlined />}
          size="large"
          placeholder={'Search Exceptions'}
          onChange={search}
        />
      </Space>
    );
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case '0':
        addExceptionAction(
          'CURRENT_RETURN',
          selectedRowKeys,
          setSelectedRowKeys
        );
        break;
      case '1':
        openAdjustmentPanal(selectedRowKeys, setSelectedRowKeys);
        break;
      case '2':
        addExceptionAction('DEFER', selectedRowKeys, setSelectedRowKeys);
        break;
      default:
        break;
    }
  };

  const renderBulkButton = () => {
    return (
      <>
        <Dropdown
          menu={{ items, onClick }}
          trigger={['click']}
          disabled={selectedRowKeys.length == 0}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space align="baseline" style={{ marginLeft: '14px' }}>
              Bulk Action
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </>
    );
  };

  const calcAmount = (amountType) => {
    if (amountType == 'net') {
      const netAmountDataSource: DataJournal[] | undefined =
        selectedRowKeys.length > 0 ? selectedRowKeys : source;

      return netAmountDataSource?.reduce(
        (exception, { netAmountConverted }) => exception + netAmountConverted,
        0
      );
    } else if (amountType == 'tax') {
      const taxAmountDataSource: DataJournal[] | undefined =
        selectedRowKeys.length > 0 ? selectedRowKeys : source;

      return taxAmountDataSource?.reduce(
        (exception, { taxAmountConverted }) => exception + taxAmountConverted,
        0
      );
    }
  };

  const renderItemsSelected = () => {
    let itemString = '';
    if (selectedRowKeys.length === 0) {
      itemString = `${source ? source.length : '0'} items | `;
    } else {
      if (selectedRowKeys.length === 1) {
        itemString = `1 item selected | `;
      } else {
        itemString = `${selectedRowKeys.length} items selected | `;
      }
    }
    return itemString;
  };

  const renderHeaderComponent = () => {
    return (
      <Space direction="horizontal" style={{ float: 'right' }}>
        <CardDescSpan
          style={{
            float: 'right',
            fontSize: 11,
          }}
        >
          {renderItemsSelected()}
          Totals {selectedRowKeys.length > 0 && 'of selected'}: Net amount:{' '}
          <span
            style={{
              textAlign: 'end',
              fontWeight: 600,
            }}
          >
            {calcAmount('net')?.toFixed(2)}
          </span>
          {'     '}
          Tax amount:{' '}
          <span
            style={{
              textAlign: 'end',
              fontWeight: 600,
            }}
          >
            {calcAmount('tax')?.toFixed(2)}
          </span>
        </CardDescSpan>
      </Space>
    );
  };
  const savePageSize = (pageSize: number) => {
    const preferenceSection = PreferenceSections.EXCEPTIONS;
    handlePreferences(preferenceSection, PreferenceActions.SET, {
      name: PAGE_SIZE_EXCEPTIONS,
      value: pageSize,
    });
  };

  return (
    <>
      <ExceptionsWrapper>
        {handleExceptions && (
          <>
            {showCard ? (
              <StyledCard
                width="100%"
                centercontent
                hideheader
                style={{
                  marginBottom: '20px',
                }}
                extra={
                  <IconClose
                    style={{
                      top: '25',
                      position: 'absolute',
                      right: '25',
                      cursor: 'pointer',
                      filter:
                        'invert(71%) sepia(12%) saturate(247%) hue-rotate(131deg) brightness(80%) contrast(81%)',
                    }}
                    onClick={() => {
                      setShowCard(false);
                      handlePreferences(
                        PreferenceSections.EXCEPTIONS,
                        PreferenceActions.SET,
                        {
                          name: EXCEPTIONS_TUTORIAL_HIDE,
                          value: true,
                        }
                      );
                    }}
                  />
                }
              >
                <Space style={{ columnGap: 50 }}>
                  <Space
                    direction="vertical"
                    style={{ maxWidth: '270px', marginRight: '20px' }}
                  >
                    <CardTitleLargeSpan>Process Exceptions</CardTitleLargeSpan>
                    <CardDescSpan>
                      Transactions that have been flagged as exceptions need to
                      be reviewed and processed before this submission can be
                      marked as submitted.
                    </CardDescSpan>
                  </Space>
                  <Space direction="vertical">
                    <img
                      alt="exception-step-1"
                      style={{ width: '290px', height: '230px' }}
                      src={ExceptionStepOne}
                    />
                  </Space>
                  <Space direction="vertical">
                    <img
                      alt="exception-step-2"
                      style={{ width: '290px', height: '230px' }}
                      src={ExceptionStepTwo}
                    />
                  </Space>
                </Space>
              </StyledCard>
            ) : (
              <Row justify={'end'} style={{ marginTop: -20, marginBottom: 20 }}>
                <ExportButton
                  type="text"
                  onClick={() => {
                    setShowCard(true);
                    handlePreferences(
                      PreferenceSections.EXCEPTIONS,
                      PreferenceActions.SET,
                      {
                        name: EXCEPTIONS_TUTORIAL_HIDE,
                        value: false,
                      }
                    );
                  }}
                  icon={<IconSupport />} //@ts-ignore
                  isGuide
                >
                  Open Guide
                </ExportButton>
              </Row>
            )}
          </>
        )}
        <StyledCard //@ts-ignore
          style={{
            width: showSideMenu ? 'calc(100vw - 540px)' : '100%',
            minWidth: 900,
            margin: 'auto',
          }}
        >
          {transData && transData?.length > 0 && renderCardTitle()}
          <Divider />
          <div
            style={{
              padding: '10px',
              paddingLeft: '5px',
              paddingTop: '10px',
              //maxWidth: 1600,
              minWidth: 800,
              margin: 'auto',
            }}
          >
            <ScopeTable
              filters={[
                'taxName',
                'selectedValue',
                'journalDate',
                'sourceType',
                'accountCode',
                'accountType',
                'accountName',
                'exceptionActionType',
              ]} //@ts-ignore
              rowSelection={
                handleExceptions
                  ? { type: 'checkbox', ...rowSelection }
                  : undefined
              }
              mainLocaleKey="exceptions"
              tableDataSource={source}
              originalDataSource={transData}
              columns={columns}
              pagination={true}
              rowsPerPage={pageSizeSaved}
              enableColumnsCustomization={false}
              renderRightComponent={
                handleExceptions ? renderBulkButton() : undefined
              }
              rowHeight={40} //@ts-ignore
              selectedFilter={{ key: 'selectedValue', value: filteredBox }}
              titleComponent={
                handleExceptions ? renderHeaderComponent() : undefined
              }
              showSizeChanger
              getNewPageSize={savePageSize}
              scroll={{ x: 'max-content' }}
              fontSize={12}
            />
          </div>
        </StyledCard>
      </ExceptionsWrapper>
    </>
  );
};
