import { AccountType } from 'store/api/enums';
import { DataLogActions, DataLogStatus } from 'store/app/enums';
import { User } from 'store/auth/models';
import { VatRunData } from 'store/vat/models';

export const checkDisabled = (
  action: DataLogActions,
  vatRunData: VatRunData,
  currentUser: User
) => {
  const currentStatus = !vatRunData.status ? 'NOT_SAVED' : vatRunData.status;
  switch (action) {
    case DataLogActions.SAVE_VAT_RETURN:
      if (
        (!vatRunData?.status &&
          vatRunData?.isPeriodClosed &&
          (vatRunData.validRange || vatRunData.isFirstTime)) ||
        DataLogStatus[currentStatus] === DataLogStatus.NOT_SAVED
      )
        return false;
      break;
    case DataLogActions.LOCK_AS_APPROVED:
      if (
        DataLogStatus[currentStatus] === DataLogStatus.LOCKED ||
        DataLogStatus[currentStatus] === DataLogStatus.SUBMITTED ||
        DataLogStatus[currentStatus] === DataLogStatus.NOT_SAVED ||
        DataLogStatus[currentStatus] === DataLogStatus.EXCEPTIONS_MODIFIED
      )
        return true;
      else if (
        DataLogStatus[currentStatus] === DataLogStatus.SAVED ||
        vatRunData.validRange === true
      )
        return false;
      break;
    case DataLogActions.CANCEL_RETURN:
      if (DataLogStatus[currentStatus] !== DataLogStatus.SUBMITTED)
        return false;
      break;
    case DataLogActions.SET_AWAITING_APPROVAL:
      if (
        DataLogStatus[currentStatus] === DataLogStatus.SAVED &&
        (currentUser.accountUuid === vatRunData?.preparerId ||
          AccountType[currentUser.accountType] === AccountType.OWNER)
      )
        return false;
      break;
    case DataLogActions.SET_INTERNAL_APPROVED:
      if (
        DataLogStatus[currentStatus] === DataLogStatus.AWAITING_APPROVAL &&
        (currentUser.accountUuid === vatRunData?.preparerId ||
          currentUser.accountUuid === vatRunData?.reviewerId ||
          AccountType[currentUser.accountType] === AccountType.OWNER)
      )
        return false;
      break;
    case DataLogActions.SEND_FOR_CLIENT_APPROVAL:
      if (
        (DataLogStatus[currentStatus] === DataLogStatus.SAVED &&
          (currentUser.accountUuid === vatRunData?.reviewerId ||
            currentUser.accountUuid === vatRunData?.preparerId ||
            AccountType[currentUser.accountType] === AccountType.OWNER)) ||
        (DataLogStatus[currentStatus] === DataLogStatus.INTERNAL_APPROVED &&
          (currentUser.accountUuid === vatRunData.reviewerId ||
            currentUser.accountUuid === vatRunData.preparerId ||
            AccountType[currentUser.accountType] === AccountType.OWNER))
      )
        return false;
      break;
    case DataLogActions.MARK_AS_SUBMITTED:
      if (
        DataLogStatus[currentStatus] === DataLogStatus.LOCKED ||
        DataLogStatus[currentStatus] === DataLogStatus.SAVED ||
        DataLogStatus[currentStatus] === DataLogStatus.INTERNAL_APPROVED ||
        (DataLogStatus[currentStatus] === DataLogStatus.CLIENT_APPROVED &&
          vatRunData.validRange &&
          vatRunData.validRange === true)
      )
        return false;
      break;
    case DataLogActions.SET_NOT_APPROVED:
      const showNoApprove =
        vatRunData.status &&
        ((DataLogStatus[vatRunData.status] ===
          DataLogStatus.AWAITING_APPROVAL &&
          (currentUser.accountUuid === vatRunData.reviewerId ||
            currentUser.accountUuid === vatRunData.preparerId ||
            AccountType[currentUser.accountType] === AccountType.OWNER)) ||
          (DataLogStatus[vatRunData.status] ===
            DataLogStatus.AWAITING_CLIENT_APPROVAL &&
            (currentUser.accountUuid === vatRunData.clientId ||
              AccountType[currentUser.accountType] === AccountType.OWNER)));
      if (showNoApprove) return false;
  }
  return true;
};
