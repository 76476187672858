import { call, put } from 'redux-saga/effects';
import { ApiError, ApiResponse } from 'store/api/types';
import {
  HideContactRequestAction,
  RefreshContactsRequestAction,
} from '../types';
import { checkResponse } from 'store/utils';

import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { hideContactFailure, hideContactSuccess } from '../actions';
import { hideContact } from '../api';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* hideContactSaga(action: HideContactRequestAction) {
  try {
    let err: ApiError | null;
    const { hideContactData } = action.payload;

    const response: ApiResponse = yield call(hideContact, hideContactData);
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(hideContactSuccess(hideContactData));
    const message: MessageData = {
      title: 'Success',
      description: `Selected contacts are now ${
        hideContactData.isActive ? 'visible' : 'hidden'
      }.`,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(hideContactFailure());
  }
}
