import { call, put } from 'redux-saga/effects';
import { changePasswordFailure, changePasswordSuccess } from '../actions';
import { changePassword } from '../api';
import { ChangePasswordRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn, turnModalOff } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* changePasswordSaga(action: ChangePasswordRequestAction) {
  try {
    let err: ApiError | null;

    const { oldPassword, newPassword } = action.payload;
    const response: ApiResponse = yield call(
      changePassword,
      oldPassword,
      newPassword
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: `Updated !`,
      description: `Password changed successfully.`,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(changePasswordSuccess());
    yield put(turnModalOff());
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(changePasswordFailure());
  }
}
