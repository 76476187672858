import {
  MenuOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Popover, Progress, Space, Spin, Tabs } from 'antd';
import * as React from 'react';
import {
  DataLogActions,
  DataLogStatus,
  MenuSize,
  TaxReportCountry,
  VatTaxReportType,
} from 'store/app/enums';
import { User } from 'store/auth/models';
import {
  ActivityLog,
  ActivityRoleUpdateRequest,
  VatReportData,
  VatRunData,
} from 'store/vat/models';
import { getProviderLink } from 'utils/get-provider-link';
import { renderErrorMessage } from './render-error-messages';
import { VatReturnForms } from './vat-return-forms';
import { RenderTransactions } from './tables/render-transactions';
import { renderReconciliations } from './tables/render-reconciliation';
import { renderMaltaRecap } from './tables/render-malta-recap';
import { renderCyprusRecap } from './tables/render-cyprus-recap';
import { ActivityTab } from './activity-tab';
import { AccountType, JournalType, ReturnFrequency } from 'store/api/enums';
import styled from 'styled-components';
import {
  ContextButton,
  ExportButton,
  IconContextButton,
} from 'components/Custom/buttons';
import { ReactComponent as IconRefresh } from 'assets/images/icons/iconRefresh.svg';

import { StatusGetter } from 'utils/status-getter';
import { Entity } from 'store/organisation/models';
import { AvatarGroupRenderer } from 'utils/avatar-group-renderer';
import { AvatarSizes } from 'utils/render-avatar';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import Logo from 'assets/images/generateTAX-logo.png';
import { getActionMenuItems } from './get-action-menu-items';
import { getNextButton } from './get-next-button';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import { DataJournal, ExcelFileResponse } from 'store/vat/models/vatReportData';
import Excel, { Column } from 'exceljs';
import { StyledTooltip } from 'components/Custom/Tooltip';

import {
  BlackSpan,
  BoldNameSpan,
  CardDescSpan,
  CardTitleLargeSpan,
} from 'components/Custom/spans';
import { StyledCard } from 'components/Custom/card';
import PendingReview from 'assets/images/pending-review.svg';
import { RenderExceptions } from './tables/render-exceptions';
import {
  Preference,
  PreferenceActions,
  PreferenceSections,
} from 'utils/preferences';
import { ReactComponent as IconClose } from 'assets/images/icons/iconClose.svg';
import { ExceptionsActionType } from 'store/vat/models/exceptionWrapper';
import { MappingOverview } from '../Mapping';
import { MappingTaxRate } from 'store/returns/models/mappingTaxRate';
const builder = require('xmlbuilder');

const VatReportWrapper = styled.div`
  .top-wrapper {
    border-bottom: 2px solid #f0f0f0;
    background: white;
    display: flex;
    justify-content: space-between;
    height: 65px;
    position: fixed;
    z-index: 100;
    min-width: 800px;
  }

  .content-wrapper {
    font-family: Inter;
    background: #f3f7f8;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    //min-height: 100vh;
    padding: 40px;
  }

  .form-wrapper {
    //padding-left: 20px;
    //background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
  }

  .ant-tabs-nav-wrap {
    padding: 0px 0px 0px 40px;
  }

  .menu-wrapper {
    background: #f3f7f8;
    width: 360px;
    z-index: 102;
  }

  .menu-top-wrapper {
    //border-bottom: 2px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 40px;
    gap: 20px;
    position: absolute;
    z-index: 103;
    right: 0px;
    top: 80px;
  }

  .menu-burger-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
  }
  .menu-burger-icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  }

  .burger-on {
    box-shadow: 2px 2px 2px 0px #adc2b94d inset;
    border: 1px solid #dbeae3;
    background: #f3f7f8;
    &:hover,
    &:active {
      border: 1px solid #41aaaf;
    }
  }

  .burger-off {
    border: 1px solid transparent;
    background: none;
    &:hover,
    &:active {
      border: 1px solid #dbeae3;
      background: #f3f7f8;
    }
  }

  .menu-burger-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #042a2a;
  }

  .menu-entity-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .menu-entity-details {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    //line-height: 18px;
  }

  .tabs-wrapper {
    position: fixed;
    width: 100%;
    align-self: end;
    z-index: 101;
  }

  .ant-progress-text {
    color: #41aaaf !important;
  }

  .csv-link-wrapper {
    font-size: 12px;
    font-weight: 600;
    color: #042a2a;
  }
  .csv-link-wrapper:hover {
    text-decoration: none;
  }

  .ant-empty-description:after {
    content: ' found for this period' !important;
  }
`;

interface VatReportProps {
  loading: boolean;
  success: boolean;
  vatRunData: VatRunData;
  vatReportData?: VatReportData;
  generatedDate: string;
  reloadReportData: (data: VatRunData, isSaved: boolean) => void;
  currentUser: User;
  loadingPercent: number;
  resetValues: boolean;
  resetFinished: () => void;
  reconciliation?: Map<string, string>;
  pages: number;
  saveReturn: (vatRunData: VatRunData) => void;
  revertData: (vatRunData: VatRunData) => void;
  cancelReturn: (vatRunData: VatRunData) => void;
  setAwaitingApproval: (vatRunData: VatRunData) => void;
  setInternallyApproved: (vatRunData: VatRunData) => void;
  sendForClientApproval: (vatRunData: VatRunData) => void;
  setClientApproved: (vatRunData: VatRunData) => void;
  setNotApproved: () => void;
  cancelNotSaved: (vatRunData: VatRunData) => void;
  updateActivityRole: (
    activityRoleUpdateRequest: ActivityRoleUpdateRequest
  ) => void;
  activityLogs?: ActivityLog[];
  updateNote: (noteText: string, dataLogId: string) => void;
  cannotSave: boolean;
  menuSize: MenuSize;
  currentOrganisation?: Entity;
  retFreq: string;
  returnTypeId: string;
  excelFileData?: ExcelFileResponse;
  onReconnect: () => void;
  getExcelFileForExport: () => void;
  loackWarningWindow: (vatRunData, actionType) => void;
  openAdjustmentPanal: (
    selectedRowKeys: DataJournal[],
    setSelectedRowKey: (data: DataJournal[]) => void
  ) => void;
  handlePreferences: (
    preferenceSection: PreferenceSections,
    action: PreferenceActions,
    preference: Preference
  ) => any;
  addExceptionAction: (
    exceptionActionType: ExceptionsActionType,
    selectedRowKeys: DataJournal[],
    setSelectedRowKey: (data: DataJournal[]) => void,
    selectedAdjustmentPeriod: string | null,
    selectedCaptureDate: any
  ) => void;
  noDataFound: boolean;
}

export const VatReport: React.FC<VatReportProps> = ({
  loading,
  success,
  vatRunData,
  vatReportData,
  generatedDate,
  reloadReportData,
  currentUser,
  loadingPercent,
  resetValues,
  resetFinished,
  reconciliation,
  pages,
  saveReturn,
  revertData,
  cancelReturn,
  setAwaitingApproval,
  setInternallyApproved,
  sendForClientApproval,
  setClientApproved,
  setNotApproved,
  cancelNotSaved,
  updateActivityRole,
  activityLogs,
  updateNote,
  cannotSave,
  menuSize,
  currentOrganisation,
  retFreq,
  returnTypeId,
  onReconnect,
  getExcelFileForExport,
  excelFileData,
  loackWarningWindow,
  openAdjustmentPanal,
  handlePreferences,
  addExceptionAction,
  noDataFound,
}) => {
  const [showSideMenu, setShowSideMenu] = React.useState<boolean>(true);
  const [journalType, setJournalType] = React.useState<string>(
    vatReportData && vatReportData.summarizedJournals.length === 0
      ? 'DETAILED'
      : 'SUMMARISED'
  );
  const [activeKey, setActiveKey] = React.useState('1');
  const [filteredBox, setFilteredBox] = React.useState(null);
  const [enableDownloadFile, setEnableDownloadFile] =
    React.useState<boolean>(false);
  const [showCard, setShowCard] = React.useState(true);
  const [exceptionsSorted, setExceptionsSorted] =
    React.useState<DataJournal[]>();
  const [progress, setProgress] = React.useState(0);
  const [progressText, setProgressText] = React.useState(
    `Connecting to ` + vatRunData.providerName
  );
  const [switchTotals, setSwitchTotals] = React.useState<boolean>(false);
  const [exporting, setExporting] = React.useState<boolean>(false);

  React.useEffect(() => {
    setActiveKey('1');
    setProgress(1);
  }, []);

  React.useEffect(() => {
    loadingPercent === 0 && setProgress(1);
  }, [loadingPercent]);

  React.useEffect(() => {
    if (loadingPercent !== 100) {
      const timerSecs =
        progress > 75 ? 1000 : progress > 50 ? 500 : progress > 75 ? 750 : 100;
      switch (progress) {
        case 0:
        case 80:
        case 60:
          setProgressText(`Connecting to ${vatRunData.providerName}...`);
          break;
        case 15:
          setProgressText(`Fetching data from ${vatRunData.providerName}...`);
          break;
        case 30:
        case 75:
          setProgressText(`Calculating...`);
          break;
        case 45:
        case 90:
          setProgressText(`Processing...`);
          break;
      }
      if (progress === 99) {
        setProgress(83);
      } else
        setTimeout(function () {
          setProgress(progress + 1);
        }, timerSecs);
    } else {
      setProgress(100);
    }
  }, [progress]);

  React.useEffect(() => {
    setJournalType(
      vatReportData && vatReportData.summarizedJournals.length === 0
        ? 'DETAILED'
        : 'SUMMARISED'
    );
  }, [JSON.stringify(vatReportData)]);

  React.useEffect(() => {
    const exceptSorted = vatReportData?.exceptions?.map((value, index) => {
      value.key = index + 1;
      return value;
    });
    setExceptionsSorted(exceptSorted);
  }, [vatReportData?.exceptions]);

  React.useEffect(() => {
    const selectedSubsections = switchTotals
      ? vatReportData?.adjustedSubsections
      : vatReportData?.subsections;

    async function exportExcelFile() {
      //@ts-ignore
      const data = Buffer.from(excelFileData?.file, 'base64'); //toBuffer(excelFileData?.file);
      const workbook = new Excel.Workbook(); //@ts-ignore
      await workbook.xlsx.load(data);

      /* generate array of presidents from the second worksheet */
      const detailedVATReturnWS = workbook.getWorksheet(2); //wb.Sheets[wb.SheetNames[1]];
      const rawData: any = vatReportData?.journals.map((journal) =>
        Object.values(journal)
      );
      detailedVATReturnWS
        ?.insertRow(1, getHeaderForExport(vatReportData?.journals))
        .commit();
      const insertedRows = detailedVATReturnWS?.insertRows(2, rawData);
      insertedRows?.forEach((row) => row.commit());

      /* generate array of presidents from the second worksheet */
      const detailedExceptionsWS = workbook.getWorksheet(3); //wb.Sheets[wb.SheetNames[1]];
      const rawData2: any = vatReportData?.exceptions.map((journal) =>
        Object.values(journal)
      );
      if (rawData2?.length > 0) {
        detailedExceptionsWS
          ?.insertRow(1, getHeaderForExport(vatReportData?.exceptions))
          .commit();
        const insertedRows2 = detailedExceptionsWS?.insertRows(2, rawData2);
        insertedRows2?.forEach((row) => row.commit());
      }

      const VATReturnWS = workbook.getWorksheet(1);

      selectedSubsections?.forEach((subsection) => {
        if (subsection.cellName.includes(';') && VATReturnWS) {
          const cellNames = subsection.cellName.split(';');
          cellNames.forEach((cellName, index) => {
            let value = 0;
            if (index == 0) {
              value = subsection.net;
            } else if (index == 1) {
              value = subsection.tax;
            }
            VATReturnWS.getCell(cellName).value = value;
          });
        } else if (
          VATReturnWS &&
          vatRunData.taxReportCountry === TaxReportCountry.MALTA &&
          subsection.code == 'Box 45'
        ) {
          const box43 = vatReportData?.subsections?.find(
            (subs) => subs.code == 'Box 43'
          )?.net;
          const box44 = vatReportData?.subsections?.find(
            (subs) => subs.code == 'Box 44'
          )?.net;
          if (
            box43 !== undefined &&
            box44 !== undefined &&
            !isNaN(box43) &&
            !isNaN(box44)
          ) {
            const box45 = box43 - box44;
            VATReturnWS.getCell(subsection.cellName).value = box45;
          }
        } else if (subsection.cellName && VATReturnWS) {
          VATReturnWS.getCell(subsection.cellName).value = subsection.net;
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileExtension = '.xlsx';

      const blob = new Blob([buffer], { type: fileType });
      setEnableDownloadFile(false);
      saveAs(
        blob,
        `${vatRunData.connectionName} - ${vatRunData.taxReportTypeName}` +
          fileExtension
      );
    }
    if (enableDownloadFile && excelFileData) {
      exportExcelFile();
    }
    setExporting(false);
  }, [excelFileData]);

  const providerName = 'xero';
  const jsonMappingString = vatRunData?.jsonMapping;
  const jsonMapping: MappingTaxRate[] = jsonMappingString
    ? JSON.parse(jsonMappingString).mapping
    : null;

  const mappingTab = () => {
    return (
      <MappingOverview
        mappedReturn={currentOrganisation?.returns.find(
          (r) => r.mappingId === vatRunData.mappingId
        )}
        jsonMapping={jsonMapping}
      />
      // <div style={mapTaxRatesStyle}>
      //   <MapTaxRates
      //     sections={sections}
      //     taxRateOptions={taxRateOptions}
      //     outOfScopeOptions={outOfScopeOptions}
      //     handleTaxRangeChange={() => {}}
      //     form={form2}
      //     mappingData={mappingData}
      //     taxRates={returnData?.taxRates}
      //     updateSelectedRates={() => {}}
      //     setOptions={() => {}}
      //     prepareTaxRateOptions={prepareTaxRateOptions}
      //     addUnmapped={() => {}}
      //     useCashReverse={false}
      //     updateTaxRateType={() => {}}
      //     disabled={true}
      //     useReverseCharge={false}
      //     stepOneData={{}}
      //     useCashAccounting={false}
      //     editInitialData={() => {}}
      //     hasTemplates={false}
      //     openTemplateWindow={() => {}}
      //     handlePreferences={() => {}}
      //   />
      // </div>
    );
  };
  const tabsHeaderClass =
    menuSize === MenuSize.WIDE ? 'header-narrow' : 'header-wide';

  const downloadPDF = async () => {
    setExporting(true);
    const getTargetElement: any = document.getElementById('vat-to-be-exported');
    const getStatusElement: any = document.getElementById('vat-status');

    const pdf = new jsPDF('p', 'mm', 'a4', true);
    pdf.addPage();
    await html2canvas(getStatusElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('img/png');
      pdf.addImage(imgData, 'PNG', 160, 25, 75, 6);
    });

    html2canvas(getTargetElement, { scale: 2 }).then((canvas) => {
      const imgWidth = 185;
      const pageHeight = 270;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const imgData = canvas.toDataURL('img/png');
      let position = 10; // give some top padding to first page

      pdf.addImage(Logo, 'PNG', 5, 5, 45, 8);
      pdf.setFontSize(14);
      pdf.setFont('Inter', 'bold');
      pdf.setFillColor('#042A2A');
      pdf.text(`${vatRunData.connectionName}`, 100, 17, { align: 'center' });
      pdf.setFont('Inter', 'normal');
      pdf.setFontSize(12);
      pdf.text(
        `${vatRunData.taxReportCountry} - ${vatRunData.taxReportTypeName} (${vatRunData.currency})`,
        100,
        22,
        { align: 'center' }
      );
      pdf.text(`Period - ${vatRunData.generationPeriod}`, 100, 27, {
        align: 'center',
      });
      pdf.text(`Generated - ${generatedDate}`, 100, 32, {
        align: 'center',
      });

      pdf.addImage(imgData, 'PNG', 5, 35, 200, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 5, position, 200, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.deletePage(1);
      pdf.save(
        `${vatRunData.connectionName} - ${vatRunData.taxReportTypeName}.pdf`
      );
    });
    setExporting(false);
  };

  const getHeaderForExport = (dataToExport): Array<Partial<Column>> => {
    const obj = dataToExport?.length > 0 ? dataToExport[0] : null;
    let headerArr: Array<Partial<Column>> = [];
    if (obj) {
      const headers = Object.keys(obj);
      headerArr = headers.map((key) =>
        key.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      );
    }

    return headerArr;
  };

  const downloadExcel = async () => {
    setExporting(true);
    setEnableDownloadFile(true);
    getExcelFileForExport();
  };

  const renderContextMenu = () => {
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <IconContextButton
            type="text"
            icon={
              <FilePdfOutlined
                style={{ width: '18px', height: '19px', fontSize: 18 }}
              />
            }
            onClick={downloadPDF}
          >
            Export PDF
          </IconContextButton>
          <IconContextButton
            //disabled
            type="text"
            icon={
              <FileExcelOutlined
                style={{ width: '18px', height: '19px', fontSize: 18 }}
              />
            }
            onClick={downloadExcel}
          >
            Export Excel
          </IconContextButton>
        </Space>
      </div>
    );
  };

  const renderClientBox = () => {
    return (
      <>
        {showCard && (
          <StyledCard
            width={'100%'}
            nopadding
            fullsize
            hideheader
            style={{
              //border: '1px solid #D13131',
              boxShadow: '0px 4px 10px 0px #0000000D',
              marginBottom: '20px',
              display: 'flex',
              fontSize: 14,
              fontWeight: 400,
              padding: 0,
            }}
            extra={
              <IconClose
                style={{
                  top: '25',
                  position: 'absolute',
                  right: '25',
                  cursor: 'pointer',
                  filter:
                    'invert(71%) sepia(12%) saturate(247%) hue-rotate(131deg) brightness(80%) contrast(81%)',
                }}
                onClick={() => setShowCard(false)}
              />
            }
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexFlow: 'row',
                alignContent: 'normal',
                padding: '24px 24px 0 0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  padding: '30px 0px 24px 0px',
                }}
              >
                <Space direction="vertical">
                  <Space>
                    <CardTitleLargeSpan
                      style={{ marginBottom: 10, display: 'block' }}
                    >
                      Review Pending
                    </CardTitleLargeSpan>
                    <ExclamationCircleOutlined
                      style={{
                        color: '#FFBC3E',
                        fontSize: 19,
                        marginLeft: 6,
                        marginBottom: 8,
                      }}
                    />
                  </Space>
                  <CardDescSpan style={{ marginBottom: 16 }}>
                    <BlackSpan>
                      You have been invited to review this submission by{' '}
                      <BoldNameSpan style={{ fontWeight: 700 }}>
                        {vatRunData.preparer}
                      </BoldNameSpan>
                      .<br />
                      Please approve or reject.
                    </BlackSpan>
                  </CardDescSpan>
                </Space>
              </div>

              <img alt="VAT checker" height={154} src={PendingReview} />
            </div>
          </StyledCard>
        )}
      </>
    );
  };

  const renderForms = () => {
    if (vatRunData.taxType !== VatTaxReportType.RECAP) {
      return (
        <VatReturnForms
          data={vatReportData}
          taxReportCountry={vatRunData.taxReportCountry}
          setActiveKey={setActiveKey}
          setFilteredBox={setFilteredBox}
          resetValues={resetValues}
          resetFinished={resetFinished}
          renderClientBox={renderClientBox}
          renderContextMenu={renderContextMenu}
          currentUser={currentUser}
          isAdjustedReturn={!!vatReportData?.adjustedSubsections}
          switchTotals={switchTotals}
          setSwitchTotals={setSwitchTotals}
          exporting={exporting}
        />
      );
    } else {
      if (vatRunData.taxReportCountry === TaxReportCountry.MALTA)
        return renderMaltaRecap(vatReportData?.contacts);
      else if (vatRunData.taxReportCountry === TaxReportCountry.CYPRUS) {
        return renderCyprusRecap(vatReportData?.contacts);
      } else if (
        vatRunData.taxReportCountry === TaxReportCountry.IRELAND_RECAP
      ) {
        return renderMaltaRecap(vatReportData?.contacts);
      }
    }
  };

  const checkClient = () => {
    if (
      AccountType[currentUser.accountType] === AccountType.CLIENT &&
      vatRunData.status &&
      DataLogStatus[vatRunData.status] !==
        DataLogStatus.AWAITING_CLIENT_APPROVAL //&&
      // vatRunData.clientId !== currentUser.accountUuid
    )
      return true;
    if (
      AccountType[currentUser.accountType] === AccountType.CLIENT &&
      vatRunData.status &&
      DataLogStatus[vatRunData.status] ===
        DataLogStatus.AWAITING_CLIENT_APPROVAL &&
      vatRunData.clientId !== currentUser.accountUuid
    )
      return true;
    return false;
  };

  const renderSideMenu = () => {
    const selReturnTypeCountry = currentOrganisation?.returnTypesEdit?.find(
      (rt) => rt.id === returnTypeId
    )?.countryName;

    return (
      <>
        {showSideMenu && (
          <div className="menu-wrapper">
            <div id="vat-status">
              <StatusGetter status={vatRunData.status} />
            </div>
            <div className="divider-20" />
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <a
                href={getProviderLink(
                  providerName.toLowerCase(),
                  vatRunData.shortCode
                )}
                style={{ cursor: 'pointer' }}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={
                    providerName
                      ? require(`shared/assets/images/${providerName.toLowerCase()}_small.png`)
                          .default
                      : ''
                  }
                  width={25}
                />
              </a>
              <span className="menu-entity-name">
                {vatRunData.connectionName}
              </span>
            </div>
            <div className="menu-entity-details">
              {`${selReturnTypeCountry} - ${vatRunData.taxReportTypeName} (${vatRunData.currency})`}
              <br />
              Period - {vatRunData.generationPeriod}
              <br />
              Generated - {generatedDate}
            </div>

            {!cannotSave &&
            vatReportData &&
            (vatReportData.journals.length > 0 ||
              vatReportData.exceptions.length > 0) ? (
              <>
                {(!vatRunData.status ||
                  (DataLogStatus[vatRunData.status] !==
                    DataLogStatus.SUBMITTED &&
                    DataLogStatus[vatRunData.status] !==
                      DataLogStatus.CANCELLED)) && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //gap: 20,
                      margin: '20px 0',
                    }}
                  >
                    <Spin spinning={loading || !success}>
                      {getNextButton(
                        handleMenuClick,
                        vatRunData,
                        currentUser,
                        checkClient
                      )}
                    </Spin>{' '}
                    {!checkClient() &&
                      (!loading || success) &&
                      currentUser &&
                      AccountType[currentUser.accountType] !==
                        AccountType.CLIENT && (
                        <Popover
                          placement="bottomRight"
                          arrow={false}
                          trigger="click"
                          content={getActionMenuItems(
                            vatRunData,
                            currentUser,
                            handleMenuClick,
                            cannotSave
                          )}
                          title={''}
                          overlayStyle={{ zIndex: 999 }}
                        >
                          <ContextButton
                            type="text" //@ts-ignore
                            bgcolor="white"
                          >
                            <EllipseIcon
                              style={{
                                height: '10px',
                              }}
                            />
                          </ContextButton>
                        </Popover>
                      )}
                  </div>
                )}

                {vatRunData.dataLogId &&
                  vatRunData.status &&
                  DataLogStatus[vatRunData.status] !==
                    DataLogStatus.CANCELLED && (
                    <ActivityTab
                      data={vatRunData}
                      updateActivityRole={updateActivityRole}
                      activityLogs={activityLogs}
                      updateNote={updateNote}
                      currentUser={currentUser}
                    />
                  )}
              </>
            ) : null}
            {renderErrorMessage(vatRunData)}
          </div>
        )}
      </>
    );
  };

  const filterJournals = (boxNumber) => {
    if (filteredBox) {
      const filteredData =
        JournalType[journalType] == JournalType.SUMMARISED
          ? vatReportData?.summarizedJournals?.filter(
              (journal) => journal.selectedValue == boxNumber
            )
          : vatReportData?.journals?.filter(
              (journal) => journal.selectedValue == boxNumber
            );
      return filteredData?.map((value, index) => {
        value.key = index + 1;
        return value;
      });
    } else {
      return JournalType[journalType] == JournalType.SUMMARISED
        ? vatReportData?.summarizedJournals?.map((value, index) => {
            value.key = index + 1;
            return value;
          })
        : vatReportData?.journals?.map((value, index) => {
            value.key = index + 1;
            return value;
          });
    }
  };

  const addTabItems = (reportData?: VatReportData): any => {
    return new Array(pages).fill(null).map((_, i) => {
      const id = i + 1;
      let lbl;
      let child;
      switch (id) {
        case 1:
          child = <>{renderForms()}</>;
          if (vatRunData.taxType !== VatTaxReportType.RECAP) {
            lbl = 'VAT return';
            break;
          } else {
            if (
              vatRunData.taxReportCountry === TaxReportCountry.MALTA ||
              vatRunData.taxReportCountry === TaxReportCountry.CYPRUS ||
              vatRunData.taxReportCountry === TaxReportCountry.IRELAND_RECAP
            ) {
              lbl = 'Statement';
              break;
            }
          }
        case 2:
          const count = filterJournals(filteredBox)?.length;

          lbl = `Transactions (${count ? count : 0})`;
          child = (
            <RenderTransactions
              transData={filterJournals(filteredBox)}
              dataToExport={filterJournals(filteredBox)}
              journalType={journalType}
              //isException={false}
              setJournalType={setJournalType}
              filteredBox={filteredBox}
              noSumData={
                vatReportData && vatReportData.summarizedJournals.length === 0
              }
              handlePreferences={handlePreferences}
              providerName={vatRunData.providerName}
              showSideMenu={showSideMenu}
              isAdjustedReturn={!!vatReportData?.adjustedSubsections}
            />
          );
          break;
        case 3:
          const handleExceptions =
            !!vatRunData.dataLogId &&
            vatRunData.status &&
            (DataLogStatus[vatRunData.status] === DataLogStatus.SAVED ||
              DataLogStatus[vatRunData.status] ===
                DataLogStatus.AWAITING_APPROVAL ||
              DataLogStatus[vatRunData.status] ===
                DataLogStatus.INTERNAL_APPROVED ||
              DataLogStatus[vatRunData.status] ===
                DataLogStatus.EXCEPTIONS_MODIFIED);
          if (pages === 4 || pages === 5) {
            lbl = `Exceptions (${
              reportData?.exceptions?.length
                ? reportData?.exceptions?.length
                : 0
            })`;

            child = (
              <RenderExceptions
                transData={exceptionsSorted}
                dataToExport={exceptionsSorted}
                openAdjustmentPanal={openAdjustmentPanal}
                addExceptionAction={addExceptionAction}
                handleExceptions={!!handleExceptions}
                handlePreferences={handlePreferences}
                providerName={vatRunData.providerName}
                showSideMenu={showSideMenu}
              />
            );
            break;
          } else {
            lbl = 'Mapping';
            child = mappingTab();
            break;
          }

        case 4:
          if (pages === 4 || pages === 5) {
            lbl = 'Reconciliation';
            child = renderReconciliations(reconciliation);
            break;
          }
        case 5:
          lbl = 'Mapping';
          child = mappingTab();
          break;
      }
      return {
        label: lbl,
        key: id.toString(),
        //children: child,
        children: (
          <div
            className={`content-wrapper vat-content ${tabsHeaderClass} scroller-submissions`}
          >
            {/* <div
              style={{
                width: '100%',
                width: lbl?.toLowerCase().includes('exceptions') || lbl?.toLowerCase().includes('transactions') ? '70%' : '100%',
                height: '100%',
              }}
            > */}
            <div
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {child}
              <div className="divider-20" />
              <div className="divider-20" />
              <div className="divider-20" />
            </div>
            {renderSideMenu()}
          </div>
        ),
      };
    });
  };

  const handleMenuClick = (key: DataLogActions) => {
    switch (key) {
      case DataLogActions.REVERT_DATA:
        revertData(vatRunData);
        break;
      case DataLogActions.SAVE_VAT_RETURN:
        saveReturn(vatRunData);
        break;
      case DataLogActions.CANCEL_RETURN:
        if (!vatRunData.status) cancelNotSaved(vatRunData);
        else cancelReturn(vatRunData);
        break;
      case DataLogActions.SET_AWAITING_APPROVAL:
        setAwaitingApproval(vatRunData);
        break;
      case DataLogActions.SET_INTERNAL_APPROVED:
        setInternallyApproved(vatRunData);
        break;
      case DataLogActions.SEND_FOR_CLIENT_APPROVAL:
        sendForClientApproval(vatRunData);
        break;
      case DataLogActions.SET_NOT_APPROVED:
        setNotApproved();
        break;
      case DataLogActions.SET_CLIENT_APPROVED:
        setClientApproved(vatRunData);
        break;
      case DataLogActions.MARK_AS_SUBMITTED:
        loackWarningWindow(vatRunData, DataLogActions.MARK_AS_SUBMITTED);
        break;
      case DataLogActions.LOCK_AS_APPROVED:
        loackWarningWindow(vatRunData, DataLogActions.LOCK_AS_APPROVED);
        break;
    }
  };

  const refreshDisable = (data: VatRunData) => {
    const disable =
      (data.status &&
        (DataLogStatus[data.status] === DataLogStatus.LOCKED ||
          DataLogStatus[data.status] === DataLogStatus.SUBMITTED ||
          DataLogStatus[data.status] === DataLogStatus.CANCELLED ||
          DataLogStatus[data.status] === DataLogStatus.NOT_SAVED)) ||
      (data.validRange !== null &&
        data.validRange === false &&
        data.isFirstTime === false);

    return disable;
  };

  const refreshTooltip = (data: VatRunData) => {
    let tooltip = 'Update report data from Xero';
    const disable =
      (data.status &&
        (DataLogStatus[data.status] === DataLogStatus.LOCKED ||
          DataLogStatus[data.status] === DataLogStatus.SUBMITTED ||
          DataLogStatus[data.status] === DataLogStatus.CANCELLED)) ||
      (data.validRange !== null &&
        data.validRange === false &&
        data.isFirstTime === false);

    if (disable) tooltip = 'Cannot refresh locked return';
    else if (!currentOrganisation?.connectedProperly)
      tooltip = 'Entity must be connected to be refreshed';
    return tooltip;
  };

  const onRefreshClick = (data: VatRunData) => {
    let isSaved = true;
    if (
      (data.validRange !== null &&
        data.validRange === true &&
        !data.dataLogId) ||
      data.isFirstTime === true
    ) {
      isSaved = false;
    }
    //setProgress(1);
    reloadReportData(data, isSaved);
  };

  const getReturnFreq = () => {
    let freq = '';
    switch (retFreq) {
      case ReturnFrequency.BI_YEARLY:
        freq = '3';
        break;

      default:
        freq = '1';
        break;
    }

    return freq;
  };

  const getCurrency = (currency) => {
    let curr = '';
    switch (currency) {
      case 'EUR':
        curr = 'E';
        break;

      default:
        curr = 'E';
        break;
    }

    return curr;
  };

  const onDownloadXMLClick = (data: VatRunData) => {
    const sales: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'T1'
    )?.net;
    const purchases: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'T2'
    )?.net;
    const goodsTo: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'E1'
    )?.net;
    const goodsFrom: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'E2'
    )?.net;
    const servicesTo: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'ES1'
    )?.net;
    const servicesFrom: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'ES2'
    )?.net;

    const xml = builder
      .create('VAT3', { encoding: 'UTF-8', standalone: 'yes' })
      .att('currency', getCurrency(vatRunData.currency))
      .att('name', vatRunData.connectionName.substring(0, 30))
      .att('regnum', currentOrganisation?.vatNumber)
      .att('startdate', moment(vatRunData.fromDate).format('DD/MM/YYYY'))
      .att('enddate', moment(vatRunData.toDate).format('DD/MM/YYYY'))
      .att('sales', parseInt(sales))
      .att('purchs', parseInt(purchases))
      .att('goodsto', parseInt(goodsTo))
      .att('goodsfrom', parseInt(goodsFrom))
      .att('servicesto', parseInt(servicesTo))
      .att('servicesfrom', parseInt(servicesFrom))
      .att('type', '0') //vatRunData.taxType
      .att('filefreq', getReturnFreq())
      .att('formversion', '1')
      .att('language', 'E')
      .end({ pretty: true });

    //@ts-ignore
    var data = new Blob([xml], { type: 'xml' }); //@ts-ignore
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      `${vatRunData.connectionName}-${vatRunData.taxReportTypeName}.xml`
    );
    tempLink.click();
  };

  const showRefreshButton = () => {
    if (
      (!vatRunData.status ||
        DataLogStatus[vatRunData.status] === DataLogStatus.NOT_SAVED) &&
      ((vatRunData.validRange && vatRunData.validRange == true) ||
        vatRunData.isFirstTime)
    )
      return true;
    if (
      vatRunData.status &&
      DataLogStatus[vatRunData.status] === DataLogStatus.SAVED &&
      AccountType[currentUser.accountType] !== AccountType.CLIENT
    )
      return true;
    if (
      (vatRunData.status &&
        (DataLogStatus[vatRunData.status] === DataLogStatus.LOCKED ||
          DataLogStatus[vatRunData.status] === DataLogStatus.SUBMITTED ||
          DataLogStatus[vatRunData.status] === DataLogStatus.CANCELLED)) ||
      (vatRunData.validRange === false && vatRunData.isFirstTime === false)
    )
      return true;
    return false;
  };

  const onTabChange = (key) => {
    if (key == '1') setFilteredBox(null);
    setActiveKey(key);
  };

  return (
    <VatReportWrapper>
      {vatRunData && (
        <>
          <div className="tabs-wrapper">
            <Spin spinning={loading} size="large">
              <Tabs
                //type="card"
                activeKey={activeKey}
                onChange={onTabChange}
                items={addTabItems(vatReportData)}
                //onChange={onTabChange}
                size={'large'}
                tabBarGutter={40}
              />
            </Spin>
          </div>
          <div className={`top-wrapper ${tabsHeaderClass} vat-header`}>
            <div style={{ width: '100%' }} />
          </div>
          <div className={'menu-top-wrapper'}>
            {loadingPercent === 100 && progress === 100 && (
              <>
                <div
                  style={{
                    display: 'flex',
                    width: 330,
                    //flexDirection: 'column',
                    justifyContent: 'end',
                    //alignItems: 'center',
                    paddingLeft: 250,
                  }}
                >
                  {vatRunData.taxReportCountry ===
                    TaxReportCountry.IRELAND_STANDARD && (
                    <ExportButton
                      icon={<DownloadOutlined />}
                      //disabled={refreshDisable(vatRunData) || !showRefreshButton()}
                      onClick={() => onDownloadXMLClick(vatRunData)}
                      loading={loading || !success}
                      style={{ zIndex: 200, marginRight: 20 }}
                    >
                      Export XML
                    </ExportButton>
                  )}
                  <StyledTooltip //@ts-ignore
                    title={
                      showRefreshButton() &&
                      !refreshDisable(vatRunData) &&
                      refreshTooltip(vatRunData)
                    }
                    placement={'bottom'}
                  >
                    <ExportButton
                      icon={<IconRefresh />}
                      disabled={
                        refreshDisable(vatRunData) ||
                        !showRefreshButton() ||
                        AccountType[currentUser.accountType] ===
                          AccountType.CLIENT
                      }
                      onClick={() =>
                        currentOrganisation?.connectedProperly
                          ? onRefreshClick(vatRunData)
                          : onReconnect()
                      }
                      loading={loading || !success}
                      style={{ zIndex: 200 }}
                    >
                      {currentOrganisation?.connectedProperly
                        ? 'Refresh Data'
                        : 'Reconnect'}
                    </ExportButton>
                  </StyledTooltip>
                </div>
              </>
            )}
            {loadingPercent !== 100 && progress !== 100 && !noDataFound && (
              <div
                style={{
                  //display: 'flex',
                  width: 220,
                  //flexDirection: 'column',
                }}
              >
                <div className="menu-entity-details">{progressText}</div>

                <Progress
                  //percent={loadingPercent}
                  percent={progress}
                  type={'line'}
                  //type="circle"
                  strokeColor="#41AAAF"
                  //size={'small'}
                />
              </div>
            )}
            {currentOrganisation &&
              AccountType[currentUser.accountType] !== AccountType.CLIENT && (
                <AvatarGroupRenderer
                  entity={currentOrganisation}
                  maxCount={4}
                  size={AvatarSizes.DEFAULT}
                />
              )}

            <div className="menu-burger-button">
              <div
                className={`menu-burger-icon ${
                  showSideMenu ? 'burger-on' : 'burger-off'
                }`}
                onClick={() => setShowSideMenu(!showSideMenu)}
              >
                <MenuOutlined />
              </div>
              <span className="menu-burger-text">
                {showSideMenu ? 'Hide Menu' : 'Show Menu'}
              </span>
            </div>
          </div>
        </>
      )}
    </VatReportWrapper>
  );
};
