import { Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckCircleIcon } from 'assets/images/icons/checkCircleIcon.svg';
import { ReactComponent as RefreshIcon } from 'assets/images/icons/refreshIcon.svg';
import { ReactComponent as HistoryIcon } from 'assets/images/icons/historyIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/downloadIcon.svg';
import moment from 'moment';
import {
  ActivityLogsWrapper,
  EntityDetailsWrapper,
} from 'components/Custom/wrappers';
import { StyledCard } from 'components/Custom/card';
import {
  BlackSpan,
  BoldNameSpan,
  CardTitleLargeSpan,
  CardTitleSpan,
} from 'components/Custom/spans';
import {
  ContextButton,
  IconButton,
  IconContextButton,
  ScopeButton,
} from 'components/Custom/buttons';
import { AvatarGroupRenderer } from 'utils/avatar-group-renderer';
import { AvatarSizes } from 'utils/render-avatar';
import { Contact, ViesCheck } from 'store/vat-checker/models';
import { StatusGetter } from 'utils/status-getter';
import { ContactType, ViesStatus, ViesTriggerType } from 'store/app/enums';
import { CardInfo } from 'components/OrganisationDetails/card-info';
import { Organisation } from 'store/organisation/models';
import { getInvoiceLink } from 'utils/get-invoice-link';
import { StyledTooltip } from 'components/Custom/Tooltip';

interface ContactDetailsProps {
  loading: boolean;
  contact?: Contact;
  organisation?: Organisation;
  viesLogs: ViesCheck[] | undefined;
  handleRefreshData: (contactUuid: string) => void;
  checkContact: (contactUuid) => void;
  getHistoryLogs: (contactUuid, contactName) => void;
  openDownloadCertificateModal: (record: Contact, invoice?: string) => void;
}
export const ContactDetails: React.FC<ContactDetailsProps> = ({
  loading,
  contact,
  organisation,
  viesLogs,
  handleRefreshData,
  checkContact,
  getHistoryLogs,
  openDownloadCertificateModal,
}) => {
  const { t } = useTranslation();
  const [historyLogs, setHistoryLogs] = React.useState<ViesCheck[]>();

  React.useEffect(() => {
    setHistoryLogs(viesLogs);
  }, [viesLogs]);

  const renderContactDetailsCard = () => {
    return (
      <div /* style={{ padding: '0 40px' }} */>
        <div
          style={{
            paddingTop: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <StyledCard
            style={{
              border: '1px solid #E3EBEB',
              boxShadow: '0px 4px 10px 0px #0000000D',
              margin: '8px 0',
              display: 'flex',
              justifyContent: 'center',
              //cursor: 'pointer',
              minWidth: 700,
              maxWidth: 1000,
              width: '100%' /*minWidth: 916.6*/,
              overflow: 'auto',
              fontSize: 14,
              fontWeight: 400,
            }}
            //onClick={handleAddReturn}
          >
            <StyledCard
              style={{
                border: '1px solid #E3EBEB',
                margin: '20px 0',
                display: 'flex',
                width: '100%',
                overflow: 'auto',
                fontSize: 14,
                fontWeight: 400,
              }}
              stopshadowbox
              fullsize
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space direction="vertical">
                  <Space>
                    <CardTitleLargeSpan>
                      {t('vat-checker.viesStatus')}
                    </CardTitleLargeSpan>
                    <StatusGetter
                      status={
                        contact?.viesStatus
                          ? ViesStatus[contact?.viesStatus]
                          : contact?.viesStatus
                      }
                      noDraft
                    />
                  </Space>
                  <Space>
                    <BlackSpan>{t('vat-checker.last-date-checked')}</BlackSpan>
                    <BoldNameSpan //@ts-ignore
                      fontSize="700"
                    >
                      {moment(contact?.lastViesCheck).format(
                        'DD/MM/YYYY hh:mm:ss'
                      )}
                    </BoldNameSpan>
                  </Space>
                </Space>
                <ScopeButton
                  type={'primary'}
                  onClick={() => {
                    checkContact(contact?.contactUuid);
                  }} // @ts-ignore
                  width={'135px'}
                  height={'35px'}
                  fontSize={'13px'}
                  lineheight={'24px'}
                  style={{ padding: '4px 5px', margin: '7px 13px' }}
                  loading={loading}
                >
                  {t('vat-checker.check-vat')}
                </ScopeButton>
              </div>
            </StyledCard>
            <StyledCard
              style={{
                border: '1px solid #E3EBEB',
                margin: '20px 0',
                display: 'flex',
                width: '100%',
                maxWidth: 900,
                minWidth: 600,
                overflow: 'auto',
                fontSize: 14,
                fontWeight: 400,
                overflowY: 'auto',
              }}
              stopshadowbox
              fullsize
              nopadding={false}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <CardTitleLargeSpan>
                  {`${
                    contact?.contactType &&
                    ContactType[contact?.contactType] == ContactType.SUPPLIER
                      ? t('vat-checker.suppliers')
                      : t('vat-checker.customers')
                  } ${t('vat-checker.details')}`}
                </CardTitleLargeSpan>
                <ScopeButton
                  //disabled
                  loading={loading}
                  type={'default'}
                  onClick={() =>
                    contact && handleRefreshData(contact.contactUuid)
                  } // @ts-ignore
                  width={'123px'}
                  height={'32px'}
                  fontSize={'13px'}
                  lineheight={'24px'}
                  style={{ padding: '4px 5px', margin: '0px 13px' }}
                >
                  {t('vat-checker.refresh-data')}
                </ScopeButton>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 20,
                  columnGap: 50,
                  //maxHeight: 400,
                  //minHeight: 400,
                  overflowY: 'auto',
                }}
              >
                <CardInfo
                  label={t('organisation.name')}
                  data={contact?.name}
                  wrapData
                  haveBorder
                />

                <CardInfo
                  label={t('vat-checker.address')}
                  data={contact?.address ? contact?.address : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('vat-checker.address2')}
                  data={contact?.address1 ? contact?.address1 : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('vat-checker.city')}
                  data={contact?.country ? contact?.country : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('signup.zip-code')}
                  data={contact?.phone ? contact?.phone : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('signup.country')}
                  data={contact?.country ? contact?.country : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('signup.phone')}
                  data={contact?.phone ? contact?.phone : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('vat-checker.vat-no')}
                  data={contact?.taxNumber ? contact?.taxNumber : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('vat-checker.primary-contact')}
                  data={contact?.email ? contact?.email : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('vat-checker.primary-contact-email')}
                  data={contact?.email ? contact?.email : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('vat-checker.secondary-contact')}
                  data={contact?.email ? contact?.email : '-'}
                  haveBorder
                />

                <CardInfo
                  label={t('vat-checker.secondary-contact-email')}
                  data={contact?.email ? contact?.email : '-'}
                  haveBorder
                />
              </div>
            </StyledCard>
          </StyledCard>
        </div>
      </div>
    );
  };

  const renderHistoryLogCard = () => {
    return (
      <StyledCard //@ts-ignore
        width={'fit-content'}
        style={{ marginTop: '28px', width: 305 }}
        nopadding={false}
        fullsize={false}
      >
        <div style={{ overflow: 'visible' }}>
          <Space style={{ columnGap: 20, marginBottom: 20 }}>
            <CardTitleSpan>{t('vat-checker.history-log')}</CardTitleSpan>
          </Space>
          <ActivityLogsWrapper>
            {historyLogs?.slice(0, 3)?.map((log) => (
              <div
                key={log.invoiceNumber}
                className="vies-logs-card vies-logs-card-details"
              >
                <div className="data-row-details">
                  <Space style={{ columnGap: 30 }}>
                    <span className="title-date">
                      {moment(log.viesCheckedDate).format(
                        'DD/MM/YYYY - hh:mm:ss'
                      )}
                    </span>
                    {ViesStatus[log.viesStatus] == ViesStatus.VALID && (
                      <IconButton
                        style={{
                          width: 25,
                          height: 25,
                        }}
                        onClick={() => {
                          if (contact) {
                            contact.lastViesCheck = log.viesCheckedDate;
                            openDownloadCertificateModal(
                              contact,
                              log.invoiceNumber
                            );
                          }
                        }}
                        icon={<DownloadIcon width={'12px'} height={'12px'} />}
                        type="default"
                      ></IconButton>
                    )}
                  </Space>
                  <div style={{ alignSelf: 'end', marginBottom: 5 }}>
                    <StatusGetter
                      status={
                        log.viesStatus
                          ? ViesStatus[log.viesStatus]
                          : log.viesStatus
                      }
                      smaller
                    />
                  </div>
                </div>

                <div>{ViesTriggerType[log.triggerType]}</div>
                {log.invoiceNumber && log.triggerInvoiceId && (
                  <Space className="invoice-box" style={{ marginTop: 5 }}>
                    <StyledTooltip //@ts-ignore
                      title="Click to view the invoice"
                    >
                      <a //@ts-ignore
                        href={
                          organisation?.providerName &&
                          getInvoiceLink(
                            //@ts-ignore
                            log.triggerInvoiceId,
                            organisation?.providerName
                          )
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={
                            organisation?.providerName
                              ? require(`shared/assets/images/${organisation?.providerName.toLowerCase()}_small.png`)
                                  .default
                              : ''
                          }
                          width={20}
                          style={{ paddingTop: '4px' }}
                        />
                      </a>
                    </StyledTooltip>
                    <span>{log.invoiceNumber}</span>
                  </Space>
                )}
              </div>
            ))}
          </ActivityLogsWrapper>
          {historyLogs && historyLogs?.length > 3 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '4px',
                flexDirection: 'column',
                rowGap: 5,
                overflow: 'auto',
                maxWidth: 1000,
                width: '100%' /*minWidth: 916.6*/,
              }}
            >
              <ScopeButton
                type="default" //@ts-ignore
                width="195px"
                height="32px"
                style={{ margin: '0 30px', marginTop: '12px' }}
                onClick={() =>
                  getHistoryLogs(contact?.contactUuid, contact?.name)
                }
              >
                View All
              </ScopeButton>
            </div>
          )}
        </div>
      </StyledCard>
    );
  };

  const renderContactDetails = () => {
    return (
      <div
        style={{
          backgroundColor: '#f3f7f8',
          display: 'flex',
          flexDirection: 'row',
          //justifyContent: 'center',
          margin: 'auto',
          height: 'calc(100vh - 13vh)',
        }}
      >
        <div style={{ float: 'left', width: '57%', margin: '0 7%' }}>
          {renderContactDetailsCard()}
        </div>
        <div
          style={{
            //height: '100vh',
            float: 'right',
            right: 17,
            width: '25%',
          }}
        >
          {renderHistoryLogCard()}
        </div>
      </div>
    );
  };

  const renderContextMenu = (record) => {
    //const to = `entities/organisation/${record.organisationId}#${record.mappingId}`;
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <IconContextButton
            type="text"
            icon={
              <CheckCircleIcon
                width={'16px'}
                height={'16px'}
                style={{
                  filter:
                    'brightness(0) saturate(100%) invert(13%) sepia(44%) saturate(720%) hue-rotate(131deg) brightness(95%) contrast(100%)',
                }}
              />
            }
            onClick={() => {
              checkContact(record.contactUuid);
            }}
          >
            {t('vat-checker.check-vat')}
          </IconContextButton>
          <IconContextButton
            disabled
            type="text"
            icon={<RefreshIcon width={'18px'} height={'18px'} />}
            onClick={() => {
              //refreshContactData(record.contactUuid);
            }}
          >
            {t('vat-checker.refresh-contact-data')}
          </IconContextButton>
          <IconContextButton
            type="text"
            icon={<HistoryIcon width={'18px'} height={'18px'} />}
            onClick={() => {
              getHistoryLogs(record.contactUuid, record.name);
            }}
          >
            {t('vat-checker.history-log')}
          </IconContextButton>
          <IconContextButton
            type="text"
            icon={<DownloadIcon width={'18px'} height={'18px'} />}
            onClick={() => {
              openDownloadCertificateModal(record);
            }}
          >
            {t('vat-checker.download-last-certificate')}
          </IconContextButton>
        </Space>
      </div>
    );
  };

  return <EntityDetailsWrapper>{renderContactDetails()}</EntityDetailsWrapper>;
};
