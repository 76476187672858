import { call, put } from 'redux-saga/effects';
import { fetchVatLogsFailure, fetchVatLogsSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { FetchVatLogsRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { GetVatLogsResponse, getVatLogs } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { VatReturnLog } from '../models';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchVatLogsSaga(action: FetchVatLogsRequestAction) {
  try {
    let err: ApiError | null;
    const { connectionId, mappingId } = action.payload;
    const vatReturnLogs: VatReturnLog[] = [];
    for (let i = 0; i < mappingId.length; i++) {
      const response: GetVatLogsResponse = yield call(
        getVatLogs,
        connectionId,
        mappingId[i]
      );

      err = checkResponse(response?.responseMessage);
      if (err) throw err;

      const tempLogs: VatReturnLog[] = response.data.logs;
      tempLogs?.forEach((vatLog) => {
        vatLog.connectionId = connectionId;
        vatLog.mappingId = mappingId[i];
        vatReturnLogs.push(vatLog);
      });
    }
    yield put(fetchVatLogsSuccess(vatReturnLogs));
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchVatLogsFailure());
  }
}
