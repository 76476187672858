import { call, put } from 'redux-saga/effects';
import {
  changeStatusAfterUpdate,
  fetchActivityLogsRequest,
  submitReturnFailure,
  submitReturnSuccess,
} from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { SubmitReturnRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { submitReturn } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { DataLogStatus, SubmissionUpdateField } from 'store/app/enums';
import {
  fetchOrganisationsRequest,
  updateSubmissionLog,
} from 'store/organisation/actions';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* submitReturnSaga(action: SubmitReturnRequestAction) {
  try {
    let err: ApiError | null;
    const { dataLogId, mappingId } = action.payload;
    const response: ApiResponse = yield call(
      submitReturn,
      dataLogId,
      mappingId
    );
    err = checkResponse(response?.responseMessage);
    if (err) throw err;
    const message: MessageData = {
      title: 'Success!',
      description: response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(fetchActivityLogsRequest(dataLogId));
    yield put(changeStatusAfterUpdate(DataLogStatus.SUBMITTED));
    yield put(fetchOrganisationsRequest());

    yield put(submitReturnSuccess());
    yield put(
      updateSubmissionLog(
        mappingId,
        SubmissionUpdateField.STATUS,
        'SUBMITTED',
        dataLogId
      )
    );
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(submitReturnFailure());
  }
}
