import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  fetchAddReturnDataRequest,
  saveMappingRequest,
} from 'store/returns/actions';
import {
  getReturnData,
  getReturnLoading,
  getReturnPreLoading,
  getReturnTaxRatesLoading,
} from 'store/returns/selectors';
import { MappingForm } from 'components/MappingForm';
import { useState } from 'react';
import { MappingData } from 'store/returns/models';
import {
  getOrgMappedOrganisations,
  getOrgMappings,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import moment from 'moment';
import { calculateNextPeriod } from 'utils/calculate-next-period';
import { Form, Spin } from 'antd';
import { ReturnData, Section, TaxRate } from 'store/returns/models/returnData';
import { MappingTaxRate } from 'store/returns/models/mappingTaxRate';
import {
  turnDrawerOn,
  turnMessageOn,
  turnModalOff,
  turnModalOn,
  updateMappingData,
} from 'store/app/actions';
import { MessageStates } from 'containers/MessageBox';
import { DrawerData, MessageData, ModalData } from 'store/app/types';
import { useTranslation } from 'react-i18next';
import { getMappingData } from 'store/app/selectors';
import { DrawerType, TaxRateType, WindowType } from 'store/app/enums';
import { ChooseReturn } from 'components/MappingForm/choose-return';
import routes from 'routes';
import { ChooseTemplate } from 'components/MappingForm/choose-template';
import { getCommonCountries } from 'store/common/selectors';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import * as _ from 'lodash';
import { getAuthUser } from 'store/auth/selectors';
import { checkPageLoaded } from 'utils/check-page-loaded';
import {
  PreferenceSections,
  PreferenceActions,
  Preference,
  preferences,
} from 'utils/preferences';

export interface StepOneData {
  entity?: string;
  country?: string;
  typeName?: string;
  firstPeriod?: string;
  firstDueDate?: string;
}

export type TaxRateOption = {
  label: string;
  value: string;
};

export interface SelectedRate {
  value: string;
  location: string[];
  cashAccounting: boolean;
  reverseCharge: number | null;
  taxRateType: TaxRateType;
}

export interface TemplateMapping {
  mappingId: string;
  organisationName: string;
  createdDate: string;
  country?: string;
}

export interface ReverseCharge {
  rateId: string;
  used: boolean;
  amount: number | null;
}

export const AddReturnPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const parameters: any = useParams();
  const currentUser = useSelector(getAuthUser);
  const conId = parameters.connectionId;
  const mappingId = parameters.mappingId;
  const mappings = useSelector(getOrgMappings);
  const organisations = useSelector(getOrgOrganisations);
  const mappedOrganisations = useSelector(getOrgMappedOrganisations);
  const preLoading = useSelector(getReturnPreLoading);
  const taxRatesLoading = useSelector(getReturnTaxRatesLoading);
  const loading = useSelector(getReturnLoading);
  const returnData = useSelector(getReturnData);
  const mappingData = useSelector(getMappingData);
  const countries = useSelector(getCommonCountries);
  const [form2] = Form.useForm();
  const [stepOneData, setStepOneData] = useState<StepOneData>({});
  const editMode: boolean = !!mappingId;
  const [selectedReturnType, setSelectedReturnType] = useState<string>();
  const [taxRateOptions, setTaxRateOptions] = useState<TaxRateOption[]>();
  const [outOfScopeOptions, setOutOfScopeOptions] = useState<TaxRateOption[]>();
  const [sections, setSections] = useState<Section[]>();
  const [cashAccounting, setCashAccounting] = useState<boolean>(false);
  const [outOfScopeCode, setOutOfScopeCode] = useState<string>('OOS');
  const [selectedRates, setSelectedRates] = useState<SelectedRate[]>([]);
  const [selectedRatesCopy, setSelectedRatesCopy] = useState<SelectedRate[]>(
    []
  );
  const [oldSelectedRates, setOldSelectedRates] = useState<SelectedRate[]>([]);

  const history = useHistory();
  const [dataSaved, setDataSaved] = React.useState<boolean>(false);
  const [sectionTouched, setSectionTouched] = React.useState<boolean>(false);
  const unblockRef = React.useRef();
  const [templateMappings, setTemplateMappings] = useState<TemplateMapping[]>(
    []
  );
  const [reverseCharges, setReverseCharges] = useState<ReverseCharge[]>([]);

  const handlePreferences = (
    preferenceSection: PreferenceSections,
    action: PreferenceActions,
    preference: Preference
  ) => {
    if (currentUser)
      return preferences(
        action,
        preferenceSection,
        preference,
        currentUser?.userUuid
      );
    return false;
  };

  const editInitialData = () => {
    if (conId) {
      const drawerData: DrawerData = {
        title: 'Add a Tax Return',
        type: DrawerType.ADD_TAX_RETURN,
        component: (
          <ChooseReturn editMode={true} setSectionTouched={setSectionTouched} />
        ),
      };
      dispatch(turnDrawerOn(drawerData));
    }
  };

  const setMappingData = (data: MappingData) => {
    dispatch(updateMappingData(data));
  };

  const updateSelectedRates = (mappedData: SelectedRate[]) => {
    setSelectedRates(mappedData);
    setSelectedRatesCopy([...mappedData]);
  };

  const saveMappingData = (mData: MappingData) => {
    dispatch(saveMappingRequest(conId, mData));
  };

  const openTemplateWindow = () => {
    const drawerData: DrawerData = {
      title: 'Load from another Entity',
      type: DrawerType.LOAD_FROM_OTHER_ENTITY,
      component: (
        <ChooseTemplate
          templateMappings={templateMappings}
          loadExistingMapping={loadExistingMapping}
        />
      ),
    };
    dispatch(turnDrawerOn(drawerData));
  };

  const setOptions = (
    trOptions: TaxRateOption[],
    oosOptions: TaxRateOption[]
  ) => {
    setTaxRateOptions(trOptions);
    setOutOfScopeOptions(oosOptions);
  };

  const prepareTaxRateOptions = () => {
    const opts: TaxRateOption[] = [];
    returnData?.taxRates?.map((tr) =>
      opts.push({
        value: tr.taxType,
        label: `${tr.name} [${tr.taxRate.toString()}%]`,
      })
    );
    return opts;
  };

  const sortSelectionsByReturnType = (returnType: string) => {
    setSelectedReturnType(returnType);
    const hasCA = returnData?.returnTypesEdit?.find(
      (rt) => rt.id === returnType
    )?.cashAccountingEnabled;

    hasCA ? setCashAccounting(true) : setCashAccounting(false);

    const sec = returnData?.sections?.filter((s) => {
      return (
        s.returnTypeId === returnType ||
        (hasCA && s.name === s.subsections[0].code)
      );
    });
    sec?.sort((a, b) => a.sortOrder - b.sortOrder);
    sec && setSections(sec);
    const ooc = sec?.find((s) => s.subsections[0].outOfScope)?.subsections[0]
      .code;
    ooc && setOutOfScopeCode(ooc);
  };

  const addUnmappedToOutOfScope = () => {
    const emptyLocations = selectedRates.filter(
      (sr) => sr.location.length === 0
    );
    if (emptyLocations.length === 0) {
      const message: MessageData = {
        title: t('common.attention') + '!',
        description: t('returns.mapping.all-rates-mapped'),
        type: MessageStates.INFO,
      };
      dispatch(turnMessageOn(message));
    } else {
      const md: SelectedRate[] = [];
      const oosValue: string[] = [];
      if (returnData?.taxRates) {
        returnData?.taxRates.map((rt) => {
          const nIndex = selectedRates.findIndex(
            (sr) => sr.value === rt.taxType
          );
          let mdRow: SelectedRate;
          if (nIndex === -1) {
            mdRow = {
              value: rt.taxType,
              location: [outOfScopeCode],
              cashAccounting: false,
              reverseCharge: null,
              taxRateType: TaxRateType.INPUT,
            };
            oosValue.push(rt.taxType);
          } else if (selectedRates[nIndex].location.length === 0) {
            const location = [outOfScopeCode];
            mdRow = { ...selectedRates[nIndex], location };
            oosValue.push(rt.taxType);
          } else {
            mdRow = { ...selectedRates[nIndex] };
            if (mdRow.location[0] === outOfScopeCode) oosValue.push(rt.taxType);
          }

          md.push(mdRow);
        });

        updateSelectedRates(md);
        handleTaxRangeChange(oosValue, true, '');
        form2.setFieldsValue({
          ['outOfScope']: oosValue,
        });
      }
    }
  };

  const handleCashAccounting = (rateId: string) => {
    const tempSelRates: SelectedRate[] = [...selectedRatesCopy];
    const nIndex = tempSelRates.findIndex((tsr) => tsr.value === rateId);
    if (nIndex > -1) {
      tempSelRates[nIndex] = {
        ...tempSelRates[nIndex],
        cashAccounting: !tempSelRates[nIndex]?.cashAccounting,
      };
      setSelectedRatesCopy(tempSelRates);
    }
  };

  const handleReverseCharge = (rateId: string, val: string | null) => {
    const value = val !== null ? parseFloat(val) : null;
    const tempReverseCharges: ReverseCharge[] = [...reverseCharges];
    const nIndex = tempReverseCharges.findIndex((trc) => trc.rateId === rateId);
    if (nIndex > -1) {
      const used =
        value !== null && value > 0
          ? tempReverseCharges[nIndex].used
          : !tempReverseCharges[nIndex].used;
      const amount =
        value !== null && value > 0 ? value : tempReverseCharges[nIndex].amount;

      tempReverseCharges[nIndex] = {
        ...tempReverseCharges[nIndex],
        used,
        amount,
      };
      setReverseCharges(tempReverseCharges);
    }
  };

  const fillCashAccounting = (value: boolean) => {
    const tempSelRates: SelectedRate[] = [];

    selectedRatesCopy.forEach((sr) => {
      tempSelRates.push({ ...sr, cashAccounting: value });
    });
    setSelectedRatesCopy(tempSelRates);
  };

  const resetReverseCharge = () => {
    const tempReverseCharges: ReverseCharge[] = [];

    reverseCharges.forEach((rc) => {
      tempReverseCharges.push({ ...rc, amount: null, used: false });
    });
    setReverseCharges(tempReverseCharges);
  };

  const saveCashAccounting = () => {
    setSectionTouched(true);
    setSelectedRates(selectedRatesCopy);
  };

  const saveReverseCharges = () => {
    if (
      reverseCharges.find(
        (rc) => (rc.amount === 0 || rc.amount === null) && rc.used
      )
    ) {
      const message: MessageData = {
        title: t('common.problem-saving') + '!',
        description:
          'The Reverse Charge cannot be saved.  Please ensure that all selected taxes have been assigned a % tax rate.',
        type: MessageStates.WARNING,
      };
      dispatch(turnMessageOn(message));
      return false;
    } else {
      setSectionTouched(true);
      const tempSelectedRates = [...selectedRates];
      reverseCharges.forEach((rc) => {
        const nIndex = tempSelectedRates.findIndex(
          (tsr) => tsr.value === rc.rateId
        );
        if (nIndex > -1)
          if (rc.used) {
            tempSelectedRates[nIndex].reverseCharge = rc.amount;
          } else tempSelectedRates[nIndex].reverseCharge = null;
      });

      setSelectedRates(tempSelectedRates);
      return true;
    }
  };

  const loadCashAccounting = () => {
    setSelectedRatesCopy([...selectedRates]);
  };

  const loadReverseCharge = () => {
    const revCharges: ReverseCharge[] = [];
    selectedRates.forEach((sr) => {
      const rateId = sr.value;
      const used = sr.reverseCharge !== null && sr.reverseCharge !== 0;
      const amount = sr.reverseCharge;
      const revCharge: ReverseCharge = { rateId, used, amount };
      revCharges.push(revCharge);
    });
    setReverseCharges(revCharges);
  };

  const handleTaxRangeChange = (
    rateIds: string[],
    outOfScope: boolean,
    code: string
  ) => {
    setSectionTouched(true);
    const opts: TaxRateOption[] = prepareTaxRateOptions();
    if (outOfScope) {
      const tempSelRates: SelectedRate[] = [...selectedRates];
      setOldSelectedRates(tempSelRates);
      rateIds.map((r) => {
        const rateIndex = opts.findIndex((o) => o.value === r);
        rateIndex > -1 && opts.splice(rateIndex, 1);
        const srIndex = tempSelRates.findIndex((tsr) => tsr.value === r);
        if (srIndex === -1)
          tempSelRates.push({
            value: r,
            location: [outOfScopeCode],
            cashAccounting: false,
            reverseCharge: null,
            taxRateType: TaxRateType.INPUT,
          });
        else tempSelRates[srIndex].location = [outOfScopeCode];
      });
      const tempSR = [...tempSelRates];
      tempSR.map((t, idx) => {
        const locIndex = t.location.findIndex((l) => l === outOfScopeCode);
        if (locIndex > -1 && rateIds.findIndex((ri) => t.value === ri) === -1) {
          tempSelRates[idx].location = [];
        }
      });

      setSelectedRates(tempSelRates);
      setSelectedRatesCopy([...tempSelRates]);
      setTaxRateOptions(opts);
    } else {
      const tempSelRates: SelectedRate[] = [...selectedRates];
      setOldSelectedRates(tempSelRates);
      rateIds.map((ri) => {
        const selIndex = selectedRates.findIndex((sr) => sr.value === ri);
        if (selIndex === -1)
          tempSelRates.push({
            value: ri,
            location: [code],
            cashAccounting: false,
            reverseCharge: null,
            taxRateType: TaxRateType.INPUT,
          });
        else {
          const codeIndex = selectedRates[selIndex].location.findIndex(
            (l) => l === code
          );
          if (codeIndex === -1) selectedRates[selIndex].location.push(code);
        }
      });
      const tempSR = [...tempSelRates];
      tempSR.map((t, idx) => {
        const locIndex = t.location.findIndex((l) => l === code);
        if (locIndex > -1 && rateIds.findIndex((ri) => t.value === ri) === -1) {
          tempSelRates[idx].location.splice(locIndex, 1);
        }
      });

      const finalSelRates = tempSelRates;

      finalSelRates.map((fsr) => {
        const rateIndex = opts.findIndex((o) => o.value === fsr.value);
        if (
          rateIndex > -1 &&
          fsr.location[0] !== outOfScopeCode &&
          fsr.location.length > 0
        )
          opts.splice(rateIndex, 1);
      });
      setSelectedRates(finalSelRates);
      setSelectedRatesCopy([...finalSelRates]);
      setOutOfScopeOptions(opts);
    }
  };

  const loadExistingMapping = (mapId: string, isTemplate = false) => {
    const savingMapId = isTemplate ? mappingId : mapId;
    const selMap = mappings?.find((m) => m.mappingId === mapId);
    const savingPeriodFromDate = isTemplate
      ? mappingData?.periodFromDate
      : selMap?.periodFromDate;
    const savingReturnDueDate = isTemplate
      ? mappingData?.returnDueDate
      : selMap?.returnDueDate;
    const savingReturnFrequency = isTemplate
      ? mappingData?.returnFrequency
      : selMap?.returnFrequency;
    let jsonMapping;
    if (selMap?.jsonMapping) {
      try {
        const parsedMapping = JSON.parse(selMap.jsonMapping);

        jsonMapping = parsedMapping?.mapping;
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
    if (
      returnData?.taxRates &&
      returnData.taxRates.length < jsonMapping.length
    ) {
      const toDelete = jsonMapping.filter(
        (jm) =>
          returnData.taxRates?.findIndex((tr) => tr.taxType === jm.taxType) ===
          -1
      );
      toDelete.map((td) => {
        jsonMapping.splice(
          jsonMapping.findIndex((jm) => jm.taxType === td.taxType),
          1
        );
      });
    }
    const mapData = {
      mappingId: savingMapId,
      templateMappingId: mapId,
      typeId: returnData?.returnTypesEdit?.find(
        (rt) => rt.id === selMap?.returnTypeId
      )?.id,
      countryId: returnData?.returnTypesEdit?.find(
        (rt) => rt.id === selMap?.returnTypeId
      )?.countryId,
      mappedCashAccounting: selMap?.cashAccounting,
      periodFromDate: moment(savingPeriodFromDate).format('YYYY-MM-DD'),
      returnDueDate: moment(savingReturnDueDate).format('YYYY-MM-DD'),
      returnFrequency: savingReturnFrequency,
      jsonMapping,
    };
    setMappingData(mapData);
  };

  const resetForm = () => {
    const opts: TaxRateOption[] = prepareTaxRateOptions();
    const jsonMapping: MappingTaxRate[] = [];
    const mapData: MappingData = { ...mappingData, jsonMapping };

    resetSelectedRates();
    setMappingData(mapData);
    form2.resetFields();
    setTaxRateOptions(opts);
    setOutOfScopeOptions(opts);
  };

  React.useEffect(() => {
    if (!preLoading) {
      const retTypes = returnData?.returnTypesEdit;
      if (editMode && retTypes && retTypes.length > 0) {
        loadExistingMapping(mappingId);
      }
    }
  }, [preLoading, mappings, returnData]);

  React.useEffect(() => {
    if (mappingData && mappingData.mappingId) {
      setSelectedReturnType(mappingData.typeId);
    }
    let entity: any = '';
    let country: any = '';
    let typeName: any = '';
    let firstPeriod: any = '';
    let firstDueDate = '';
    if (mappingData) {
      if (mappingData.typeId) {
        const curType = returnData?.returnTypesEdit?.find(
          (rt) => rt.id === mappingData?.typeId
        );
        typeName = curType?.name;
        country = curType?.countryName;
        if (mappingData.typeChanged) {
          setSectionTouched(false);
          //setDataSaved(true);
          sortSelectionsByReturnType(mappingData?.typeId);
          resetSelectedRates();
          form2.resetFields();
          setMappingData({ ...mappingData, typeChanged: false });
        }
      }
      if (mappingData.periodFromDate && mappingData.returnFrequency) {
        firstPeriod = calculateNextPeriod(
          mappingData.periodFromDate,
          mappingData.returnFrequency
        )[0];
        if (mappingData.returnDueDate) firstDueDate = mappingData.returnDueDate;
      }
      if (organisations && conId) {
        entity = organisations.find(
          (o) => o.connectionId === conId
        )?.friendlyName;
      }

      setStepOneData({ entity, country, typeName, firstPeriod, firstDueDate });
    }
  }, [mappingData]);

  React.useEffect(() => {
    if (returnData?.taxRates) {
      if (!editMode) {
        const opts: TaxRateOption[] = prepareTaxRateOptions();
        setTaxRateOptions(opts);
        setOutOfScopeOptions(opts);
      }
      resetSelectedRates();
      mappingData?.typeId && sortSelectionsByReturnType(mappingData?.typeId);
    }
  }, [returnData]);

  React.useEffect(() => {
    currentUser && checkPageLoaded(location.pathname, routes.returns.root);
    localStorage.removeItem('refreshCountMapping');
  }, []);

  const retData = () => {
    const selectedOrg = mappedOrganisations?.find(
      (mo) => mo.connectionId === conId
    );

    const returnTypes = selectedOrg?.returnTypes;
    const returnTypesEdit = selectedOrg?.returnTypesEdit;
    const taxRates = selectedOrg?.taxRates;
    const selMapping = selectedOrg?.returns.find(
      (r) => r.mappingId === mappingId
    );
    const sections = selMapping?.sections ? selMapping.sections : [];
    return {
      returnTypes,
      returnTypesEdit,
      sections,
      connectionId: conId,
      taxRates,
    };
  };

  React.useEffect(() => {
    if (mappedOrganisations) {
      const cnt = localStorage.getItem('refreshCountMapping');
      if (!cnt) localStorage.setItem('refreshCountMapping', '1');
      else localStorage.setItem('refreshCountMapping', '2');
      //if (!loading && !returnData && !mappingData?.typeId) {
      // const selectedOrg = mappedOrganisations?.find(
      //   (mo) => mo.connectionId === conId
      // );

      // const returnTypes = selectedOrg?.returnTypes;
      // const returnTypesEdit = selectedOrg?.returnTypesEdit;
      // const taxRates = selectedOrg?.taxRates;
      // const selMapping = selectedOrg?.returns.find(
      //   (r) => r.mappingId === mappingId
      // );
      // const sections = selMapping?.sections ? selMapping.sections : [];
      // const retData: ReturnData = {
      //   returnTypes,
      //   returnTypesEdit,
      //   sections,
      //   connectionId: conId,
      //   taxRates,
      // };

      const cnt2 = localStorage.getItem('refreshCountMapping');
      if (cnt2 === '1') {
        dispatch(fetchAddReturnDataRequest(conId, retData()));
      } else {
        localStorage.removeItem('refreshCountMapping');
      }

      //}
    }
  }, [mappedOrganisations]);

  const resetSelectedRates = () => {
    if (returnData?.taxRates) {
      const tempSelRates: SelectedRate[] = [];
      returnData.taxRates.forEach((tr) => {
        const value = tr.taxType;
        const location = [];
        const cashAccounting = false;
        const reverseCharge = null;
        const taxRateType = TaxRateType.INPUT;
        tempSelRates.push({
          value,
          location,
          cashAccounting,
          reverseCharge,
          taxRateType,
        });
      });

      setSelectedRates(tempSelRates);
    }
  };
  const loadTemplateMappings = () => {
    if (mappings) {
      const otherMaps = mappings.filter((m) => {
        return (
          m.mappingId !== mappingId && m.returnTypeId === selectedReturnType
        );
      });

      const tmplMappings: TemplateMapping[] = [];
      otherMaps.map((om) => {
        const orName = organisations?.find(
          (o) => o.uuid === om.organisationId
        )?.friendlyName;
        const createdDate = moment(om.createdDate).format('DD/MM/YYYY');
        const organisationName = orName ? orName : '';
        const mappingId = om.mappingId;
        const countryIso = organisations?.find(
          (o) => o.uuid === om.organisationId
        )?.country;
        const country = countries.find((c) => c.iso === countryIso)?.name;
        tmplMappings.push({
          createdDate,
          organisationName,
          mappingId,
          country,
        });
      });

      setTemplateMappings(tmplMappings);
    }
  };

  const goBackWarningWindow = (data: any, location: any) => {
    const navigate = () => {
      if (unblockRef) {
        //@ts-ignore
        unblockRef.current();
      }
      dispatch(turnModalOff());
      history.push(location.pathname);
    };

    if (
      !dataSaved &&
      sectionTouched &&
      !location.pathname.includes('/editMapping/')
    ) {
      const modalData: ModalData = {
        type: WindowType.WARNING,
        title: 'Your mapping is not saved, do you want to save this mapping?',
        component: (
          <ConfirmBoxContainer
            callback={navigate}
            callback2={onSubmit}
            showCancelBtn={false}
            param2={data}
            okText="Continue without Saving"
            okText2="Save Mapping"
            description="Are you sure you want to go back? These changes are not saved. Once you click ‘Continue’, you will lose current changes."
          />
        ),
      };

      dispatch(turnModalOn(modalData));
    } else history.goBack();
  };

  React.useEffect(() => {
    //@ts-ignore
    unblockRef.current = history.block((location: any, _action: any) => {
      if (!dataSaved && sectionTouched) {
        goBackWarningWindow(oldSelectedRates, location);
        return false;
      }
      return true;
    });

    return () => {
      //@ts-ignore
      unblockRef.current && unblockRef.current();
    };
  }, [oldSelectedRates, dataSaved, selectedRates]);

  const onSubmit = () => {
    setSectionTouched(false);
    let passed = false;
    const emptyLocations = selectedRates.filter(
      (sr) => sr.location.length === 0
    );
    if (
      !returnData?.taxRates ||
      //selectedRates.length !== returnData.taxRates.length ||
      emptyLocations.length > 0
    ) {
      const message: MessageData = {
        title: t('common.problem-saving') + '!',
        description: t('returns.mapping.rates-must-be-mapped'),
        type: MessageStates.WARNING,
      };
      dispatch(turnMessageOn(message));
    } else {
      const jsonMapping: MappingTaxRate[] = [];
      let mappedCashAccounting = false;
      let mappedReverseCharges = false;

      selectedRates.map((sr) => {
        const rate: TaxRate | undefined = returnData?.taxRates?.find(
          (tr) => tr.taxType === sr.value
        );
        if (rate) {
          let codes: string | undefined = '';

          if (sr.cashAccounting) {
            mappedCashAccounting = true;
            codes =
              ',' +
              returnData?.sections?.find(
                (s) => s.name === s.subsections[0].code
              )?.subsections[0].code;
          }
          sr.location.map((l) => {
            codes = `${codes},${l}`;
          });
          const reverseCharge = sr.reverseCharge;
          const taxRateType = sr.taxRateType;
          if (reverseCharge !== null && reverseCharge > 0)
            mappedReverseCharges = true;
          const taxCodeName = rate?.name;
          const taxRate = rate?.taxRate.toFixed(2);
          const taxType = rate?.taxType;

          codes = codes?.substring(1);
          jsonMapping.push({
            taxCodeName,
            taxRate,
            taxType,
            codes,
            reverseCharge:
              reverseCharge !== null && reverseCharge > 0
                ? reverseCharge.toFixed(2)
                : null,
            taxRateType,
          });
        }
      });

      const md: MappingData = {
        ...mappingData,
        mappedCashAccounting,
        mappedReverseCharges,
        jsonMapping,
      };
      const findOutputs = jsonMapping.findIndex(
        (jm) =>
          jm.codes !== outOfScopeCode && jm.taxRateType === TaxRateType.OUTPUT
      );
      const saveCallback = () => {
        passed = true;
        setDataSaved(true);
        saveMappingData(md);
      };

      if (findOutputs === -1) {
        const modalData: ModalData = {
          type: WindowType.WARNING,
          title: 'Your mapping might be incorrect?',
          component: (
            <ConfirmBoxContainer
              callback={saveCallback}
              showCancelBtn
              okText="Continue"
              description="You haven't selected any output tax rates in your mapping. If you have output tax rates to include, please click 'Cancel' and select them in the Tax Rate Type section. If there are no output taxes, you may disregard this message and proceed by clicking 'Continue'."
            />
          ),
        };

        dispatch(turnModalOn(modalData));
      } else {
        saveCallback();
      }
    }

    return passed;
  };
  const updateTaxRateType = (type: TaxRateType, rateId: string | any[]) => {
    const tempSelRates = [...selectedRates];

    const tempSelRates2: SelectedRate[] = [];
    tempSelRates.forEach((a) => {
      if (typeof rateId === 'string') {
        if (a.value === rateId) {
          tempSelRates2.push({ ...a, taxRateType: type });
        } else tempSelRates2.push(a);
      } else {
        if (rateId.findIndex((r) => r === a.value) !== -1) {
          tempSelRates2.push({ ...a, taxRateType: type });
        } else tempSelRates2.push({ ...a, taxRateType: TaxRateType.INPUT });
      }
    });

    setSelectedRates(tempSelRates2);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Add a return | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for adding a return" />
      </Helmet>
      <Spin
        spinning={taxRatesLoading || loading || !sections}
        style={{ minHeight: '100vh' }}
        size="large"
        //fullscreen
      >
        {!sections ||
          (sections.length !== 0 && (
            <MappingForm
              preLoading={preLoading}
              taxRatesLoading={taxRatesLoading}
              loading={loading}
              returnData={returnData}
              mappingData={mappingData}
              stepOneData={stepOneData}
              form2={form2}
              editMode={editMode}
              selectedRates={selectedRates}
              selectedRatesCopy={selectedRatesCopy}
              reverseCharges={reverseCharges}
              outOfScopeCode={outOfScopeCode}
              cashAccounting={cashAccounting}
              handleCashAccounting={handleCashAccounting}
              handleReverseCharge={handleReverseCharge}
              addUnmappedToOutOfScope={addUnmappedToOutOfScope}
              updateSelectedRates={updateSelectedRates}
              sections={sections}
              taxRateOptions={taxRateOptions}
              outOfScopeOptions={outOfScopeOptions}
              handleTaxRangeChange={handleTaxRangeChange}
              setOptions={setOptions}
              prepareTaxRateOptions={prepareTaxRateOptions}
              hasTemplates={templateMappings.length > 0}
              loadTemplateMappings={loadTemplateMappings}
              openTemplateWindow={openTemplateWindow}
              resetForm={resetForm}
              editInitialData={editInitialData}
              saveCashAccounting={saveCashAccounting}
              saveReverseCharges={saveReverseCharges}
              resetReverseCharge={resetReverseCharge}
              fillCashAccounting={fillCashAccounting}
              loadCashAccounting={loadCashAccounting}
              loadReverseCharge={loadReverseCharge}
              onSubmit={onSubmit}
              updateTaxRateType={updateTaxRateType}
              handlePreferences={handlePreferences}
            />
          ))}
      </Spin>
    </React.Fragment>
  );
};
