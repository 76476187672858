import { call, put } from 'redux-saga/effects';
import {
  changeActivityRole,
  updateActivityRoleFailure,
  updateActivityRoleSuccess,
} from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { UpdateActivityRoleRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { updateActivityRole } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { updateSubmissionLog } from 'store/organisation/actions';
import { SubmissionUpdateField } from 'store/app/enums';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* updateActivityRoleSaga(
  action: UpdateActivityRoleRequestAction
) {
  try {
    let err: ApiError | null;
    const { activityRoleUpdateRequest } = action.payload;

    const response: ApiResponse = yield call(
      updateActivityRole,
      activityRoleUpdateRequest
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(
      changeActivityRole(
        activityRoleUpdateRequest.activityRole, //@ts-ignore
        activityRoleUpdateRequest.memberUser?.accountUuid
      )
    );
    yield put(updateActivityRoleSuccess());
    yield put(
      updateSubmissionLog(
        activityRoleUpdateRequest.mappingId,
        SubmissionUpdateField[
          activityRoleUpdateRequest.activityRole
            .toUpperCase()
            .replace(' ', '_')
            .replace('-', '_')
        ],
        activityRoleUpdateRequest.memberUser,
        activityRoleUpdateRequest.dataLogId
      )
    );
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(updateActivityRoleFailure());
  }
}
