import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuthLoading,
  getAuthMfaResponse,
  getAuthUser,
} from 'store/auth/selectors';
import { LoginForm } from 'components/LoginForm';
import { loginRequest } from 'store/auth/actions';
import { Helmet } from 'react-helmet-async';
import { UserDetails } from 'store/signup/models';
//import { RememberUser } from 'store/auth/utils/rememberUser';
import routes from 'routes';
import { Provider } from 'store/api/enums';
import { navigateToExternalPage } from 'utils/validation/common';
import { turnMessageOn } from 'store/app/actions';
import { MessageStates } from 'containers/MessageBox';
import { MessageData } from 'store/app/types';
import { push } from 'connected-react-router';
import { toTitleCase } from 'utils/to-title-case';

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getAuthLoading);
  const user = useSelector(getAuthUser);
  const mfaResponse = useSelector(getAuthMfaResponse);
  const [savedLoginParams, setSavedLoginParams] =
    React.useState<UserDetails | null>(null);
  const [showPage, setShowPage] = useState(false);

  const handleLogin = (
    email: string,
    password: string,
    //rememberMe?: boolean,
    gCaptchaResponse?: string,
    mfaCode?: string | null
  ): void => {
    const loginParams = { email, password } as UserDetails;
    dispatch(loginRequest(loginParams, false, gCaptchaResponse, mfaCode));
  };
  const onProviderLogin = (provider: Provider) => {
    const hostURL = process.env.PUBLIC_URL;
    const baseUrl = process.env.API_URL.replace('tax/api', 'tax').replace(
      '8069/api',
      '8069'
    );
    const providerUrl = `${baseUrl}/oauth2/authorization/${provider}?hostURL=${hostURL}`;

    const message: MessageData = {
      title: `Contacting ${toTitleCase(provider)}`,
      description: 'Please be patient, it might take few seconds',
      type: MessageStates.INFO,
    };
    dispatch(turnMessageOn(message));

    navigateToExternalPage(providerUrl);
  };

  useEffect(() => {
    //setSavedLoginParams(RememberUser.retrieve());
    if (!loading && !!user) dispatch(push(routes.main));
    else setShowPage(true);
  }, [user]);

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`${process.env.PROJECT_NAME} | Login`}</title>
            <meta
              name="description"
              content="This a page for clients to login"
            />
          </Helmet>

          <LoginForm
            onLogin={handleLogin}
            loading={loading}
            //savedLoginParams={savedLoginParams}
            onProviderLogin={onProviderLogin}
            mfaResponse={mfaResponse}
          />
        </>
      )}
    </React.Fragment>
  );
};
