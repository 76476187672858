import { call, put } from 'redux-saga/effects';
import { ApiError } from 'store/api/types';
import { FetchContactRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { GetContactResponse, getContact } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';

import { fetchContactFailure, fetchContactSuccess } from '../actions';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchContactSaga(action: FetchContactRequestAction) {
  try {
    let err: ApiError | null;
    const { contactUuid } = action.payload;

    const response: GetContactResponse = yield call(getContact, contactUuid);
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const viesChecks = response.data.viesChecks;
    const contact = response.data.contact;

    if (viesChecks.length > 0)
      yield put(fetchContactSuccess(viesChecks, contact));
    else {
      const message: MessageData = {
        title: 'Server message',
        description: `No logs were found for ${contact.name}`,
        type: MessageStates.WARNING,
      };
      yield put(turnMessageOn(message));
      yield put(fetchContactFailure());
    }
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchContactFailure());
  }
}
