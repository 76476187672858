import { call, put } from 'redux-saga/effects';
import { deleteAccountFailure, deleteAccountSuccess } from '../actions';
import { deleteAccount } from '../api';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* deleteAccountSaga() {
  try {
    let err: ApiError | null;

    const response: ApiResponse = yield call(deleteAccount);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: `Success!`,
      description: `Owner deleted successfully.`,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(deleteAccountSuccess());
    yield put(logoutRequest());
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(deleteAccountFailure());
  }
}
