import { call, put, select } from 'redux-saga/effects';
import { ApiError, ApiResponse } from 'store/api/types';
import { ActivateViesRequestAction } from '../types';
import { checkResponse } from 'store/utils';
import moment from 'moment';
import { activateVies } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { activateViesFailure, activateViesSuccess } from '../actions';

import { getAuthUser } from 'store/auth/selectors';
import { ViesInfo } from '../models';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* activateViesSaga(action: ActivateViesRequestAction) {
  const currentUser = yield select(getAuthUser);
  try {
    let err: ApiError | null;
    const { connectionId, entityName } = action.payload;

    const response: ApiResponse = yield call(activateVies, connectionId);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success',
      description: `${entityName} activated successfully.`, //response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(activateViesSuccess(connectionId));
    const localRaw = localStorage.getItem(`vies-infos-${currentUser.userUuid}`);
    if (localRaw) {
      const localVies = JSON.parse(localRaw);
      const viesInfos: ViesInfo[] = localVies.viesInfos;
      const nIdx = viesInfos.findIndex(
        (lv) => lv.connectionId === connectionId
      );
      if (nIdx > -1) {
        viesInfos[nIdx].vatCheckerActive = true;
        localStorage.setItem(
          `vies-infos-${currentUser.userUuid}`,
          JSON.stringify({ timestamp: moment(), viesInfos })
        );
      }
    }
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(activateViesFailure());
  }
}
