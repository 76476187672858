import * as React from 'react';
import { VatReportData } from 'store/vat/models';
import { TaxReportSubsectionDataResponse } from 'store/vat/models/vatReportData';

interface IsleOfManFormProps {
  data?: VatReportData;
  renderValueCell: (boxNumber: string, withP?: boolean) => void;
  finalTotals: boolean;
}
export const IsleOfManForm: React.FC<IsleOfManFormProps> = ({
  data,
  renderValueCell,
  finalTotals,
}) => {
  React.useEffect(() => {
    const selectedSubsections: TaxReportSubsectionDataResponse[] | undefined =
      !finalTotals ? data?.subsections : data?.adjustedSubsections;
    if (data === undefined || data === null) return;

    const currencySignSpans = document.querySelectorAll('.currency-sign');
    currencySignSpans.forEach((currencySignSpan) => {
      if (data.currencySymbol !== null) {
        currencySignSpan.textContent = data.currencySymbol;
      }
    });

    data?.sections.forEach((section, i) => {
      const sectionNameCell = document.querySelector(`#section10${i}-name`);

      if (sectionNameCell) {
        sectionNameCell.textContent = section.name;
      }
    });

    selectedSubsections?.forEach((subsection) => {
      const boxNumber = subsection.code.replace(/^Box\s+/, '');

      const subsectionBoxNameCell = document.getElementById(
        `subsection${boxNumber}-box-name`
      );
      const subsectionValueCell = document.getElementById(
        `subsection${boxNumber}-value`
      );
      const subsectionPValueCell = document.getElementById(
        `subsection${boxNumber}-value-p`
      );

      const subsectionNameCell = document.getElementById(
        `subsection${boxNumber}-name`
      );
      const subsectionValueSpan = document.getElementById(
        `subsection${boxNumber}-value-span`
      );

      if (subsectionNameCell) subsectionNameCell.textContent = subsection.name;

      if (subsectionBoxNameCell)
        subsectionBoxNameCell.textContent = subsection.code;

      const subsectionMapBox = subsection.net;
      if (subsectionPValueCell !== null && subsectionValueSpan !== null) {
        if (
          document.getElementById(`subsection${boxNumber}-value-p`) !== null
        ) {
          const inputNumber: any = subsectionMapBox
            ? subsectionMapBox.toFixed(2)
            : 0;
          const integerPart = Math.floor(inputNumber);
          const decimalPart = (inputNumber - integerPart).toFixed(0);

          subsectionPValueCell.textContent = decimalPart;
          subsectionValueSpan.textContent = integerPart.toFixed(0);
        } else {
          subsectionValueSpan.textContent = subsectionMapBox
            ? subsectionMapBox.toFixed(2)
            : '0.00';
        }
      } else if (subsectionValueSpan !== null) {
        subsectionValueSpan.textContent = subsectionMapBox
          ? subsectionMapBox.toFixed(2)
          : '0.00';
      }
    });
  }, [data, finalTotals]);
  return (
    <>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section100-name">VAT Details</th>
            <th>Box</th>
            <th style={{ width: 130, textAlign: 'center' }}>
              <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection1-name">
              VAT due in the period on SALES and other outputs
            </td>
            <td id="subsection1-box-name" className="box">
              Box 1
            </td>
            <td
              id="subsection1-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1')}
            </td>
          </tr>
          <tr>
            <td id="subsection2-name">
              VAT due in the period on ACQUISITIONS of goods brought into
              Northern Ireland from EU Member States
            </td>
            <td id="subsection2-box-name" className="box">
              Box 2
            </td>
            <td
              id="subsection2-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('2')}
            </td>
          </tr>
          <tr>
            <td id="subsection3-name">
              Total VAT due(the sum of boxes 1 and 2)
            </td>
            <td id="subsection3-box-name" className="box">
              Box 3
            </td>
            <td
              id="subsection3-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('3')}
            </td>
          </tr>
          <tr>
            <td id="subsection4-name">
              VAT reclaimed in the period on PURCHASES and any other inputs
              (including acquisitions of goods brought into Northen Ireland from
              EU Member States)
            </td>
            <td id="subsection4-box-name" className="box">
              Box 4
            </td>
            <td
              id="subsection4-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('4')}
            </td>
          </tr>
          <tr>
            <td id="subsection5-name">
              VAT to pay Customs or reclaim(difference between boxes 3 and 4)
            </td>
            <td id="subsection5-box-name" className="box">
              Box 5
            </td>
            <td
              id="subsection5-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('5')}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section101-name">Totals</th>
            <th>Box</th>
            <th style={{ width: 130, textAlign: 'center' }}>
              <span className="currency-sign"></span>
            </th>
            <th>p</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection6-name">
              Total value of SALES and all other outputs excluding any VAT.
              Include your box 8 and box 10 figures
            </td>
            <td id="subsection6-box-name" className="box">
              6
            </td>
            <td
              id="subsection6-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('6')}
            </td>
            <td id="subsection6-value-p" className="rightalign" tabIndex={1}>
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection7-name">
              Total value of PURCHASES and all other inputs excluding VAT.
              Include your box 9 figure
            </td>
            <td id="subsection7-box-name" className="box">
              7
            </td>
            <td
              id="subsection7-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('7')}
            </td>
            <td id="subsection7-value-p" className="rightalign" tabIndex={1}>
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection8-name">
              Total value of DISPATCHES of GOODS and related costs (excluding
              VAT) from Northern Ireland to EU Member States
            </td>
            <td id="subsection8-box-name" className="box">
              8
            </td>
            <td
              id="subsection8-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('8')}
            </td>
            <td id="subsection8-value-p" className="rightalign" tabIndex={1}>
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection9-name">
              Total value of ACQUISITIONS of GOODS and related costs (excluding
              VAT) from EU Member States to Northern Ireland
            </td>
            <td id="subsection9-box-name" className="box">
              9
            </td>
            <td
              id="subsection9-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('9')}
            </td>
            <td id="subsection9-value-p" className="rightalign" tabIndex={1}>
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection10-name">
              Total value of SUPPLIES of IOM accommodation or renovation and
              repair of dwellings subject to VAT at the rate of 5%
            </td>
            <td id="subsection10-box-name" className="box">
              10
            </td>
            <td
              id="subsection10-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('10')}
            </td>
            <td id="subsection10-value-p" className="rightalign" tabIndex={1}>
              0.00
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
