import { ReturnFrequency } from 'store/api/enums';
import dayjs from 'dayjs';
import moment from 'moment';

export function calculateNextPeriod(fromDate: any, returnFrequency: string) {
  const mFromDate = moment(new Date(fromDate));
  let mEndDate = moment(new Date(fromDate));

  switch (ReturnFrequency[returnFrequency]) {
    case ReturnFrequency.WEEKLY:
      mEndDate.add(1, 'w').subtract(1, 'd');
      break;
    case ReturnFrequency.FORNIGHTLY:
      mEndDate.add(2, 'w').subtract(1, 'd');
      break;
    case ReturnFrequency.MONTHLY:
      mEndDate.add(1, 'M').subtract(1, 'd');
      break;
    case ReturnFrequency.TWO_MONTHS:
      mEndDate.add(2, 'M').subtract(1, 'd');
      break;
    case ReturnFrequency.FOUR_MONTHS:
      mEndDate.add(4, 'M').subtract(1, 'd');
      break;
    case ReturnFrequency.SIX_MONTHS:
      mEndDate.add(6, 'M').subtract(1, 'd');
      break;
    case ReturnFrequency.QUARTERLY:
      mEndDate.add(3, 'M').subtract(1, 'd');
      break;
    case ReturnFrequency.BI_YEARLY:
      mEndDate.add(2, 'y').subtract(1, 'd');
      break;
    case ReturnFrequency.YEARLY:
      mEndDate.add(1, 'y').subtract(1, 'd');
      break;
    default:
      break;
  }

  if (mEndDate > mFromDate)
    return [
      `${mFromDate.format('DD/MM/YYYY')} - ${mEndDate.format('DD/MM/YYYY')}`,
      mEndDate.format('YYYY-MM-DD'),
      [
        dayjs(mFromDate.format('DD/MM/YYYY'), 'DD/MM/YYYY'),
        dayjs(mEndDate.format('DD/MM/YYYY'), 'DD/MM/YYYY'),
      ],
    ];
  else return [];
}
