import { call, put } from 'redux-saga/effects';
import {
  fetchUsersRequest,
  setUserPermissionsFailure,
  setUserPermissionsSuccess,
} from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { setUserPermissions } from '../api';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { SetUserPermissionsRequestAction } from '../types';
import { MessageStatus } from 'store/api/enums';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* setUserPermissionsSaga(
  action: SetUserPermissionsRequestAction
) {
  try {
    let err: ApiError | null;
    const { accountUuid, permissionType, enabled } = action.payload;
    const response: ApiResponse = yield call(
      setUserPermissions,
      accountUuid,
      permissionType,
      enabled
    );

    err = checkResponse(response?.responseMessage);
    if (err && response.responseMessage.status === MessageStatus.UNAUTHORIZED) {
      yield put(fetchUsersRequest());
    }
    if (err) throw err;

    yield put(setUserPermissionsSuccess(accountUuid, permissionType, enabled));
  } catch (error: any) {
    if (error?.status !== 401 && error?.status !== 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(setUserPermissionsFailure());
  }
}
